<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container position-relative">
  
          <h4 class="text-primary fw-bold">Incomplete Delivery Notes <span class="tag" >Today</span> 
          <button style="float:right;" class="btn btn-style small" @click="$router.push('/transaction/incompletenotes/add')">Generate IDN</button>
          </h4>
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>Total IDNs</p>
              <strong class="red">{{ notes?.length }}</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Settled <br>
                IDN</p>
              <strong>{{ countSettled() }}</strong>
            </div>
  
          </div>
  
          <div class="filter-search"  style="margin-top: -20px !important; padding-left: 0px;">
            <div class="filter-search-header">
              <strong>Filter</strong>
              <p class="clear" @click="clear">Clear</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom" @change="filterTable" />
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo" @change="filterTable" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
  

            <!--
             <div class="btn-group" role="group" style="float:right">
              <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black;" data-bs-toggle="dropdown" aria-expanded="false">Export</button>
              <ul class="dropdown-menu">
                <li style="width: 100%;"><a class="dropdown-item" href="#">CSV</a></li>
                <li style="width: 100%;"><a class="dropdown-item" href="#">PDF</a></li>
                <li style="width: 100%;"><a class="dropdown-item" href="#">EXCEL</a></li>
              </ul>
            </div>
            -->
            
            
          <div class="products-list transaction-products-list" style="margin-top: 0px;">
            <table class="table table-hover" v-if="notes.length > 0" id="deliveryTable" style="margin-top: 0px;">
              <thead>
                <th>No.</th>
                <th>Date</th>
                <th>Reference No.</th>
                <th>Stock Category</th>
                <th>Delivery/Courier</th>
                <th>Status</th>
                <th>Handled By</th>
                <th>Action</th>
              </thead>
              
                <tbody>
                <tr v-for="(note, index) in filteredNotes" v-bind:key="note.id">
                  <td>{{ (index+1) }}</td>
                  <td>{{ formatDate(note.createdAt) }}</td>
                  <td>{{ note.Batch[0]?.batchNo }}</td>
                  <td v-if="note.Batch[0]?.nogId">{{ note.Batch[0]?.Nog?.nog }}</td> <td v-else> - </td>
                  <td v-if="note.Batch[0]?.isInternal == false ">{{ note.Batch[0]?.supplierDriver }} - {{ note.Batch[0]?.supplierVehicle }}</td>
                  <td v-else>Internal Delivery</td>
                  <td v-if="note.status == 'settled'"><button class="btn btn-success btn-sm" style="border-radius: 15px;">Settled</button></td>
                  <td v-else><button class="btn btn-sm btn-primary" style="border-radius: 15px;">Open</button></td>
                  <td>{{ note.User?.name }}</td>
                  <td>
                    <button type="button" v-if="note.status != 'settled' " class="btn btn-default btn-sm" style="border: 1px solid black;" @click="isVisible =true,settleModal(note.id)">Settle</button>
                    <div class="btn-group" role="group">
                      <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">More</button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" @click="$router.push({name: 'PdfIncompleteDelivery', params:{ id: note.id}})">View IDN Summary</a></li>
                        <li><a class="dropdown-item" target="_blank" v-if="note.settleDocument" :href="`${urlServer}/files/${note.settleDocument}`">View Closure Doc.</a></li>
                        <li><a class="dropdown-item" v-if="note.status != 'settled'" @click="cancelNoteAlert(note.id)">Cancel IDN</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
              
            </table>
            <LoaderItem v-if="loading"/>
            <!--
             <div v-else>
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block; border-none !important">No note found</label>
            </div>
            -->
          </div>
        </div>
      </section>
     


        <Modal v-if="isVisible" @close="closeModal" >
          <template v-slot:header>
            <h4 class="text-primary fw-bold">Settle Incomplete Delivery Note</h4>
          </template>

          <template v-slot:body>
            <div class="row">
              <div class="form-group col-12">
                <label for="item-code">Upload Supporting Document</label>
                <input type="file" class="form-control" id="item-code" ref="fileDocument" @change="handleFileChange">
              </div>
              <div class="form-group col-12">
                <label for="item-code">Comment</label>
                <input type="text" class="form-control" v-model="settleComment">
              </div>
            </div>
          </template>

          <template v-slot:footer>
          <button class="btn-style small mt-4" style="border: 1px solid black; float: right;" @click="uploadSettle">Upload</button>
          <button class="btn btn-default mt-4" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Cancel</button>
          </template>
        </Modal>
      </div>
    
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../../components/Header';
  import Modal from '../../../components/Modal.vue'
  import axios from 'axios'
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import LoaderItem from '../../../components/LoaderItem'
  
  export default {
    name: 'IncompleteDeliveryNotes',
    components: {
      Header,
      Modal, 
      LoaderItem
    },
    data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      urlServer:process.env.VUE_APP_URL,
      isVisible:false,
      processedData:[],
      notes: [],
      notesDatas: [],
      filteredNotes: [],
      dateTo: "",
      dateFrom: "",
      selectedNote:"",
      document:"",
      settleComment:"",
      loading: false
    }),
  
    mounted(){
      this.initializeDataTable()
      this.fetchNotes()
    },

    methods:{
      closeModal() {
        this.isVisible = false;
      },

      reinitializeTable(){
        this.dataTable.clear()
        this.dataTable.rows.add(this.preprocessData(this.notesDatas))
        this.dataTable.draw()
      },

      clear(){
        this.dateFrom = ""
        this.dateTo = ""
        this.filteredNotes = this.notesDatas
      },

      settleModal(value){
        this.selectedNote = value
      },
      async uploadSettle () {
        const formData = new FormData();
        formData.append("noteId", this.selectedNote)
        formData.append("file", this.document)
        formData.append("comment", this.settleComment)

        axios.post(process.env.VUE_APP_URL+'/incompletenotes/settle', 
          formData, 
          {
            headers:{ 
              "Content-Type": "multipart/form-data", 
              'Authorization': 'Bearer '+this.token 
            }}).then(function(data) {
            if(data.data.status == true){
              window.location.reload();
            }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Incomplete Delivery Note not settled',
                  text: data.data.message
              })
            }
          })
      },
      cancelNoteAlert(id){
          Swal.fire({
            title: 'Confirm Incomplete Delivery Note Cancellation',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!'
          }).then((result) => {
            if (result.isConfirmed) {
              this.cancelNote(id);
            }
          })
      },

      async fetchNotes () {
        try{
          const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))};
          const res = await fetch(process.env.VUE_APP_URL+'/incompletenotes',{ mode: 'cors', headers})
          this.loading = true
          this.notes = await res.json()
          this.notesDatas = this.notes
          this.filteredNotes = this.notes
          this.processedData = this.processedData.concat(this.preprocessData(this.notes));
          //console.log(this.processedData)
          this.initializeDataTable();
        }catch(err){
          //console.log(err)
        }finally{
          this.loading = false
        } 
      },
      
      preprocessData(rawData) {
        return rawData?.map(item => {
          return {
            indexNo:"1",
            date: this.formatDate(item.createdAt),
            referenceNo: item.Batch[0]?.batchNo,
            stockCategory: item.Batch[0]?.nogId,
            delivery: item.Batch[0]?.isInternal,
            status: item.status,
            handledBy: item.User?.name,
            action: ''
          };
        });
      },

      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#deliveryTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF,
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'date' },
              { data: 'referenceNo' }, 
              { data: 'stockCategory' }, 
              { data: 'delivery'},
              { data: 'status' }, 
              { data: 'handledBy' }, 
              { data: 'action', render: this.renderActionButton }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },

      filterTable() {
        if (!this.dateFrom || !this.dateTo) {
          console.error("Invalid date inputs");
          return;
        }

        const fromDate = new Date(this.dateFrom);
        const toDate = new Date(this.dateTo);

        if (isNaN(fromDate) || isNaN(toDate)) {
          console.error("Invalid date values");
          return;
        }

        // Filter the data array based on the date range
        this.filteredNotes = this.notes.filter(item => {
          const createdAt = new Date(item.createdAt)
          return createdAt >= fromDate && createdAt <= toDate;
        });
      },


      countSettled() {
        let count = 0;
        for (const obj of this.notes) {

          if (obj.status === 'settled') {
            count++;
          }
        }

        return count
     },



      renderActionButton(data, type, row){
        console.log(row.type)
        if(type == 'display'){
          let action = ''
          /*
          action = `<button type="button" v-if="note.status != 'settled' " class="btn btn-default btn-sm" style="border: 1px solid black;" @click="isVisible =true,settleModal(note.id)">Settle</button>
            <div class="btn-group" role="group">
            <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">More</button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" @click="$router.push({name: 'PdfIncompleteDelivery', params:{ id: note.id}})">View IDN Summary</a></li>
                  <li><a class="dropdown-item" target="_blank" v-if="note.settleDocument" :href="`${urlServer}/files/${note.settleDocument}`">View Closure Doc.</a></li>
                  <li><a class="dropdown-item" v-if="note.status != 'settled'" @click="cancelNoteAlert(note.id)">Cancel IDN</a></li>
                 </ul>
            </div>`
         */

          return action
        }
        return data
      },

      async cancelNote (id) {
          const requestOptions = {
              method: "DELETE",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/incompletenotes/'+id, requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Incomplete Delivery Note Cancelled',
                showConfirmButton: false,
                timer: 1500
              })
              this.fetchNotes()      
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Incomplete Delivery Note Cancellation Failed',
                  text: data.message
              })
          }
      },
      handleFileChange() {
        this.document = this.$refs.fileDocument.files[0];
      },
    }
  }
  </script>