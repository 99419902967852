<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container" >
          <h5 class="fw-bold text-primary">{{ title }} / Stock Item List </h5>
          <div class="products-list transaction-products-list" style="margin-top: 0px !important;">
            <div class="btn-group" role="group" style="float:right">
              <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black; margin-bottom:10px !important" data-bs-toggle="dropdown" aria-expanded="false">Export</button>
              <ul class="dropdown-menu">
              <li style="width: 100%;"><span class="dropdown-item" @click="exportData">CSV</span></li> 
                <li style="width: 100%;"><span class="dropdown-item" @click="exportToPDF">PDF</span></li>
            <!--<li style="width: 100%;"><span class="dropdown-item" @click="exportData">EXCEL</span></li>-->
              </ul>
            </div>


        <div id="pdfStart">
          <div class="d-flex justify-content-between" v-if="pdf">
            <div>
              <p style="color: #FFA500; font-size: 14px" class="fw-bold">{{ profile?.businessName}}</p>
              <p style="color: #4448B3; font-size: 13px"> {{ profile?.businessName }} | Warehouse Products</p>
            </div>

            <div style="display: flex; flex-direction: column; align-items: end">
              <p style="color: #4448B3; font-size: 12px; margin-bottom: 2px !important;" class="fw-bold">Warehouse Products</p>
              <p style="font-size: 10px !important">{{ this.formatDate(new Date(), "true")}}</p>
            </div>
          </div>
          
          <table class="table table-hover"  v-if="activities.length > 0">
            <thead>
              <th>No.</th>
              <th>Product Description</th>
              <th>Category</th>
              <th>Unit</th>
              <th>On Hand</th>
              <th>Qty Reserved</th>
              <th>Qty Available</th>
              <th>SOH Value(Kshs)</th>
              <th>Stock Level Alert</th>
              <th>Warehouse</th>
              <th v-if="!pdf">Action</th>
            </thead>
            <tbody>
              <tr v-for="(activity, index) in activities" v-bind:key="activity.id">
                <td>{{ (index+1) }}</td>
                <td>{{ activity?.Product?.name }}</td>
                <td>{{ activity?.Product?.Category?.category }}</td>
                <td>{{ activity?.Product?.unit?.unit }}</td>
                <td>{{ (activity?.quantity + activity?.quantityReserved) }}</td>
                <td>{{ activity?.quantityReserved }}</td>
                <td>{{ activity?.quantity }}</td>
                <td v-if="activity?.Product?.pricePurchase">{{ (activity?.quantity+ activity?.quantityReserved) * activity.Product.pricePurchase }}</td>
                <td v-else>-</td>
                <td>Good</td>
                <td>{{ activity?.Warehouse?.name }}</td>
                <td v-if="!pdf">
                  <button class="btn btn-sm btn-default" style="border: 1px solid black; margin-left:10px; margin-right: 10px;" @click="this.$router.push('/warehouses/activity/'+activity.id)">Activity</button>
                  <div class="btn-group" role="group">
                      <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                      <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/products/'+activity.Product.id)">View Product</a></li>
                          <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/stocks/reserved/'+activity.id)">Reserve Stock</a></li>
                          <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/stocks/adjustments/'+activity.id)">Adjust Stock</a></li>
                          <li><a class="dropdown-item" href="#" @click="this.$router.push('/inventory/stocks/transfers/'+activity.id)">Transfer Stock</a></li>
                      </ul>
                  </div>
              </td>
              </tr>
            </tbody>
            
          </table>
          <LoaderItem  v-if="loading" />
          <div v-else>
            <label for="" class="text-danger" style="margin: 0px; width: fit-content; display: block;">No item found</label>
          </div>


          <div class="d-flex  justify-content-between" style="margin-top: 140vh" >
            <p>{{ profile.businessWebsite  }}</p>
            <p>{{ profile.businessEmail }}</p>
            <p> page 1 of 1 </p>
            <p> {{ this.formatDate(new Date(), "true") }}  </p>
          </div>    
          
        </div>
          
        </div>
  
        </div>
      </section>
  
      <div style="margin-bottom: 50px;">
        <button class="btn btn-default mt-4 mr-5" style="float: right; margin-right: 30px;border:1px solid black;" @click="this.$router.back()">Close</button>
      </div>

      <div id="pdfLoader" style="position: absolute; width: 100%; height: 2000px; top:0; z-index:1;background-color: #f2f2f2; display: none;">
        <h3 style="margin-top: 100px; text-align: center;padding: 40px;">Wait as the PDF is being generated</h3>
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
  

    </div>
  </template>
  
  <script>
  import Header from '../../components/Header';
  import html2pdf from "html2pdf.js";
  import exportFromJSON from 'export-from-json';
import LoaderItem from '@/components/LoaderItem.vue';

  export default {
    name: 'ViewWarehouse',
    components: {
      Header,
      LoaderItem
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        title: "",
        warehouseId:"",
        type:"",        
        activities: [],
        profile: {},
        pdf: false, 
        loading: false
    }),
    mounted(){
        this.warehouseId = this.$route.params.id
        this.type = this.$route.params.type
        this.title = this.$route.params.title
        this.setupInventory()
        this.profile = JSON.parse(localStorage.getItem('profile'))
    },
    methods:{
      exportToPDF() {
        document.getElementById("pdfLoader").style.display = "block";
        this.pdf = true
        const collection = document.getElementsByTagName("td");

        for (let i = 0; i < collection.length; i++) {
          collection[i].style.fontSize = "12px";
        }

        const element = document.getElementById("pdfStart");

        html2pdf()
          .from(element)
          .set({
            margin: 0.2,
            filename: new Date().getTime() + "_warehouse_products.pdf",
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
          })
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            this.addHeadersAndFooters(pdf);
            return pdf;
          })
          .save()
          .then(() => {
            for (let i = 0; i < collection.length; i++) {
              collection[i].style.fontSize = "16px";
            }
            this.pdf=false
            document.getElementById("pdfLoader").style.display = "none";
          });
          
        },


        addHeadersAndFooters(pdf) {
          const totalPages = pdf.internal.getNumberOfPages();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);

            // Header
            pdf.setFontSize(10);
            pdf.text("Header text", pdf.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

            // Footer
            pdf.setFontSize(10);
            pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 10, { align: 'center' });
          }
        },


        async setupInventory(){
          try {
            this.loading = true
            const requestOptions = {
              method: "POST",
              headers: { 
                "Content-Type": "application/json", 
                'Authorization': 'Bearer ' + this.token 
              },
              body: JSON.stringify({ 
                warehouseId: this.warehouseId,
                type: this.type,
                title: this.title,
              }),
            };

            const res = await fetch(process.env.VUE_APP_URL + '/inventory/warehouse', requestOptions);
            const data = await res.json();

            if (data.status === true) {
              this.activities = data.inventory;
            } else {
              // Handle the case where the response status is not true
              console.error("Failed to fetch inventory: ", data.message);
            }

          } catch (error) {
            this.loading = false
          }finally{
            this.loading = false
          }



        },

    
      exportData() {
      const data = this.activities.map((activity, index) => ({
        'No.': index + 1,
        'Product Description': activity?.Product?.name,
        'Category': activity?.Product?.Category?.category,
        'Unit': activity?.unit?.unit,
        'On Hand': activity.quantity + activity.quantityReserved,
        'Qty Reserved': activity?.quantityReserved,
        'Qty Available': activity?.quantity,
        'Qty Value (Kshs)': activity?.Product?.pricePurchase,
        'Stock Level Alert': 'good',
        'Warehouse': activity?.Warehouse?.name,
       
      }));
          const fileName = new Date().getTime()+'_warehouse_products';
          const exportType = 'csv';
          
          const fields = ['No.', 'Date', 'Activity', 'Shipped From', 'Qty-In', 'Qty Adj', 'Qty Trans', 'Qty Res', 'Qty-Out', 'BPN', 'PN/DN','Requested By', 'Shipped To', 'Project/Site', 'Balance'];
          console.log({data})
          console.log({fields})
          //exportFromJSON({ data, fileName, exportType, fields });
          exportFromJSON({ data, fileName, exportType });
        },
        
        
    }
  }
  </script>