<template>
    <div class="home">
  
    <Header :mainHeader="true"/>

    <section id="settings-users">
        <div class="container">
            <h5 class="mb-3 fw-bold text-primary">System Settings & Configurations – Manage Users</h5>
            <h5 for="" class="text-primary fw-bold">1. Add user</h5>
            <div class="panel">
                <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="address">System Role</label>
                        <select v-model="roleId" class="full form-control" id="roles"  v-if="roles.length > 0">
                            <option v-for="role in roles" v-bind:Key="role.id" :value="role.id">
                                {{ role.name }}
                            </option>
                        </select>
                        <div v-else>
                            <h5 for="" style="color: red !important;">No Role Found.</h5> 
                            <button class="btn btn-primary btn-sm" @click="this.fetchRoles">Fetch Roles</button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                        <label for="">Name</label>
                        <input type="text"  class="form-control" v-model="name" >
                    </div>
                   
                    <div class="col-md-2">
                        <label for="">Designation</label>
                        <input type="text"  class="form-control" v-model="designation" >
                    </div>
                    <div class="col-md-2">
                        <label for="">Employee Id No.</label>
                        <input type="text"  class="form-control" v-model="employeeId" >
                    </div>
                    <div class="col-md-2">
                        <div class="check-button">
                        <div class="form-group">
                            <label>System User?</label>
                            <div class="components-button">
                        <span class="switcher switcher-1">
                            <input type="checkbox" id="switcher-1" v-model="isSystemUser">
                            <label for="switcher-1"></label>
                        </span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="">Upload ID</label>
                        <input type="file"  class="form-control" ref="fileUpload" @change="handleFileChange">
                    </div>
                    <div class="col-md-2">
                        <label for="">Upload Signature</label>
                        <input type="file"  class="form-control" ref="fileSignature" @change="handleFileChange">
                    </div>
                    <div class="col-md-2">
                        <label for="">Upload Passport Pciture</label>
                        <input type="file"  class="form-control" ref="filePicture" @change="handleFileChange">
                    </div>
                    <div class="col-md-2">
                        <label for="">Mobile Contact No. 1</label>
                        <input type="text" placeholder="Use +2547... (format)"  class="form-control" v-model="phone" >
                    </div>
                    <div class="col-md-2">
                        <label for="">Mobile Contact No. 2</label>
                        <input type="text" placeholder="Use +2547... (format)"  class="form-control" v-model="phone2" >
                    </div>
                    <div class="col-md-2">
                        <label for="">Email Address</label>
                        <input type="email"  class="form-control" v-model="email" >
                    </div>
                    <div class="col-md-2">
                        <label for="">ID Front</label>
                        <input class="form-control" type="file" id="id-front" ref="idFront" @change="handleIDChange">
                    </div>
                    <div class="col-md-2 mt-3">
                        <label for="">ID Back</label>
                        <input class="form-control" type="file" id="id-back" ref="idBack" @change="handleIDChange">
                        
                    </div>
                    <div class="col-md-2" v-if="loading">
                        <div style="margin-top: 50px;">
                            processing ID<img  src="../../assets/images/loader.gif" class="" /> 
                        </div>
                    </div>
                    <div class="col-md-1">
                        <button class="btn btn-style-2 btn-small" @click="postUser" style="margin-top: 42px;">Create</button>
                    </div>
                </div>
            </div>


            <h5 class="fw-bold text-primary mt-4 mb-0">2. System Users</h5>
            <div class="panel" style="padding-top: 0px">
                <div class="row">
                    <table class="table table-hover">
                        <thead>
                            <th>No.</th>
                            <th>Name </th>
                            <th>Role </th>
                            <th>Phone </th>
                            <th>System Status </th>
                            <th>User Status </th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            <tr v-for="(user,index) in users" v-bind:key="user.id">
                                <td>{{ index+1 }}</td>
                                <td>{{ user.name}}</td>
                                <td>{{ user.Permission?.name}}</td>
                                <td>{{ user.phone}}</td>
                                <td>
                                  <button class="btn btn-primary btn-sm" v-if="user.isSystemUser == true">System User</button>
                                  <button class="btn btn-default btn-sm" style="border: 1px solid #000; background-color: #f2f2f2;" v-else>Non - User</button>
                                </td>
                                <td>
                                  <button class="btn btn-success btn-sm " style="margin-left: 5px;" v-if="user.isActive == true">Active</button>
                                  <button class="btn btn-danger btn-sm " style="margin-left: 5px;" v-else>In - Active</button>
                                </td>
                                <td>
                                    <button class="btn-sm btn btn-default" style="border: 1px solid #000"  @click="$router.push({name: 'ViewUser', params:{ id: user.id}})">View</button>
                                    <div class="btn-group" role="group">
                                      <button type="button" class="btn btn-primary btn-sm dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                                      <ul class="dropdown-menu">
                                          <li v-if="user.isActive == true"><a class="dropdown-item" @click="suspendUser(user.id)">Suspend User</a></li>
                                          <li v-else><a class="dropdown-item" @click="unsuspendUser(user.id)">Unsuspend User</a></li>
                                          <li><a class="dropdown-item" @click="deleteUser(user.id)">Delete User</a></li>
                                          <li v-if="user.isActive == true"><a class="dropdown-item" @click="passwordUser(user.id)">Reset Password</a></li>
                                      </ul>
                                  </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </section>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import Header from '../../components/Header';
import axios from 'axios'




export default {
    name: 'SettingsUsers',
    components:{
        Header
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        url:process.env.VUE_APP_URL,
        name: "",
        email: "",
        phone: "",
        roleId:"",
        isSystemUser:true,
        designation:"",
        employeeId : "",
        phone2:"",
        uploadSignature:"",
        uploadPicture:"",
        upload:"",
        loading: false
    }),
    computed:{
        ... mapGetters (["getUsers","getRoles"]),
        users () { return this.getUsers },
        roles () { return this.getRoles },
    },
    methods:{
        ... mapActions(['fetchUsers', 'fetchRoles']),
        async postUser () {
            let formData = new FormData()
            formData.append("name", this.name)
            formData.append("upload", this.upload)
            formData.append("picture", this.uploadPicture)
            formData.append("signature", this.uploadSignature)
            formData.append("designation", this.designation)
            formData.append("employeeId", this.employeeId)
            formData.append("isSystemUser", this.isSystemUser)
            formData.append("roleId", this.roleId)
            formData.append("email", this.email)
            formData.append("phone", this.phone)
            formData.append("phone2", this.phone2)
            const requestOptions = {
                method: "POST",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
                body:formData
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users', requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'User Created',
                    text: data.message
                })
                this.fetchUsers();

            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'User not created',
                    text: data.message
                })
            }
        },


        
       async handleIDChange() {
          this.idFront = this.$refs.idFront.files[0];
          this.idBack = this.$refs.idBack.files[0];
          const formaData ={
            front_image: this.idFront,
            back_image: this.idBack
          }
          try{
            this.loading = true
            if(this.idFront !== null &&  this.idBack !== null){
            const response = await axios.post(
              `https://zebra-advanced-manually.ngrok-free.app/kyc/predict?api_key=2683e658-4bd8-4945-aff2-9db67981b674`,
              formaData,
              {
                headers: {
                  'Content-Type': `multipart/form-data`,
  
                },
              }
            )
            const data = response.data.result
            this.name = data.fullName
            this.idNumber = data.idNumber
            console.log("KYC",response)
          }
          }catch(err){
            console.log(err)
          }finally{
            this.loading = false
          }
          
        },





        async suspendUser (userid) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/suspend/'+userid, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'User suspended successfully',
                    text: 'User account suspended and cannot access the system '
                })
                this.fetchUsers();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'User not suspended',
                    text: data.message
                })
            }
        },
        async unsuspendUser (userid) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/unsuspend/'+userid, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'User unsuspended successfully',
                    text: 'User account unsuspended and can now access the system '
                })
                this.fetchUsers();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'User not unsuspended',
                    text: data.message
                })
            }
        },
        async deleteUser (userid) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/delete/'+userid, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'User deleted successfully',
                    text: 'User account deleted'
                })
                this.fetchUsers();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'User not deleted',
                    text: data.message
                })
            }
        },
        async passwordUser (userid) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/password/'+userid, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'User password reset successfully',
                    text: 'User account password reset. Check your phone number for new password'
                })
                this.fetchUsers();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'User password not reset',
                    text: data.message
                })
            }
        },

        handleFileChange() {
          this.upload = this.$refs.fileUpload.files[0];
          this.uploadSignature = this.$refs.fileSignature.files[0];
          this.uploadPicture = this.$refs.filePicture.files[0];
        }
        
    },
    mounted(){
        this.fetchUsers()
        this.fetchRoles()
    }
}
</script>

<style lang="scss" scoped>
input {
    border: 1px solid #b4c7e7;
    border-radius: 3px;
}
::placeholder{
    color: black !important;
    opacity: .7 !important;
    text-transform: none !important;
}
table{
    margin-left: 12px;
}
th, td{
    text-align: center !important;
}

$primary: #d2d2d2;
$green: #4448B3;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                    height: 30px !important;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                    height: 30px !important;
                  }
                }
              }
            }
          }
        }
      }
  </style>