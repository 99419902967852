<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h4 class="mb-3 fw-bold text-primary">Generate Purchase Order</h4>
          <strong>1. Add Details</strong>
          <div>
            <div class="form">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="product-name">Department</label>
                    <select  v-model="department" class="full"  v-if="departments.length > 0">
                      <option v-for="department in departments" v-bind:key="department.id" :value="department.name">
                          {{  department.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Project</label>
                    <!--<input type="text" class="form-control" id="item-code" v-model="project">-->
                    <select v-model="project" class="full" id="projects"  v-if="projects?.length > 0">
                      <option v-for="proj in projects" v-bind:Key="proj.id" :value="proj.name">
                          {{ proj.name }}
                      </option>
                    </select>
                    <div v-else>
                      <h5 for="" style="color: red !important;">No Projects Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchProjects">Fetch Projects</button>
                    </div>

                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="suppliers">Supplier</label>
                    <select v-model="supplierId" class="full" id="suppliers"  v-if="suppliers.length > 0">
                        <option v-for="supplier in suppliers" v-bind:Key="supplier.id" :value="supplier.id">
                            {{ supplier.name }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Supplier Found.</h5> 
                        <button class="btn-style btn-sm" @click="this.fetchSuppliers">Fetch Suppliers</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="supplierContact">Contacts</label>
                    <input type="text" class="form-control" id="supplierContact" readonly v-model="supplierContact">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="payments">Terms of Payment</label>
                    <select v-model="paymentId" class="full" id="payments"  v-if="payments.length > 0">
                        <option v-for="payment in payments" v-bind:Key="payment.id" :value="payment.id">
                            {{ payment.payment }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Payment Found.</h5> 
                        <button class="btn-style btn-sm" @click="this.fetchPayments">Fetch Payments</button>
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Requisition Number</label>
                    <input type="text" class="form-control" id="item-code">
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">VAT {{vat}}</label>
                    <div class="components-button">
                      <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="vat">
                        <label for="switcher-1"></label>
                      </span>
                    </div>
                  </div>
                </div>
              
                <div class="col-md-1" :style="{ 'display': displayButton }">
                    <button class="btn btn-style-2" style="margin-top: 31px;" @click="postPurchase">Add</button>
                </div>
  
              </div>
  
            </div>
          </div>
          <div :style="{ 'display': displayItems }" style="margin-top:30px">
            <strong>2. Add Products</strong>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="supplierContact">Product Name</label>
                  <select v-model="productId" class="full form-control" id="products"  v-if="products.length > 0">
                    <option v-for="product in products" v-bind:Key="product.id" :value="product.id">
                        {{ product.name }}
                    </option>
                  </select>
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Product Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchProducts">Fetch Products</button>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Product Code</label>
                  <input type="text" readonly v-model="productCode" class="form-control">
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Unit</label>
                  <input type="text" readonly v-model="productUnit" class="form-control">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Product Description</label>
                  <input type="text" v-model="productDescription" class="form-control">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Qty Requested</label>
                  <input type="number" min="0" v-model="productRequest" class="form-control">
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Amount</label>
                  <input type="text" v-model="productPrice" class="form-control">
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Action</label><br>
                  <button class="btn btn-default pull-right" style="border:1px solid black;" @click="postProduct">Add</button>
                </div>
              </div>
            </div>

            <p></p>
            <p></p>

            <strong class="mt-5" style="margin-top:30px !important">3. Product List</strong>
            <table class="table table-hover">
                <thead>
                    <th>No.</th>
                    <th>Product</th>
                    <th>Product Code</th>
                    <th>Product Description</th>
                    <th>Unit</th>
                    <th>Qty Requesting</th>
                    <th>Price</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="items.length > 0">
                    <tr v-for="(item, index) in items" v-bind:key="item.id">

                        <td>{{ (index+1) }}</td>
                        <td>{{ item.Product.name }}</td>
                        <td>{{ item.Product.code }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.Product.unit.unit }}</td>
                        <td>{{ formatQty(item.quantity) }}</td>
                        <td>{{ formatMoney(item.price) }}</td>
                        <td>
                            <button class="btn btn-default btn-sm" style="border:1px solid black;" @click="deleteProduct(item.id)">Remove</button>
                        </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Total</td>
                      <td>{{ formatMoney(total) }}</td>
                      <td></td>
                    </tr>
                </tbody>
                <div v-else>
                  <label for="" class="text-danger" style="margin: 0px;  display: block;">No product found</label>
                </div>
            </table>
  
            <div>
              <button class="btn-style small" style="border: 1px solid black; float: right;" @click="generatePurchase">Generate</button>
              <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="$router.push('/purchases')">Close</button>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  
  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  
  export default {
    name: 'AddPurchasePage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        displayItems: "none",
        displayButton: "block",
        supplierId: "",
        paymentId:"",
        total: 0,
        supplierContact: "",
        department: "",
        project: "",
        purchaseId:"",
        productId: "",
        productCode: "",
        productDescription: "",
        productUnit: "",
        productQty: "",
        productRequest: "",
        productPrice: "",
        items:[],
        vat: true
    }),
    computed:{
        ... mapGetters (["getPayments", "getSuppliers", "getProducts", "getProductOptions", "getProjects", "getDepartments"]),
        payments () { return this.getPayments },
        suppliers () { return this.filterPrequalified(this.getSuppliers) },
        products () { return this.getProducts },
        productOptions () { return this.getProductOptions },
        projects() { return this.getProjects},
        departments () { return this.getDepartments }
    },
    methods:{
        ...mapActions(["fetchPayments", "fetchSuppliers", "fetchProducts","fetchProjects", "fetchDepartments"]),
        async generatePurchase () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/purchases/generate/'+this.purchaseId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                  position: 'top-center',
                  icon: 'success',
                  title: 'Purchase Generated',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.$router.push('/purchases')          
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Purchase not generated',
                    text: data.message
                })
            }
        },

        filterPrequalified(arr) {
          return arr.filter(item => item.prequalified === true);
        },

        async postPurchase () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                  department: this.department,
                  project: this.project,
                  supplierId: this.supplierId,
                  paymentId: this.paymentId,
                  vat: this.vat
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/purchases', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.purchaseId = data.purchase.id
                this.displayButton = "none"
                this.displayItems = "block"
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Product not created',
                    text: data.message
                })
            }
        },

        async postProduct() {
          const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                  description: this.productDescription,
                  quantity: this.productRequest,
                  price: this.productPrice,
                  purchaseId: this.purchaseId,
                  productId: this.productId,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/purchases/products', requestOptions)
            const data = await res.json()
            if(data.status == true){
              this.productRequest = ""
              this.productDescription = ""
              this.productPrice = ""
              this.items = data.products
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Product not added',
                    text: data.message
                })
            }
        },
        async deleteProduct(id) {
          const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token }
            };
            const res = await fetch(process.env.VUE_APP_URL+'/purchases/products/'+id+'/'+this.purchaseId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.items = data.products
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Purchase Order Product not deleted',
                    text: data.message
                })
            }
        }
        
    },
    mounted(){
        this.fetchPayments()
        this.fetchSuppliers()
        this.fetchProducts()
        this.fetchProjects()
        this.fetchDepartments()
    },
    watch:{
        supplierId(newValue){
            let supplierObj = this.suppliers.find(i => i.id === newValue);
            this.supplierContact = supplierObj.address
        },
        productId(newValue){    
          // const productObj = JSON.parse(JSON.stringify(this.products))
          // console.log(newValue)
          // console.log(productObj)
          // let item = productObj.find(i => i.id === newValue);
          // console.log(item)
          // this.productCode = item.code
          // this.productDescription = item.name
          // this.productUnit = item.unit.unit
          let item = this.products.find(i => i.id === newValue);
          this.productCode = item.code
          this.productUnit = item.unit.unit
        },
        items(newValue){
          var sum = 0;
          newValue.forEach(e => {
              sum += e.price;
          });
          this.total=sum
        }
    },
  }
  </script>


<style lang="scss" scoped>
$text-color: #002060;
input[readonly]{
  background-color: #cfcece !important;
}

select {
  /* inline SVG */
  background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"), #fff;
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius: 3px;
  height: 45px;
  border: 1px solid #b4c7e7 !important;
  padding: 5px 30px 5px 10px;
  // disable default appearance
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand { display: none };
  &.full{
    width: 100%;
  }
  &:focus, &:hover{
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"), #fff;
    border: 1px solid $text-color;
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
  }
}


$primary: #d2d2d2;
$green: #4448B3;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                    height: 30px !important;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                    height: 30px !important;
                  }
                }
              }
            }
          }
        }
      }
  </style>