<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
            <h4 class="mb-3 fw-bold title">System Settings & Configurations – Roles & Permissions</h4>
          <div>
            <div class="form">
              <div class="row">
                <strong>1. Role Details</strong>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="address">Name</label>
                    <input type="text" id="address" class="form-control" v-model="name">
                  </div>
                </div>


                <div class="col-md-4">
                  <div class="form-group">
                    <label for="address">Description</label>
                    <input type="text" id="address" class="form-control" v-model="description">
                  </div>
                </div>


                <div class="col-md-3 mt-1">
                    <label for="">Department</label>
                    <select  v-model="departmentId" class="full"  v-if="departments.length > 0">
                        <option v-for="department in departments" v-bind:key="department.id" :value="department.id">
                            {{  department.name }}
                        </option>
                    </select>
                    <div v-else>
                        <label for="" style="color: red !important;">No Deparment Found.</label> 
                        <button  class="btn btn-primary btn-sm" @click="this.fetchDepartments">Fetch Departments</button>
                    </div>
                </div>


                <div class="col-md-2 mt-1">
                    <label for="">Department Rank</label>
                    <select  v-model="departmentRank" class="full"  v-if="departments.length > 0">
                        <option v-for="index in ranks" v-bind:key="index" :value="index">
                            {{ index }}
                        </option>
                    </select>
                </div>


              </div>
            </div>

            <div class="form mt-3">
                <strong>2. Permission Details</strong>
                
              <div class="row mt-2 panel mb-4">
                <h5>Login and Dashboard</h5>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can view Transactions?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="transaction">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can view Dashboard?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="dashboard">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
              </div>
              
              <div class="row panel mb-4">
                <h5>System users</h5>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can Login?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="login">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can view users?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="usersView">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can add a new user?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="usersAdd">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can update and edit user detail?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="usersManage">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
              </div>
              <div class="row panel mb-4">
                <h5>Warehouse</h5>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can view warehouses?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="warehouseView">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can add a new warehouse?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="warehouseAdd">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can update and edit warehouse detail?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="warehouseManage">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
              </div>
              <div class="row panel mb-4">
                <h5>Purchase Orders</h5>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can view purchase orders?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="purchaseView">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can add a new purchase order?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="purchaseAdd">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can update and edit purchase order detail?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="purchaseManage">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
              </div>
              <div class="row panel mb-4">
                <h5>Supplier</h5>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can view suppliers?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="supplierView">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can add a new supplier?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="supplierAdd">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can update and edit supplier detail?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="supplierManage">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
              </div>
              <div class="row panel mb-4">
                <h5>System Configurations</h5>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can manage categories?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="categoriesManage">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can manage products?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="productsManage">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div> 
              </div>
              <div class="row panel mb-4">
                <h5>Pickup Notes</h5>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can create a Pickup Note?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="pickupAdd">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can process a Pickup Note?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="pickupProcess">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div> 
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can cancel a Pickup Note?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="pickupCancel">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div> 
              </div>
              <div class="row panel mb-4">
                <h5>Dispatch Notes</h5>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can create a Dispatch Note?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="dispatchAdd">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can process a Dispatch Note?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="dispatchProcess">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div> 
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can cancel a Dispatch Note?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="dispatchCancel">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div> 
              </div> 
              <div class="row panel mb-4">
                <h5>Batch Processing Notes</h5>
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can create a Batch Processing Note?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="batchAdd">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>  
                <div class="col-md-3">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can verify a Batch Processing Note?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="batchVerify">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div> 
              </div>
              <div class="row panel mb-4">
                <h5>Approvals</h5>
                <div class="col-md-3" v-if="!approveAll">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can approve Stock Transfer?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="approveTransfer">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                    
                    <div class="mb-5">
                        <multiselect 
                            v-if="approveTransfer"
                            v-model="transferDepartments" 
                            :options="departments" 
                            :multiple="true" 
                            :track-by="trackBy"
                            :label="label"
                            placeholder="Select departments">
                        </multiselect>
                    </div>
                    
                </div>  
                <div class="col-md-3" v-if="!approveAll">
                    <div class="check-button">
                        <div class="form-group">
                            <label>Can approve Stock Adjustment?</label>
                            <div class="components-button">
                        <span class="switcher switcher-1">
                            <input type="checkbox" id="switcher-1" v-model="approveAdjustment">
                            <label for="switcher-1"></label>
                        </span>
                            </div>
                        </div>
                    </div>

                    <div class="mb-5">
                        <multiselect 
                            v-if="approveAdjustment"
                            v-model="adjustmentDepartments" 
                            :options="departments" 
                            :multiple="true" 
                            :track-by="trackBy"
                            :label="label"
                            placeholder="Select departments">
                        </multiselect>
                    </div>
                    
                </div>  
                <div class="col-md-3" v-if="!approveAll">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can approve a Purchase Order?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="approvePurchase">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>

                    <div class="mb-5">
                        <multiselect 
                            v-if="approvePurchase"
                            v-model="purchaseDepartments" 
                            :options="departments" 
                            :multiple="true" 
                            :track-by="trackBy"
                            :label="label"
                            placeholder="Select departments">
                        </multiselect>
                    </div>
                </div>  

                <div class="col-md-3" v-if="!approveAll">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Can approve a Pickup Order?</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="approvePickup">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>

                    <multiselect 
                        v-if="approvePickup"
                        v-model="pickupDepartments" 
                        :options="departments" 
                        :multiple="true" 
                        :track-by="trackBy"
                        :label="label"
                        placeholder="Select departments">
                    </multiselect>
                </div>
                    
                <div class="col-md-3" v-if="!approveAll">
                    <div class="check-button">
                        <div class="form-group">
                            <label>Can approve a Dispatch Order?</label>
                            <div class="components-button">
                                <span class="switcher switcher-1">
                                    <input type="checkbox" id="switcher-1" v-model="approveDispatch">
                                    <label for="switcher-1"></label>
                                </span>
                            </div>
                        </div>
                    </div>

                    <multiselect 
                        v-if="approveDispatch"
                        v-model="dispatchDepartments" 
                        :options="departments" 
                        :multiple="true"
                        :track-by="trackBy"
                        :label="label"
                        placeholder="Select departments"
                        >
                    </multiselect>
                </div>  


                <div class="col-md-3">
                    <div class="check-button">
                        <div class="form-group">
                            <label>Can approve All Requests?</label>
                            <div class="components-button">
                                <span class="switcher switcher-1">
                                    <input type="checkbox" id="switcher-1" v-model="approveAll">
                                    <label for="switcher-1"></label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>  

              </div>
            </div>

            <div style="margin-bottom: 50px !important">
              <button class="btn-style small" style="border: 1px solid black; float: right; margin-bottom: 30px;" @click="updateRole">Update</button>
              <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="$router.push('/settings/roles')">Close</button>
            </div>
  
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  import Header from '../../components/Header.vue';
  import Multiselect from 'vue-multiselect';
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'RoleEdit',
    components: {
      Header,
      Multiselect
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        name:"",
        description:"",
        departmentId:"",
        departmentRank: "",
        role:"",
        roleId: "",
        login: false,
        dashboard:false,
        transaction:false,
        usersView: false,
        usersManage: false,
        usersAdd: false,
        warehouseView: false,
        warehouseAdd: false,
        warehouseManage: false,
        purchaseView: false,
        purchaseAdd: false,
        purchaseManage: false,
        supplierView: false,
        supplierAdd: false,
        supplierManage: false,
        categoriesManage: false,
        productsManage: false,
        pickupAdd: false,
        pickupProcess: false,
        pickupCancel: false,
        dispatchAdd: false,
        dispatchProcess: false,
        dispatchCancel: false,
        batchAdd: false,
        batchVerify: false,
        approveTransfer: false,
        approveAdjustment: false,
        approvePurchase: false,
        approvePickup: false, 
        approveDispatch: false,
        approveAll: false,
        purchaseDepartments: [],
        pickupDepartments: [],
        dispatchDepartments: [],
        transferDepartments: [], 
        adjustmentDepartments: []
    }),


    mounted(){
        this.roleId = this.$route.params.id
        this.setupRole(this.roleId)
        this.fetchDepartments()
    },

    watch: {
        departmentId () {
        const department = this.departments.filter(this.isDepartment)
        if(department.length > 0)
            this.ranks = department[0].ranks
        
        }
    },

    computed:{
        ... mapGetters (['getDepartments']),
        trackBy() {
            return 'id'; 
        },
        label() {
            return 'name'; 
        },
        departments () { return this.getDepartments },
        
        dispatchIds(){
            return this.dispatchDepartments.map(option => option.id)
        },

        pickupIds(){
            return this.pickupDepartments.map(option => option.id)
        },
 
        purchaseIds(){
            return this.purchaseDepartments.map(option => option.id)
        },

        transferIds(){
            return this.transferDepartments.map(option => option.id)
        },
 
        adjustmentIds(){
            return this.adjustmentDepartments.map(option => option.id)
        },

    },

    methods:{
        ... mapActions(["fetchDepartments"]),
     
        formatBoolean(value){
            if(value){
            return true
            } else{
            return false
            }
        },

        getDepartmentIds(idArray) {
            return this.departments.filter(obj => idArray.includes(obj.id));
        },

        isDepartment(element){
            return element.id == this.departmentId
        },

        async setupRole(roleId){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/permissions/'+roleId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                //console.log({data})
                this.role = data.permission;
                this.name = this.role.name
                this.description = this.role.description
                this.login = this.formatBoolean(this.role.login)
                this.dashboard = this.formatBoolean(this.role.dashboard)
                this.transaction = this.formatBoolean(this.role.transaction)
                this.usersView = this.formatBoolean(this.role.usersView)
                this.usersManage = this.formatBoolean(this.role.usersManage)
                this.usersAdd = this.formatBoolean(this.role.usersAdd)
                this.warehouseView = this.formatBoolean(this.role.warehouseView)
                this.warehouseAdd = this.formatBoolean(this.role.warehouseAdd)
                this.warehouseManage = this.formatBoolean(this.role.warehouseManage)
                this.purchaseView = this.formatBoolean(this.role.purchaseView)
                this.purchaseAdd = this.formatBoolean(this.role.purchaseAdd)
                this.purchaseManage = this.formatBoolean(this.role.purchaseManage)
                this.supplierView = this.formatBoolean(this.role.supplierView)
                this.supplierAdd = this.formatBoolean(this.role.supplierAdd)
                this.supplierManage = this.formatBoolean(this.role.supplierManage)
                this.categoriesManage = this.formatBoolean(this.role.categoriesManage)
                this.productsManage = this.formatBoolean(this.role.productsManage)
                this.pickupAdd = this.formatBoolean(this.role.pickupAdd)
                this.pickupProcess = this.formatBoolean(this.role.pickupProcess)
                this.pickupCancel = this.formatBoolean(this.role.pickupCancel)
                this.dispatchAdd = this.formatBoolean(this.role.dispatchAdd)
                this.dispatchProcess = this.formatBoolean(this.role.dispatchProcess)
                this.dispatchCancel = this.formatBoolean(this.role.dispatchCancel)
                this.batchAdd = this.formatBoolean(this.role.batchAdd)
                this.batchVerify = this.formatBoolean(this.role.batchVerify)
                this.approveTransfer = this.formatBoolean(this.role.approveTransfer)
                this.approveAdjustment = this.formatBoolean(this.role.approveAdjustment)
                this.approvePurchase = this.formatBoolean(this.role.approvePurchase)
                this.approvePickup = this.formatBoolean(this.role.approvePickup)
                this.approveDispatch = this.formatBoolean(this.role.approveDispatch)
                this.approveAll = this.formatBoolean(this.role.approveAll)
                this.departmentId = this.role?.departmentId
                this.departmentRank = this.role?.deparmentRank

                this.purchaseDepartments = this.getDepartmentIds(this.role.purchaseDepartments)
                this.pickupDepartments = this.getDepartmentIds(this.role.pickupDepartments)
                this.dispatchDepartments = this.getDepartmentIds(this.role.dispatchDepartments)
                this.transferDepartments = this.getDepartmentIds(this.role.transferDepartments)
                this.adjustmentDepartments = this.getDepartmentIds(this.role.adjustmentDepartments)
                
                /*console.log("Purchase Departments",this.role.purchaseDepartments)
                console.log("Pickup Departments", this.role.pickupDepartments)
                console.log("Dispatch Departments", this.role.dispatchDepartments)
                console.log("Transfer Departments", this.role.transferDepartments)
                console.log("Adjustment Departments",  this.role.adjustmentDepartments)*/
               
                

            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Role not found. Try again',
                    text: data.message
                })
            }
        },
      async updateRole(){
        if(!this.approveTransfer) 
            this.transferDepartments = []
        if(!this.approveAdjustment)
            this.adjustmentDepartments = []
        if(!this.approvePurchase)
            this.purchaseDepartments = []
        if(!this.approvePickup)
            this.pickupDepartments = []
        if(!this.approveDispatch)
            this.dispatchDepartments = []

        const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                name: this.name,
                description: this.description,
                transaction: this.transaction,
                dashboard: this.dashboard,
                usersView: this.usersView,
                usersManage: this.usersManage,
                usersAdd: this.usersAdd,
                warehouseView: this.warehouseView,
                warehouseAdd: this.warehouseAdd,
                warehouseManage: this.warehouseManage,
                purchaseView: this.purchaseView,
                purchaseAdd: this.purchaseAdd,
                purchaseManage: this.purchaseManage,
                supplierView: this.supplierView,
                supplierAdd: this.supplierAdd,
                supplierManage: this.supplierManage,
                categoriesManage: this.categoriesManage,
                productsManage: this.productsManage,
                pickupAdd: this.pickupAdd,
                pickupProcess: this.pickupProcess,
                pickupCancel: this.pickupCancel,
                dispatchAdd: this.dispatchAdd,
                dispatchProcess: this.dispatchProcess,
                dispatchCancel: this.dispatchCancel,
                batchAdd: this.batchAdd,
                batchVerify: this.batchVerify,

                approveTransfer: this.approveTransfer,
                approveAdjustment: this.approveAdjustment,
                approvePurchase: this.approvePurchase,

                approvePickup: this.approvePickup,
                approveDispatch: this.approveDispatch,

                purchaseDepartments: this.purchaseIds, 
                pickupDepartments: this.pickupIds,
                dispatchDepartments: this.dispatchIds,
                transferDepartments: this.transferIds, 
                adjustmentDepartments: this.adjustmentIds,
                approveAll: this.approveAll,
                departmentId: this.departmentId,
                departmentRank: this.departmentRank
            
              })
          };

          /*
          console.log("Purchase Departments",this.purchaseDepartments)
          console.log("Pickup Departments", this.pickupDepartments)
          console.log("Dispatch Departments", this.dispatchDepartments)
          console.log("Transfer Departments", this.transferDepartments)
          console.log("Adjustment Departments",  this.adjustmentIds)
          console.log("Purchase Departments", this.purchaseIds) */

          
          const res = await fetch(process.env.VUE_APP_URL+'/permissions/'+this.roleId, requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.role = data.permission;
                this.name = this.role.name
                this.description = this.role.description
                this.login = this.formatBoolean(this.role.login)
                this.dashboard = this.formatBoolean(this.role.dashboard)
                this.transaction = this.formatBoolean(this.role.transaction)
                this.usersView = this.formatBoolean(this.role.usersView)
                this.usersManage = this.formatBoolean(this.role.usersManage)
                this.usersAdd = this.formatBoolean(this.role.usersAdd)
                this.warehouseView = this.formatBoolean(this.role.warehouseView)
                this.warehouseAdd = this.formatBoolean(this.role.warehouseAdd)
                this.warehouseManage = this.formatBoolean(this.role.warehouseManage)
                this.purchaseView = this.formatBoolean(this.role.purchaseView)
                this.purchaseAdd = this.formatBoolean(this.role.purchaseAdd)
                this.purchaseManage = this.formatBoolean(this.role.purchaseManage)
                this.supplierView = this.formatBoolean(this.role.supplierView)
                this.supplierAdd = this.formatBoolean(this.role.supplierAdd)
                this.supplierManage = this.formatBoolean(this.role.supplierManage)
                this.categoriesManage = this.formatBoolean(this.role.categoriesManage)
                this.productsManage = this.formatBoolean(this.role.productsManage)
                this.pickupAdd = this.formatBoolean(this.role.pickupAdd)
                this.pickupProcess = this.formatBoolean(this.role.pickupProcess)
                this.pickupCancel = this.formatBoolean(this.role.pickupCancel)
                this.dispatchAdd = this.formatBoolean(this.role.dispatchAdd)
                this.dispatchProcess = this.formatBoolean(this.role.dispatchProcess)
                this.dispatchCancel = this.formatBoolean(this.role.dispatchCancel)
                this.batchAdd = this.formatBoolean(this.role.batchAdd)
                this.batchVerify = this.formatBoolean(this.role.batchVerify)
                this.approveTransfer = this.formatBoolean(this.role.approveTransfer)
                this.approveAdjustment = this.formatBoolean(this.role.approveAdjustment)
                this.approvePurchase = this.formatBoolean(this.role.approvePurchase)
                Swal.fire({
                icon: 'success',
                title: 'Role updated successfully',
                text: 'Role updated'
            })
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Role not found. Try again',
                text: data.message
            })
          }
      },
    },
  }
  </script>

<style lang="scss" scoped>
@import 'vue-multiselect/dist/vue-multiselect.min.css';
$text-color: #002060;
$primary: #d2d2d2;
$green: #4448B3;
$white: #ffffff;
.check-button{
    display: flex;
    align-items: center;
    gap: 10px;
}
.form-group{
    .components-button {
      display: flex;
      align-items: center;
      justify-content: left;
      span.switcher {
        position: relative;
        width: 156px;
        height: 56px;
        border-radius: 10px;
        input {
          appearance: none;
          position: relative;
          width: 130px;
          height: 40px;
          border-radius: 3px;
          border-color: #d2d2d2 !important;
          background: $primary;
          outline: none;
          font-family: 'Oswald', sans-serif;
          &:before, &:after {
            z-index: 2;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: $white;
          }
          &:before {
            content: 'Yes';
            left: 16px;
            font-weight: 600;
            font-size: 16px;
            line-height: 33px;
            color: #FFFFFF;
          }
          &:after {
            content: 'No';
            right: 16px;
            font-weight: 600;
            font-size: 17px;
            line-height: 33px;
            color: #FFFFFF;
          }
        }
        label {
          z-index: 1;
          position: absolute;
          width: 52px;
          height: 32px;
          margin: 0;
          top: 4px;
          left: 71px !important;
          border-radius: 3px;
        }
        &.switcher-1, &.switcher-2 {
          input {
            transition: .25s -.1s;
            &:checked {
              background: $primary;
              &:before {
                color: $white;
                transition: color .5s .2s;
              }
              &:after {
                color: #ffffff;
                transition: color .5s;
              }
              & + label {
                left: 4px !important;
                background: $green;
                transition: left .5s, right .4s .2s;
                height: 30px !important;
              }
            }
            &:not(:checked) {
              background: $primary;
              transition: background .5s -.1s;
              &:before {
                color: #ffffff;
                transition: color .5s;
              }
              &:after {
                color: $white;
                transition: color .5s .2s;
              }
              & + label {
                left: 100px;
                right: 10px;
                background: $green;
                transition: left .4s .2s, right .5s, background .35s -.1s;
                height: 30px !important;
              }
            }
          }
        }
      }
    }
}

    select {
      /* inline SVG */
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"), #fff;
      background-position: right 10px center;
      background-repeat: no-repeat;
      background-size: auto 50%;
      border-radius: 3px;
      height: 45px;
      border: 1px solid #b4c7e7 !important;
      padding: 5px 30px 5px 10px;
      // disable default appearance
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      &::-ms-expand { display: none };
      &.full{
        width: 100%;
      }
      &:focus, &:hover{
        background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"), #fff;
        border: 1px solid $text-color;
        background-position: right 10px center;
        background-repeat: no-repeat;
        background-size: auto 50%;
      }
    }
  
    .form-control{
    border: 1px solid black; 
    border-radius: 5px;
    }
  
    .check-button{
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .form-group{
          .components-button {
            display: flex;
            align-items: center;
            justify-content: left;
            span.switcher {
              position: relative;
              width: 156px;
              height: 56px;
              border-radius: 10px;
              input {
                appearance: none;
                position: relative;
                width: 130px;
                height: 40px;
                border-radius: 3px;
                border-color: #d2d2d2 !important;
                background: $primary;
                outline: none;
                font-family: 'Oswald', sans-serif;
                &:before, &:after {
                  z-index: 2;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  color: $white;
                }
                &:before {
                  content: 'Yes';
                  left: 16px;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 33px;
                  color: #FFFFFF;
                }
                &:after {
                  content: 'No';
                  right: 16px;
                  font-weight: 600;
                  font-size: 17px;
                  line-height: 33px;
                  color: #FFFFFF;
                }
              }
              label {
                z-index: 1;
                position: absolute;
                width: 55px;
                height: 32px;
                margin: 0;
                top: 4px;
                left: 71px !important;
                border-radius: 3px;
              }
              &.switcher-1, &.switcher-2 {
                input {
                  transition: .25s -.1s;
                  &:checked {
                    background: $primary;
                    &:before {
                      color: $white;
                      transition: color .5s .2s;
                    }
                    &:after {
                      color: #ffffff;
                      transition: color .5s;
                    }
                    & + label {
                      left: 4px !important;
                      background: $green;
                      transition: left .5s, right .4s .2s;
                      height: 30px !important;
                    }
                  }
                  &:not(:checked) {
                    background: $primary;
                    transition: background .5s -.1s;
                    &:before {
                      color: #ffffff;
                      transition: color .5s;
                    }
                    &:after {
                      color: $white;
                      transition: color .5s .2s;
                    }
                    & + label {
                      left: 100px;
                      right: 10px;
                      background: $green;
                      height: 30px !important;
                      transition: left .4s .2s, right .5s, background .35s -.1s;
                    }
                  }
                }
            }
        }
    }
}

.multiselect__tag {
    background-color: blue !important; 
    color: white; 
    border-radius: 4px;
    padding: 5px 10px;
    margin: 2px;
}
  
.multiselect__tag:hover {
  background-color: #45a049; 
}

.multiselect__tag .multiselect__tag-icon {
  color: white; 
}

</style>

  