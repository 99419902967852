<template>
    <div class="home">
        <Header :mainHeader="true"/>
        <section id="settings-categories">
            <div class="container ">
                <h4 class="mb-5 fw-bold">Notification Settings</h4>
                <div class="row">
                    <div class="col-lg-8">
                        <!-- Email notifications preferences card-->
                        <div class="card card-header-actions mb-4">
                            <div class="card-header">
                                Email Notifications
                                <div class="form-switch" >
                                    <input class="form-check-input" id="flexSwitchCheckChecked" type="checkbox" checked="" style="width: 40px !important">
                                    <label class="form-check-label" for="flexSwitchCheckChecked"></label>
                                </div>
                            </div>
                            <div class="card-body">
                                <form>
                                    <!-- Form Group (default email)-->
                                    <div class="mb-3">
                                        <label class="small mb-1" for="inputNotificationEmail">Default notification email</label>
                                        <input class="form-control" id="inputNotificationEmail" type="email" value="name@example.com" disabled="">
                                    </div>
                                    <!-- Form Group (email updates checkboxes)-->
                                    <div class="mb-0">
                                        <label class="small mb-2">Choose which types of email updates you receive</label>
                                        <div class="form-check mb-2">
                                            <input class="form-check-input" id="checkAccountChanges" type="checkbox" checked="">
                                            <label class="form-check-label" for="checkAccountChanges">Changes made to your account</label>
                                        </div>
                                        <div class="form-check mb-2">
                                            <input class="form-check-input" id="checkAccountGroups" type="checkbox" checked="">
                                            <label class="form-check-label" for="checkAccountGroups">Changes are made to roles you're part of</label>
                                        </div>
                                        <div class="form-check mb-2">
                                            <input class="form-check-input" id="checkProductUpdates" type="checkbox" checked="">
                                            <label class="form-check-label" for="checkProductUpdates">Software  upgrades for the warehouse system</label>
                                        </div>
                                        <div class="form-check mb-2">
                                            <input class="form-check-input" id="checkProductNew" type="checkbox" checked="">
                                            <label class="form-check-label" for="checkProductNew">Marketing and promotional offers</label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" id="checkSecurity" type="checkbox" checked="" disabled="">
                                            <label class="form-check-label" for="checkSecurity">Security alerts</label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- SMS push notifications card-->
                        <div class="card card-header-actions mb-4">
                            <div class="card-header">
                                Push Notifications
                                <div class="form-switch">
                                    <input class="form-check-input" id="smsToggleSwitch" type="checkbox" checked="" style="width: 40px !important">
                                    <label class="form-check-label" for="smsToggleSwitch"></label>
                                </div>
                            </div>
                            <div class="card-body">
                                <form>
                                    <!-- Form Group (default SMS number)-->
                                    <div class="mb-3">
                                        <label class="small mb-1" for="inputNotificationSms">Default SMS number</label>
                                        <input class="form-control" id="inputNotificationSms" type="tel" value="123-456-7890" disabled="">
                                    </div>
                                    <!-- Form Group (SMS updates checkboxes)-->
                                    <div class="mb-0">
                                        <label class="small mb-2">Choose which types of push notifications you receive</label>
                                        <div class="form-check mb-2">
                                            <input class="form-check-input" id="checkSmsComment" type="checkbox" checked="">
                                            <label class="form-check-label" for="checkSmsComment">Changes made to your account</label>
                                        </div>
                                        <div class="form-check mb-2">
                                            <input class="form-check-input" id="checkSmsShare" type="checkbox" checked="">
                                            <label class="form-check-label" for="checkSmsShare">Changes are made to user groups you are part of</label>
                                        </div>
                                        <div class="form-check mb-2">
                                            <input class="form-check-input" id="checkSmsFollow" type="checkbox" checked="">
                                            <label class="form-check-label" for="checkSmsFollow">Marketing and promotional offers</label>
                                        </div>
                                        <div class="form-check mb-2">
                                            <input class="form-check-input" id="checkSmsGroup" type="checkbox" checked="">
                                            <label class="form-check-label" for="checkSmsGroup">Software upgrades for the Warehouse System</label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" id="checkSmsPrivateMessage" type="checkbox" checked="">
                                            <label class="form-check-label" for="checkSmsPrivateMessage" disabled>Security alerts</label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <!-- Notifications preferences card-->
                        <div class="card">
                            <div class="card-header">Notification Preferences</div>
                            <div class="card-body">
                                <form>
                                    <!-- Form Group (notification preference checkboxes)-->
                                    <div class="form-check mb-2">
                                        <input class="form-check-input" id="checkAutoGroup" type="checkbox" checked="">
                                        <label class="form-check-label" for="checkAutoGroup">Automatically subscribe to group notifications</label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" id="checkAutoProduct" type="checkbox">
                                        <label class="form-check-label" for="checkAutoProduct">Automatically subscribe to new product notifications</label>
                                    </div>
                                    <!-- Submit button-->
                                    <button class="btn btn-danger-soft text-danger">Unsubscribe from all notifications</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        
            </div>
        </section> 
    </div>
</template>

<script>
import Header from '../../components/Header';
import Swal from 'sweetalert2'
import axios from 'axios'


export default {
    name: 'SettingsCategoryPage',
    components:{
        Header
    },

    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        profile: {},
        businessName: "",
        businessEmail: "",
        businessTel:  "",
        businessWebsite: "", 
        businessLogo: "",
        businessAddress: "",
        imageUrl: "",
        widget: null
    }),

    mounted(){
      this.profile = JSON.parse(localStorage.getItem('profile'))
      //console.log(this.profile)
      this.businessName = this.profile.businessName
      this.businessEmail = this.profile.businessEmail
      this.businessTel = this.profile.businessTel
      this.businessWebsite = this.profile.businessWebsite
      this.businessLogo = this.profile.businessLogo
      this.businessAddress = this.profile.businessAddress
      this.imageUrl = this.profile.businessLogo

      //console.log(this.businessEmail)
    },

    methods:{
       

        clear(){
          this.businessName = ""
          this.businessEmail = ""
          this.businessTel = ""
          this.businessWebsite = ""
          this.businessLogo = ""
          this.businessAddress = ""
          this.imageUrl = ""
        },


        
        openUploadWidget(){
          const widget = window.cloudinary.createUploadWidget(
            {cloud_name: "djzduga98", upload_preset: "jxeytapf"},
            (error, result) => {
              //console.log(error);
              //console.log("Result", result);
              if(!error && result.event === "success"){
              // console.log(result.info)
                this.imageUrl = result.info.secure_url
                this.businessLogo = this.imageUrl
              }
            }
          );
          if(widget)
            widget.open()
        },

        async postProfile () {
          //let formaData = new FormData()
          let formaData = {
            businessName: this.businessName,
            businessEmail: this.businessEmail,
            businessTel: this.businessTel, 
            businessAddress: this.businessAddress,
            businessWebsite: this.businessWebsite, 
            businessLogo: this.businessLogo
          }

          //console.log(this.token)
          try {
            const response = await axios.post(`${process.env.VUE_APP_URL}/profile`, formaData, {
              headers: {
                'Authorization': 'Bearer ' + this.token
              }
            });

            if (response.status === 200) {
              const data = response.data;
              //console.log("Data",data)
              if (data.status === true) {
                Swal.fire({
                  icon: 'success',
                  title: 'Profile created',
                  text: data.message
                });
                //console.log(data)
                localStorage.setItem('profile', JSON.stringify(data.profile))
                window.location.reload(); 
                const { profile } = data
                this.businessName = profile.businessName
                this.businessEmail = profile.businessEmail
                this.businessTel = profile.businessTel
                this.businessWebsite = profile.businessWebsite
                this.businessLogo = profile.businessLogo

              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Profile not created',
                  text: data.message
                });
              }
            } else {
              console.error('Unexpected status code:', response.status);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to create profile. Please try again later.'
              });
            }
          }catch (error) {
            console.error('Error creating profile:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to create profile. Please try again later.'
            });
        }
      },
    },

}

</script>

<style scoped>
.card {
    box-shadow: 1px 1px 1px 1px #f2f2f2;
}
.card .card-header {
    font-weight: 500;
}
.card-header:first-child {
    border-radius: 0.35rem 0.35rem 0 0;
}



.form-control, .dataTable-input {
    display: block;
    width: 100%;
    padding: 0.875rem 1.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #69707a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5ccd6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
    color: #0061f2;
    border-bottom-color: #0061f2;
}
.nav-borders .nav-link {
    color: #69707a;
    border-bottom-width: 0.125rem;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}

.btn-danger-soft {
    color: #000;
    background-color: #f1e0e3;
    border-color: #f1e0e3;
}

.form-check-input{
    height: 20px !important;
    width: 20px !important;
}

</style>