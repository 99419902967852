<template>
    <div class="home">
        <Header :mainHeader="true"/>
        <section id="settings-categories">
            <div class="container ">
                <h4 class="mb-5 fw-bold">User Profile</h4>
                <div class="row">
                    <div class="col-lg-4">
                      <div class="card mb-4">
                        <div class="card-body text-center">
                          <img v-if="user.picture" :src="user.picture" alt="avatar"
                            class="rounded-circle img-fluid" style="width: 150px;">
                          <img v-else src="https://th.bing.com/th/id/OIP.tQYFfqM9HEki3rZPgBodgQAAAA?rs=1&pid=ImgDetMain" alt="avatar"
                            class="rounded-circle img-fluid" style="width: 150px;">
                          <h5 class="my-3">{{ user.name }}</h5>
                          <p class="text-muted mb-4 text-capitalize">{{ user?.Permission?.name}}</p>
                          <div class="d-flex justify-content-center mb-2">
                            <button  type="button" data-mdb-button-init data-mdb-ripple-init class="btn btn-primary" @click="updateProfile">Update Profile</button>
                            <!--<button  type="button" data-mdb-button-init data-mdb-ripple-init class="btn btn-outline-primary ms-1">Reset Password</button>-->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="card mb-4">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-3">
                              <p  class="mb-0 text-primary">Full Name</p>
                            </div>
                            <div class="col-sm-9">
                              <input type="text" class="text-muted mb-0" :placeholder="user.name" v-model="name"/>
                            </div>
                          </div>
                          <hr>
                          <div class="row">
                            <div class="col-sm-3">
                              <p class="mb-0 text-primary">Email</p>
                            </div>
                            <div class="col-sm-9">
                              <input type="email" class="text-muted mb-0" :placeholder="user.email" v-model="email"/>
                            </div>
                          </div>
                          <hr>
                          <div class="row">
                            <div class="col-sm-3">
                              <p class="mb-0 text-primary">Phone 1</p>
                            </div>
                            <div class="col-sm-9">
                              <input type="tel" class="text-muted mb-0" :placeholder="user.phone" v-model="phone" />
                            </div>
                          </div>
                          <hr>
                          <div class="row">
                            <div class="col-sm-3">
                              <p class="mb-0 text-primary">Phone 2</p>
                            </div>
                            <div class="col-sm-9">
                              <input type="tel" class="text-muted mb-0" :placeholder="user.phone2" v-model="phone2"/>
                            </div>
                          </div>
                          <hr>
                          <div class="row">
                            <div class="col-sm-3">
                              <p class="mb-0 text-primary">System Role</p>
                            </div>
                            <div class="col-sm-9">
                              <p class="text-muted mb-0 text-capitalize">{{ user?.Permission?.name }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


            </div>
        </section> 
    </div>
</template>

<script>
import Header from '../../components/Header';
import Swal from 'sweetalert2'
//import axios from 'axios'


export default {
    name: 'SettingsCategoryPage',
    components:{
        Header
    },

    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        user: "",
        name: "",
        email: "",
        phone: "", 
        phone2: "",
        designation: "",
        roleId:  1,
        employeeId: "",
        isSystemUser: true,
        picture: "",
        upload: "",
        uploadSignature:"",
        widget: null
    }),

    mounted(){
      this.profile = JSON.parse(localStorage.getItem('profile'))
      this.setupUser()
    },

    methods:{
        clear(){
          this.name = ""
          this.email = ""
          this.phone = ""
          this.phone2 = ""
          this.designation = ""
          this.roleId = ''
          this.phone = ""
          this.phone2 = ""
          this.email = ""
          this.picture = ""
          
        },

        openUploadWidget(){
          const widget = window.cloudinary.createUploadWidget(
            {cloud_name: "djzduga98", upload_preset: "jxeytapf"},
            (error, result) => {
              //console.log(error);
              //console.log("Result", result);
              if(!error && result.event === "success"){
              // console.log(result.info)
                this.imageUrl = result.info.secure_url
                this.businessLogo = this.imageUrl
              }
            }
          );
          if(widget)
            widget.open()
        },

        async updateProfile(){

           /* let formData = {
                name:this.name,
                isSystemUser:this.isSystemUser,
                designation:this.designation,
                employeeId:this.employeeId,
                roleId:this.roleId,
                phone:this.phone,
                phone2:this.phone2,
                email:this.email,
                picture: this.picture,
                upload: this.upload
            }*/
            let formData = new FormData()
            formData.append("name", this.name)
            formData.append("upload", this.upload)
            formData.append("picture", this.picture)
            formData.append("signature", this.uploadSignature)
            formData.append("designation", this.designation)
            formData.append("employeeId", this.employeeId)
            formData.append("isSystemUser", this.isSystemUser)
            formData.append("roleId", this.roleId)
            formData.append("email", this.email)
            formData.append("phone", this.phone)
            formData.append("phone2", this.phone2)
          
            try{
                const requestOptions = {
                  method: "POST",
                  headers: { 
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+this.token 
                  },
                  body:formData
              };
              const response = await fetch(process.env.VUE_APP_URL+'/users/'+this.user.id, requestOptions)

              if (response?.status === 200) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Profile updated',
                    text: 'Profile updated'
                  });
                  window.location.reload()
              } else {
                console.error('Unexpected status code:', response.status);
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Failed to update user. Please try again later.'
                });
              }

            }catch (error) {
                console.error('Error creating user:', error);
                Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to update user. Please try again later.'
                });
            }
        },

        async setupUser(){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/profile', requestOptions)
            const data = await res.json()
            console.log("User Data",data)
            if(data.status == true){
              this.user = data.user;
              this.name = this.user.name
              this.email = this.user.email
              this.phone = this.user.phone
              this.phone2 = this.user.phone2 
              this.designation = this.user.designation 


            }else{
            this.$router.push("/")
            }

            
        },
    },

}

</script>


<style scoped>
input{
    border: none;
    padding: 3px 5px;
    outline: none
}

input:focus{
    border-bottom: 0.5px solid grey;
}
</style>