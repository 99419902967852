<template>
    <div class="home">
        <Header :mainHeader="true"/>
        <section id="settings-categories">
            <div class="container ">
                <h4 class="fw-bold">Audit Logs</h4>

                <!--
                  <div class="py-6">
                    <div class="row">
                      <div class="offset-md-1 col-lg-10 col-md-12 col-12">
                        <div class="row align-items-center mb-6">
                          <div class="col-lg-6 col-md-12 col-12">
                         
                            <form>
                              <input type="search" class="form-control" placeholder="Search  Activity" v-model="searchQuery" @change="searchActivity">
                            </form>
                          </div>
                          <div class="col-lg-6 col-md-12 col-12 d-flex justify-content-end">
                     
                          <div>
                            <a href="#!" class="btn btn-ghost btn-icon btn-sm rounded-circle texttooltip" data-template="filterOne">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter icon-xs"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                              <div class="d-none" id="filterOne">
                                <span>Filter</span>
                              </div>
                            </a>
                          </div>
        
                          </div>
                        </div>
                   
        
                        <div class="mb-8">
                       
                          <div class="card bg-gray-300 shadow-none mb-4">
                            
                            <div class="card-body">
                              <div class="d-flex justify-content-between
                                align-items-center">
                                <div>
                                  <h5 class="mb-0">Today</h5>
                                </div>
                                <div>
                                  <a href="#!" class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 icon-xs"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        
                          <div class="card">
                            
                            <ul class="list-group list-group-flush">
                           
                              <li  class="list-group-item p-3" v-for="log in filteredLogs"  v-bind:Key="log.id">
                                <div class="d-flex justify-content-between
                                  align-items-center">
                                  <div class="d-flex align-items-center">
                                        
                                    <div>
                                      <img src="https://th.bing.com/th/id/OIP.tQYFfqM9HEki3rZPgBodgQAAAA?rs=1&pid=ImgDetMain" alt="Image" class="avatar-sm rounded-circle">
                                    </div>
                                         
                                    <div class="ms-3">
                                      <p class="mb-0
                                        font-weight-medium"><a href="#!" class="fw-bold text-primary">{{ log.User.id == user.id ? 'You' : log.User.name}}</a> {{ log.details}}</p>
                                    </div>
                                  </div>
                                  <div>
                                         
                                    <div class="dropdown dropstart">
                                      <a href="#!" class="btn btn-ghost btn-icon btn-sm rounded-circle" id="dropdownactivityOne" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical icon-xs"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                      </a>
                                      <div class="dropdown-menu" aria-labelledby="dropdownactivityOne">
                                        <a class="dropdown-item d-flex align-items-center" href="#!">Delete Log</a>
                                        <a class="dropdown-item d-flex align-items-center" href="#!" style="color: #d9d9d9">Revert Action</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            
                             
                               
                            </ul>
                          </div>
                        </div>
                        <div class="mb-8">
                          
                                
                          <div class="card">
                            
                          </div>
                        </div>
                        <div class="mb-8">
                          
                        
                          <div class="card">
                            <ul class="list-group list-group-flush">
                             
                               
                  
                             
                            </ul>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>   -->
                  <table class="table table-hover" id="logsTable" style="width: 100% !important">
                    <thead>
                      <th>No.</th>
                      <th>Date/Time</th>
                      <th>User</th>
                      <th>Entity</th>
                      <th>Action</th>
                      <th>Log</th>
                    </thead>
                  </table>
        
                  <LoaderItem v-if="loading"/>
                  
                  
            </div>
        </section> 
    </div>
</template>

<script>
import { docFooter } from '../../utils/common'
import LoaderItem from '../../components/LoaderItem.vue'
import Header from '../../components/Header';
import 'datatables.net-vue3';
import 'datatables.net-bs5';
import "datatables.net-buttons-bs5";
import "datatables.net-responsive-bs5";
import $ from 'jquery';
import 'jszip';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.print.js';
import Swal from 'sweetalert2'
import axios from 'axios'
import { filterRecords } from '@/utils/common';

export default {
    name: 'SettingsCategoryPage',
    components:{
        Header,
        LoaderItem
    },

    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        profile: {},
        user: "",
        logs: [], 
        filteredLogs: [],
        searchQuery: "",
        dataTable: null,
        processedData: [],
        loading: false,

      
    }),

    mounted(){
        this.fetchLogs()
        this.setupUser()
    },

    methods:{
        searchActivity(){
        this.filteredLogs = filterRecords(this.logs, this.searchQuery)
        },

        async setupUser(){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/profile', requestOptions)
            const data = await res.json()
            //console.log("User Data",data)
            if(data.status == true){
              this.user = data.user;
            }  
        },


        preprocessData(rawData) {
          return rawData.map(item => {
            return {
              indexNo: "1",
              user: item.User.name,
              createdAt: this.formatDate(item.createdAt,"true"),
              entity: item.entity,
              action: item.action,
              log: item.details
             
            };
          });
        },

        
    initializeDataTable() {
      try {
        this.dataTable = $(this.$el).find('#logsTable').DataTable({
          responsive: true,
          language: {
            paginate: {
              previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
              next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
            },
          },
          dom: 'frtip',
          data: this.processedData,
          columns: [
            { data: null },
            { data: 'createdAt'},
            { data: 'user' },
            { data: 'entity'},
            { data: 'action' },
            { data: 'log' },
          ],
          columnDefs: [
            {
              targets: 0,
              searchable: false,
              orderable: false,
              render: (data, type, row, meta) => {
                return meta.row + 1;
              },
            },
          ],
        });
      } catch (error) {
        console.log({ error })
      }
    },

    customizePDF(doc) {
      const profile = JSON.parse(localStorage.getItem('profile'))
      //const logoBase64 = this.convertToBase64(logo)
      //console.log(logoBase64)
      doc.pageMargins = [40, 40, 40, 40]; // Set margins for the entire page
      doc.defaultStyle.columnGap = 0
      doc.content[1].fillColor = '#f2f2f2'

      doc.header = () => {
        return {
          columns: [
            {
              width: '*',
              columns: [
                /* {
                     image: logoBase64,
                     width: 150, // Adjust the width as needed
                 },*/
                {
                  text: profile?.businessName,
                  alignment: 'left',
                  margin: [40, 15, 0, 40], // Adjust margins as needed
                  color: '#000000', // Orange color
                  bold: true,
                  fontSize: 14,

                }
              ]
            },
            {
              width: '*',
              stack: [
                {
                  text: 'Audit Logs',
                  alignment: 'right',
                  margin: [0, 15, 50, 0],
                  fontSize: 12,
                  color: '#4448B3'
                },
                {  
                  alignment: 'right',
                  margin: [300, 0, 0, 0],
                  width: '*',
                  columns: [
                    {
                      width: 'auto',
                      text: 'Date:',
                      alignment: 'right',
                      margin: [0, 0, 10, 0],
                      bold: true,
                    },
                    {
                      width: 'auto',
                      text: `${this.formatDate(new Date(), "true")}`,
                      alignment: 'right',
                      margin: [0, 0, 50, 0],
                      fontSize: 9
                    }
                  ]
                }
              ]
            }
          ],
          fillColor: '#000000'
        };
      },

        doc.footer = (currentPage, pageCount) => docFooter(currentPage, pageCount)

      doc.styles.title = {
        color: '#4448B3',
        fontSize: 13,
        margin: [80, 0, 0, 40],
      };
    },


        async fetchLogs() {
          try {
            const response = await axios.get(`${process.env.VUE_APP_URL}/logs`, { 
              headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '+this.token 
              }
            }
            );

            if (response.status == 200) {
              this.logs = response.data
              this.filteredLogs = this.logs
              console.log(this.logs)
              this.processedData = this.preprocessData(this.logs)
              this.initializeDataTable()
            } else {
              console.error('Unexpected status code:', response.status);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to fetch logs. Please try again later.'
              });
            }
          }catch (error) {
            console.error('Error fetching logs:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to fetch logs. Please try again later.'
            });
        }
      },
    },
    watch: {
      searchQuery(newValue, oldValue) {
        if (newValue.trim() !== '' && newValue.trim() !== oldValue.trim()) {
          this.filteredLogs = filterRecords(this.logs,newValue);
        } else {
          this.filteredWarehouses = this.warehouses;
        }
      }
    },
}

</script>

<style scoped>
    .card {
        border: 0;
        border-radius: 0.2rem;
    }
    .rounded-bottom {
        border-bottom-left-radius: 0.375rem !important;
        border-bottom-right-radius: 0.375rem !important;
    }
    .avatar-xxl {
        height: 7.5rem;
        width: 7.5rem;
    }
    .nav-lt-tab {
        border-top: 1px solid var(--dashui-border-color);
    }
    .px-4 {
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }

    .avatar-sm {
        height: 2rem;
        width: 2rem;
    }

    .nav-lt-tab .nav-item {
        margin: -0.0625rem 1rem 0;
    }
    .nav-lt-tab .nav-item .nav-link {
        border-radius: 0;
        border-top: 2px solid transparent;
        color: var(--dashui-gray-600);
        font-weight: 500;
        padding: 1rem 0;
    }

    .pt-20 {
        padding-top: 8rem!important;
    }

    .avatar-xxl.avatar-indicators:before {
        bottom: 5px;
        height: 16%;
        right: 17%;
        width: 16%;
    }
    .avatar-online:before {
        background-color: #198754;
    }
    .avatar-indicators:before {
        border: 2px solid #FFF;
        border-radius: 50%;
        bottom: 0;
        content: "";
        display: table;
        height: 30%;
        position: absolute;
        right: 5%;
        width: 30%;
    }

    .avatar-xxl {
        height: 7.5rem;
        width: 7.5rem;
    }
    .mt-n10 {
        margin-top: -3rem!important;
    }
    .me-2 {
        margin-right: 0.5rem!important;
    }
    .align-items-end {
        align-items: flex-end!important;
    }
    .rounded-circle {
        border-radius: 50%!important;
    }
    .border-2 {
        --dashui-border-width: 2px;
    }
    .border {
        border: 1px solid #dcdcdc !important;
    }

    .py-6 {
        padding-bottom: 1.5rem!important;
        padding-top: 1.5rem!important;
    }

    .bg-gray-300 {
        --dashui-bg-opacity: 1;
        background-color: #cbd5e1!important;
    }

    .mb-6 {
        margin-bottom: 1.5rem!important;
    }
    .align-items-center {
        align-items: center!important;
    }


    .mb-4 {
        margin-bottom: 1rem!important;
    }

    .mb-8 {
        margin-bottom: 2rem!important;
    }
    .shadow-none {
        box-shadow: none!important;
    }

    .card>.list-group:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-bottom-width: 0;
    }
    .card>.list-group:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-top-width: 0;
    }
    .card>.list-group {
        border-bottom: inherit;
        border-top: inherit;
    }
    .card-body{
        padding: 12px 16px !important;
        background-color: #ccccdf !important;
    }



</style>