<template>
    <div class="inventory">
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container"> 
          <strong style="font-size: 20px !important;">Product Details</strong>
          <h5>{{ product?.name }} 
            <button style="float: right; margin-left: 15px;" class="btn btn-danger"  @click="deleteProductModal()">Delete Product</button>
            <button style="float: right;  color:white; padding: 6px; border-radius: 5px;" class="btn btn-primary" @click="$router.push({name: 'EditProduct', params:{ id: product.id}})">Edit Product</button>
          </h5>
         
          <div>
            <div class="form mt-4">
                <div class="row bg-white mb-4 py-3">
                  <div class="col-md-3">
                    <img v-if="product?.picture" :src="`${urlServer}/files/${product?.picture}`" alt="" style="height: 100%; width: 100%;">
                  </div>

                  <div class="col-md-9">
                    <table class="table table-bordered" style="height: 100%">
                      <tbody>
                        <tr><th class="text-primary">Name</th><td>{{ product?.name }}</td><th class="text-primary">Category</th><td>{{ product?.Category?.category }}</td><th class="text-primary">Purchase Price</th><td>{{ formatMoney(product?.pricePurchase) }}</td></tr>
                        <tr><th class="text-primary">Alt. Name</th><td>{{ product?.alternativeName }}</td><th class="text-primary">Sub-category</th><td></td><th class="text-primary">Retail Price</th><td>{{ formatMoney(product?.priceRetail) }}</td></tr>
                        <tr><th class="text-primary">Item Code</th><td>{{ product?.code }}</td><th class="text-primary">Unit</th><td>{{ product?.unit?.unit }}</td><th class="text-primary">Wholesale Price</th><td>{{ formatMoney(product?.priceWholesale) }}</td></tr>
                        <tr><th class="text-primary">HS Code</th><td>{{ product?.hscode }}</td><th class="text-primary">Sub Unit</th><td>-</td><th class="text-primary">Tax Rate</th><td>{{ product?.tax }}</td></tr>
                        <tr><th class="text-primary">Weight</th><td>{{ product?.weightMeasurement }}</td><th class="text-primary">Storage Dimension</th><td>{{ product?.dimensions }}</td><th class="text-primary">Shelf Life</th><td>{{ product?.shelfLife }}</td></tr>
                        <tr><th class="text-primary">Min Stock</th><td>{{ formatQty(product?.stockMin) }}</td><th class="text-primary">Max Stock</th><td>{{ formatQty(product?.stockMax) }}</td><th class="text-primary">Order Stock</th><td>{{ formatQty(product?.stockOrder) }}</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div v-if="warehouses.length">
                <strong>Warehouse</strong>
                <div class="products-list transaction-products-list mb-4" style="margin-top: 0px;">
                  <table class="table table-hover"  id="deliveryTable" style="margin-top: 0px;">
                    <thead>
                      <th>No.</th>
                      <th>Warehouse</th>
                      <th>Warehouse Code</th>
                      <th>Qty On Hand</th>
                      <th>Qty Reserved</th>
                      <th>Available</th>
                    </thead>
                    
                      <tbody>
                      <tr v-for="(warehouse, index) in warehouses" v-bind:key="warehouse.id">
                        <td>{{ (index+1) }}</td>
                        <td>{{ warehouse.Warehouse.name }}</td>
                        <td>{{ warehouse.Warehouse.code }}</td>
                        <td>-</td>
                        <td>{{ warehouse.quantityReserved }}</td>
                        <td>{{ warehouse.quantity }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
             

              <div v-if="purchases.length">
                <strong>Suppliers & Price Analysis</strong>
                <div class="products-list transaction-products-list" style="margin-top: 0px;">
                  <table class="table table-hover"  id="deliveryTable" style="margin-top: 0px;">
                    <thead>
                      <th>No.</th>
                      <th>Supplier</th>
                      <th>Total Qty Supplied</th>
                      <th>Avg. Price Per Unit</th>
                    </thead>
                    
                      <tbody>
                      <tr v-for="(purchase, index) in purchases" v-bind:key="purchase.id">
                        <td>{{ (index + 1) }}</td>
                        <td>{{ purchase.Supplier.name }}</td>
                        <td> {{ purchase.PurchaseProduct[0].quantity }}</td>
                        <td> {{  this.formatMoney(purchase.PurchaseProduct[0].price/purchase.PurchaseProduct[0].quantity) }} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  import Header from '../../../components/Header'
  import { mapGetters } from 'vuex'
  

  export default {
    name: 'ViewProductPage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        product: "",
        productId: "",
        totalOnhand:0,
        totalReserved:0,
        totalAvailable:0,
        warehouses: [],
        purchases: []
    }),

    computed: {
      ... mapGetters(['getSuppliers']),
      suppliers () { return this.getSuppliers }
    },
   
    mounted(){
        this.productId = this.$route.params.id
        this.setupProduct(this.productId)
        this.locateProduct()
        this.setupPurchases(this.productId)
    },
    methods:{
      async setupProduct(itemId){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/products/'+itemId, requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.product = data.product;
            console.log("Product", data.product)
            this.productId= data.product.id;
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Product not found. Try again',
                text: data.message
            })
        }

      },

      async setupPurchases(itemId){
        const res = await fetch(`${process.env.VUE_APP_URL}/purchases/product/${itemId}`, 
          {
            headers: { 
                  "Content-Type": "application/json", 
                  'Authorization': 'Bearer ' + this.token 
              },
          }
        )
        const data = await res.json()
        this.purchases = data.purchase
        console.log(this.purchases)
     
      },

      async locateProduct () {
        try {
          const requestOptions = {
              method: "POST",
              headers: { 
                  "Content-Type": "application/json", 
                  'Authorization': 'Bearer ' + this.token 
              },
              body: JSON.stringify({ 
                  productId: this.productId
              })
          };

          const res = await fetch(`${process.env.VUE_APP_URL}/inventory/locate`, requestOptions);
          const data = await res.json();

          if (data.status === true) {
              this.displaySearch = "block";
              this.warehouses = data.warehouses;
              this.productName = data.warehouses[0]?.Product?.name;
          } else {
              Swal.fire({
                  icon: 'error',
                  title: 'Location not found',
                  text: data.message
              });
          }
        } catch (error) {
          console.log(error)
        }
      },



      checkNull(category){
        if(category){
          return category;
        } else {
          return " - "
        }
      },
      deleteProductModal(){
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#4448B3',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteProduct()
          }
        })
      },
      async deleteProduct(){
        const requestOptions = {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/products/'+this.productId, requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.product = data.product;
            this.$router.push('/inventory/products')
           // this.productId= data.product.id;
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Product not found. Try again',
                text: data.message
            })
        }
      },
      calculateTotal(value, type){
        if(type == 'onhand'){
          this.totalOnhand = this.totalOnhand + value
        } else if(type == 'reserved'){
          this.totalReserved = this.totalReserved + value
        } else if(type == 'available'){
          this.totalAvailable = this.totalAvailable + value
        }
        return value;
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  
$primary: #d2d2d2;
$green: #4448B3;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
  </style>