<template>
    <div class="home">
        <Header :mainHeader="true"/>
        <section id="settings-categories">
            <div class="container">
                <h4 class="fw-bold title mb-4" style="margin-left: 10px !important; margin-right: 10px !important">System Configurations - Departments Setup</h4>
    
                <div class="panel">
                    <label for="" class="font-bold">1. Departments</label>
                    <div class="row mt-2">
                        <div class="col-md-2">
                            <input type="text"  class="form-control" placeholder="Enter department" v-model="department" >
                        </div>

                        <div class="col-md-2">
                            <input type="number"  class="form-control" placeholder="Levels of Ranks" v-model="ranks" max="2" min="1">
                        </div>

                        <div class="col-md-6">
                            <button class="btn btn-primary btn-small" @click="postDeparment">Add</button>
                        </div>
                        <div class="col-md-2">
                            <button class="btn-small btn btn-default" :style="{ 'display': departmentDisplay == 'block' ? 'none' : 'block' }"  style="border: 1px solid gray; float: right;" @click="departmentDisplay='block', this.fetchDepartments()">View List</button>
                        </div>
                </div>
                
                <div class="row mt-4" :style="{ 'display': departmentDisplay }"   style="margin-left: 3px; margin-right: 3px">
                    <h6 style="padding:0px !important">Departments list <button class="btn btn-sm btn-default pull-right text-primary" @click="departmentDisplay='none'" style="float:right;">Hide List</button></h6>
                    <table class="table table-hover">
                        <thead>
                            <th>No.</th>
                            <th>Department</th>
                            <th>Hierarchies</th>
                            <th>Roles</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            <tr v-for="(department,index) in departments" v-bind:key="department.id">
                                <td>{{ index+1 }}</td>
                                <td>{{ department.name }}</td>
                                <td>{{ department.ranks }}</td>
                                <td>{{ department.permissions.map(permission => permission.name ).join(', ') }}</td>
                                <td><button class="btn-sm btn btn-danger" @click="deleteDepartment(department.id)">Delete</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
    </div>

    </div>



    </section>   
    </div>
 


</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import Header from '../../components/Header';


export default {
    name: 'SettingsCategoryPage',
    components:{
        Header
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        department: "",
        ranks: "",
        departmentDisplay: "none",
    }),
    computed:{
        ... mapGetters (["getDepartments"]),
        departments () { return this.getDepartments },
    },
    methods:{
        ... mapActions(['fetchDepartments']),
        async postDeparment () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    name: this.department,
                    ranks: this.ranks
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/departments', requestOptions)
            console.log(res)
            const data = await res.json()
            if(data.status == true){
                this.departmentDisplay = 'block';
                this.department = "";
                this.fetchDepartments();
                console.log("Department")
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Department not added',
                    text: data.message
                })
            }
        },


        async deleteDepartment(id) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/departments/delete/'+id, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Department deleted successfully',
                    text: 'Department  deleted'
                })
                this.fetchDepartments();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Department not deleted',
                    text: data.message
                })
            }
        },
    },

    watch: {
    ranks(value) {
        if (value > 2) {
        this.ranks = 2; 
        }

        if (value < 1) {
        this.ranks = 1; 
        }

    }
    }


}




</script>


<style lang="scss" scoped>
ul{
    display: block;
    list-style-type: none;
}
ul > li{
    color: #0c275e;
    list-style: none;
    display: block;
    padding: 3px 0px; 
}
ul li:hover{
    color:#00005C;
    cursor: pointer;
    text-decoration: underline;
}

#main-header{
    padding: 10px;
    background-color: #dce2f0;
}
</style>
