<template>
    <div class="home">
        <Header :mainHeader="true"/>
        <CreditModal v-if="isVisible" @close="closeModal">
            <template v-slot:header>
                <h3>Add Payment Details</h3>
            </template>
            
            <template v-slot:body>
                <CreditCard/>
            </template>

            <template v-slot:footer>
                <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Cancel</button>
                <button class="btn-style small" style="border: 1px solid black; float: right;" @click="processBatch">Add</button>
            </template>
        </CreditModal>
        <section id="settings-categories">
            <div class="container ">
                <h4 class="mb-5 fw-bold">Billing</h4>
                <div class="row">
                    <div class="col-lg-4 mb-4">
                        <!-- Billing card 1-->
                        <div class="card h-100 border-start-lg border-start-primary">
                            <div class="card-body">
                                <div class="small text-muted">Current monthly bill</div>
                                <div class="h4 fw-bold text-primary">KES 120,000</div>
                                <a class="text-arrow-icon small" href="#!">
                                    Switch to yearly billing
                                    
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-4">
                        <!-- Billing card 2-->
                        <div class="card h-100 border-start-lg border-start-secondary">
                            <div class="card-body">
                                <div class="small text-muted">Next payment due</div>
                                <div class="h4 fw-bold text-primary">July 15</div>
                                <a class="text-arrow-icon small text-secondary" href="#!">
                                    View payment history
                                    
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-4">
                        <!-- Billing card 3-->
                        <div class="card h-100 border-start-lg border-start-success">
                            <div class="card-body">
                                <div class="small text-muted">Current plan</div>
                                <div class="h5 d-flex align-items-center text-primary fw-bold">Standard</div>
                                <a class="text-arrow-icon small text-success" href="#!">
                                    Upgrade plan
                                    
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Payment methods card-->
                <div class="card card-header-actions mb-4">
                    <div class="card-header d-flex justify-content-between" >
                        Payment Methods
                        <button class="btn btn-sm clear" type="button" @click="openModal">Add Payment Method</button>
                    </div>
                    <div class="card-body px-0">
                       
                        <div v-for="creditCard in creditCards" v-bind:key=creditCard.cardNumber class="d-flex align-items-center justify-content-between px-4">
                            <div class="d-flex align-items-center">
                                 <i class="fab fa-cc-visa fa-2x cc-color-visa"></i>
                                <div class="ms-4">
                                    <div class="small">{{identifyCardType(creditCard.cardNumber)}} ending in {{getLastFour(creditCard.cardNumber)}}</div>
                                    <div class="text-xs text-muted">Expires {{creditCard.expirationDate}}</div>
                                </div>
                            </div>
                            <div class="ms-4 small">
                                <div class="badge bg-light text-dark me-3">Default</div>
                                <a href="#!">Edit</a>
                            </div>
                        </div>
                        
                        
                       
                    </div>
                </div>
                <!-- Billing history card-->
                <div class="card mb-4">
                    <div class="card-header">Billing History</div>
                    <div class="card-body p-0">
                        <!-- Billing history table-->
                        <div class="table-responsive table-billing-history">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th class="border-gray-200" scope="col">Transaction ID</th>
                                        <th class="border-gray-200" scope="col">Date</th>
                                        <th class="border-gray-200" scope="col">Amount</th>
                                        <th class="border-gray-200" scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>#39201</td>
                                        <td>06/15/2024</td>
                                        <td>KES 2999</td>
                                        <td><span class="badge bg-light text-dark">Pending</span></td>
                                    </tr>
                                    <tr>
                                        <td>#38594</td>
                                        <td>05/15/2024</td>
                                        <td>KES 2999</td>
                                        <td><span class="badge bg-success">Paid</span></td>
                                    </tr>
                                    <tr>
                                        <td>#38223</td>
                                        <td>04/15/2024</td>
                                        <td>KES 2999</td>
                                        <td><span class="badge bg-success">Paid</span></td>
                                    </tr>
                                    <tr>
                                        <td>#38125</td>
                                        <td>03/15/2024</td>
                                        <td>KES 2999</td>
                                        <td><span class="badge bg-success">Paid</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                


            </div>
        </section> 
    </div>
</template>

<script>
import Header from '../../components/Header';
import CreditCard from '../../components/CreditCard.vue'
import axios from 'axios'
import CreditModal from '../../components/CreditModal.vue'


export default {
    name: 'SettingsCategoryPage',
    components:{
        Header,
        CreditCard, 
        CreditModal
    },

    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        profile: {},
        isVisible:false,
        creditCards: []
    }),

    mounted(){
        this.getCreditCards()
    },

    methods:{
        async getCreditCards () {
          try {
            const response = await axios.get(`${process.env.VUE_APP_URL}/creditcards`,{
              headers: {
                'Authorization': 'Bearer ' + this.token
              }
            });

            if (response.status === 200) {
              const data = response.data;
              //console.log("Data",data)
              if (data.status === true) {
                this.creditCards = response.data.creditCards
                console.log("Credit cards", this.creditCards)
              }
            } 
          }catch (error) {
            console.error('Error creating profile:', error);
        }
      },

      closeModal() {
            this.isVisible = false
      },

      openModal(){
            this.isVisible = true
      },

      getLastFour(str) {
            if (str.length <= 4) {
                return str; 
            }
            return str.slice(-4); 
      },

      identifyCardType(cardNumber) {
        const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?(?:[0-9]{3})?$/;
        const masterCardRegex = /^5[1-5][0-9]{14}$|^2(22[1-9]|2[3-9][0-9]|[3-6][0-9]{2}|7([01][0-9]|20))[0-9]{12}$/;

        if (visaRegex.test(cardNumber)) {
            return 'Visa';
        } else if (masterCardRegex.test(cardNumber)) {
            return 'MasterCard';
        } else {
            return 'Unknown';
    }
}




    },

}

</script>


<style scoped>
.img-account-profile {
    height: 10rem;
}
.rounded-circle {
    border-radius: 50% !important;
}
.card {
    box-shadow: 0 0.005rem 0.05rem 0 rgb(33 40 50 / 15%);
}
.card .card-header {
    font-weight: 500;
}
.card-header:first-child {
    border-radius: 0.35rem 0.35rem 0 0;
}
.card-header {
    padding: 1rem 1.35rem;
    margin-bottom: 0;
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}
.form-control, .dataTable-input {
    display: block;
    width: 100%;
    padding: 0.875rem 1.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #69707a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5ccd6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
    color: #0061f2;
    border-bottom-color: #0061f2;
}
.nav-borders .nav-link {
    color: #69707a;
    border-bottom-width: 0.125rem;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}
.fa-2x {
    font-size: 2em;
}

.table-billing-history th, .table-billing-history td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
}
.table > :not(caption) > * > *, .dataTable-table > :not(caption) > * > * {
    padding: 0.75rem 0.75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.border-start-primary {
    border-left-color: #0061f2 !important;
}
.border-start-secondary {
    border-left-color: #6900c7 !important;
}
.border-start-success {
    border-left-color: #00ac69 !important;
}
.border-start-lg {
    border-left-width: 0.25rem !important;
}
.h-100 {
    height: 100% !important;
}



</style>