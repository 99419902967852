<template>
    <div class="container">

    </div>
</template>

<script>
import { defineComponent, onMounted,  h} from 'vue'
import { Bar } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  // Plugin
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default defineComponent({
  name: 'BarChart',
  components: {
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 320
    },
    xValues:Array,
    yValues:Array,
    cssClasses: {
      default: '',
      type: String
    },
  },

  /*
  data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      activities: [],
  }),*/


  setup(props) {
    
    // Fetch activities and categories on component mount
    onMounted(() => {
     // console.log(props.xValues)
     // console.log(props.yValues)
    });
    

    const chartData = {
      labels: props.xValues,
      datasets: [
        {
          label: 'Products',
          backgroundColor: '#B0C4DE',
          data: props.yValues
        }
      ]
    }

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0
        }
      },
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#92959E'
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            color: '#92959E'
          },
          grid: {
            display: false
          }
        },
      },
    }

   
    return () =>
        h(Bar, {
          chartData,
          chartOptions,
          chartId: props.chartId,
          width: props.width,
          height: props.height,
          cssClasses: props.cssClasses,
          styles: props.styles,
          plugins: props.plugins
        })
  },

/*
  mounted(){
    this.getActivities()
    this.fetchCategories()  
    this.initializeCategories()  
  },


  computed:{
      ... mapGetters (["getCategories"]),
      categories () { return this.getCategories },
  },



  initializeCategories(){
    for (const obj of this.categories) {
      const nameValue = obj.category
      this.xValues.push(nameValue)
    }
  },

  methods:{
    ...mapActions(["fetchCategories"]),
    async getActivities () {
          const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/inventory/activity', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.activities = data.activities
              console.log(this.activities)
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Stock Item List not found',
                  text: data.message
              })
          }
      },
  }*/
  
})


</script>
