<template> 
    <Header :mainHeader="true" />
    <div class="page-wrap d-flex flex-row align-items-center ">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                    <h4 class="display-8 d-block text-primary fw-bold mb-3">COMMING SOON</h4>
                    <div class="mb-4 lead text-sm" style="font-size: 15px">The functionality is not available, you will be notified when the updates are made</div>
                    <span @click="$router.back()" class="btn-style" style="cursor: pointer !important;"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path></svg> Go Back</span>
                </div>
            </div>
        </div>
    </div>

</template>

<script> 

import Header from '../../components/Header.vue'

export default {
  name: 'CommingSoon',
  components: {
    Header
  }
}
</script>

<style scoped>
.page-wrap{
    background: #f2f2f2;
    min-height: calc(100vh - 150px) !important;
}
</style>