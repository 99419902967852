<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h4 class="mb-4 fw-bold text-primary">Generate Incomplete Delivery Note</h4>
          <div>
            <div class="form">
              <div class="row">
                <strong>1. Batch Processing</strong>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="address">Select BPN</label>
                    <select v-model="batchId" class="full" id="batchess"  v-if="batches?.length > 0">
                        <option v-for="batch in batches" v-bind:Key="batch.id" :value="batch.id">
                            {{ batch.batchNo }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Batch Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchBatches">Fetch Batches</button>
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                    <button class="btn-style small" style="margin-top: 31px" @click="postNote">Add</button>
                </div>
  
              </div>
  
            </div>
          </div>
          <div :style="{ 'display': displayItems }" style="margin-top:30px;">
            <strong>2. Add Missing Items/Quantities</strong>
            <table class="table table-hover">
              <thead>
                <tr>
                    <th>Product Description</th>
                    <th>Unit</th>
                    <th>Quantity</th>
                    <th>Comments</th>
                    <th></th>
                </tr>
                <tr style="background: #fff !important;">
                    <td>
                      <select v-model="productId" class="full form-control" id="products"  v-if="products?.length > 0">
                          <option v-for="product in products" v-bind:Key="product.id" :value="product.id">
                              {{ product.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No Product Found.</h5> 
                          <button class="btn btn-primary btn-sm" @click="this.fetchProducts">Fetch Products</button>
                      </div>
                    </td>
                    <td><input class="form-control" type="text"  readonly v-model="productUnit" /></td>
                    <td><input class="form-control" type="number" min="0" v-model="quantity"/></td>
                    <td><input class="form-control" type="text" v-model="comment"/></td>
                    <td><button class="btn-style small" style="margin-top: 0px" @click="postItem">Add</button></td>
                </tr>
              </thead>
              </table>

            <strong class="mt-4">3. Item List</strong>
            <table class="table table-hover">
                <thead style="background-color: #f2f2f2;">
                    <th>No. </th>  
                    <th>Product Description</th>
                    <th>Unit</th>
                    <th>Qty</th>
                    <th>Comments</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="items?.length > 0">
                    <tr v-for="(item, index) in items" v-bind:key="item.id">
                        <td>{{ (index+1) }}</td>
                        <td>{{ item.Product.name }}</td>
                        <td>{{ item.Product.unit.unit }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.comment }}</td>
                        <td>
                            <button class="btn btn-default btn-sm" style="border:1px solid black;" @click="removeItem(item.id)">Remove</button>
                        </td>
                    </tr>
                </tbody>
                <div v-else>
                  <label for="" class="alert alert-danger" style="margin: 10px auto;  display: block;">No items found</label>
                </div>
            </table>

            <strong>3. Add IDN Notes</strong>
            <input type="text" v-model="idnNote" class="form-control">
            <div style="margin-top: 15px; margin-bottom: 30px;">
              <button class="btn-style small" style="border: 1px solid black; float: right;" @click="completeNote">Complete</button>
              <button class="btn btn-danger" style="border: 1px solid black; float: right;margin-right: 10px;" @click="cancelNoteAlert()">Cancel</button>
              <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="$router.push('/transaction/incompletenotes')">Close</button>
            </div>
  
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>

  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../../components/Header';
  
  export default {
    name: 'AddIncompleteDelivery',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        displayItems: "none",
        batchId:[],
        batches:[],
        items:[],
        idnNoteId:"",
        idnNote:"",
        productId: "",
        quantity:"",
        comment: "",
    }),
    computed:{
        ... mapGetters (["getProducts"]),
        products () { return this.getProducts },
    },
    mounted(){
        this.fetchProducts()
        this.setupBatches()
    },
    methods:{
        ... mapActions(['fetchProducts']),
        async setupBatches () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/batchno', requestOptions)
            const data = await res.json()
            if(data.status == true){
              this.batches = data.batches 
            }
        },
        async completeNote () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                  note:this.idnNote,
                  noteId:this.idnNoteId,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/incompletenotes/process', requestOptions)
            const data = await res.json()
            if(data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Incomplete Delivery Note processed successfully',
                  text: data.message
              })
              this.$router.push('/transaction/incompletenotes')   
            }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Incomplete Delivery Note not processed',
                  text: data.message
              })
            }
        },
        async postItem () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                  quantity:this.quantity,
                  comment:this.comment,
                  noteId:this.idnNoteId,
                  productId:this.productId
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/incompletenotes/items', requestOptions)
            const data = await res.json()
            if(data.status == true){
              this.items = data.items;    
              this.quantity = "";
              this.comment = "";
            }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Item not created',
                  text: data.message
              })
            }
        },
        async removeItem (itemId) {
            const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/incompletenotes/items/'+itemId+"/"+this.idnNoteId, requestOptions)
            const data = await res.json()
            if(data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Item removed',
                  text: data.message
              })
              this.items = data.items;     
            }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Item not created',
                  text: data.message
              })
            }
        },
        async postNote () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                  batchId: this.batchId,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/incompletenotes', requestOptions)
            const data = await res.json()
            if(data.status == true){
              this.idnNoteId = data.note.id
              this.displayItems = "block"        
            }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Incomplete Delivery Note not created',
                  text: data.message
              })
            }
        },
        cancelNoteAlert(){
            Swal.fire({
              title: 'Confirm Incomplete Delivery Note Cancellation',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, cancel it!'
            }).then((result) => {
              if (result.isConfirmed) {
                this.cancelNote();
              }
            })
        },
        async cancelNote () {
            const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/incompletenotes/'+this.idnNoteId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                  position: 'top-center',
                  icon: 'success',
                  title: 'Incomplete Delivery Note Cancelled',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.$router.push('/transaction/incompletenotes')          
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Incomplete Delivery Note Cancellation Failed',
                    text: data.message
                })
            }
        },
      },
      watch:{
        productId(newValue){
            let productObj = this.products.find(i => i.id === newValue);
            this.productUnit = productObj.unit.unit
        },
      }
  }
  </script>