<template>
  <div class="transaction" :key="componentKey">
    <Header :mainHeader="true" />

    <section id="transaction-products">
      <div class="container">

        <h4 class="text-primary fw-bold">View Products</h4>

        <div class="filter-search"  style="margin-top: 0px;padding-left: 0px;">
          <div class="filter-search-header">
            <strong>Filter</strong>
            <p class="clear" @click="clear">Clear</p>
          </div>
          <div class="filter-search-body">
            <div class="form">
              <div class="row">
                <div class="col-md-3 align-self-end">
                  <div class="form-group">
                    <label for="date">Product Name / Description</label>
                    <input type="text" class="form-control" v-model="name" @input="filterTable('name')">
                  </div>
                </div>

                <div class="col-md-2 align-self-end">
                  <div class="form-group">
                    <label for="date">Item Code</label>
                    <input type="text" class="form-control" v-model="code" @input="filterTable('code')">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="product-category">Product Category</label>
                    <select v-model="categoryId" class="full" id="product-category" v-if="categories.length > 0"
                      @change="filterTable('category')">
                      <option v-for="category in categories" v-bind:Key="category.id" :value="category.category">
                        {{ category.category }}
                      </option>
                    </select>
                    <div v-else>
                      <h5 for="" style="color: red !important;">No Product Category.</h5>
                      <button class="btn btn-primary btn-sm" @click="this.fetchCategories">Fetch Product
                        Categories</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="product-sub-category">Product Sub-cat.</label>
                    <select v-model="subCategoryId" class="full" id="product-sub-category">
                      <option value="0" v-if="subcategories?.length == 0">None</option>
                      <option v-for="subcategory in subcategories" v-bind:Key="subcategory.id" :value="subcategory.id">
                        {{ subcategory.category }}
                      </option>
                    </select>
                  </div>
                </div>
                <!--
                   <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <a href="#" class="btn-style" @click="filterProducts(name)">Filter</a>
                    </div>
                  </div>
                -->
              </div>
            </div>
          </div>
        </div>

        <div>
          <strong>Products List</strong>
          <!-- <keep-alive> -->
          <table class="table table-hover" id="productsTable" style="width: 100% !important">
            <thead>
              <th>No.</th>
              <th>Product Name</th>
              <th>Alternative Name</th>
              <th>Item Code</th>
              <th>Category</th>
              <th>Unit</th>
              <th>Value (KES)</th>
              <th>Min Stock</th>
              <th>Max Stock</th>
              <th>Order Stock</th>
              <th>Action</th>
            </thead>
          </table>

          <LoaderItem v-if="loading"/>
          <!-- </keep-alive> 
          <h4 v-if="products.length < 1" style="color:red; text-align: center; margin-top: 30px;">No product found</h4>
          -->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { docFooter } from '../../../utils/common'
import { mapGetters, mapActions } from 'vuex'
import LoaderItem from '../../../components/LoaderItem'
import Header from '../../../components/Header';
import 'datatables.net-vue3';
import 'datatables.net-bs5';
import "datatables.net-buttons-bs5";
import "datatables.net-responsive-bs5";
import $ from 'jquery';
import 'jszip';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.print.js';

export default {
  name: 'ProductsPage',
  components: {
    Header,
    LoaderItem
  },
  data: () => ({
    token: JSON.parse(localStorage.getItem('access_token')),
    name: "",
    code: "",
    categoryId: 0,
    subCategoryId: 0,
    products: [],
    filteredProducts: [],
    dataTable: null,
    processedData: [],
    componentKey: 0,
    loading: false,
    productDatas: []
  }),
  computed: {
    ...mapGetters(["getCategories"]),
    categories() { return this.getCategories },
  },
  mounted() {
    this.fetchCategories()
    this.getProducts()
  },
  methods: {
    ...mapActions(['fetchCategories']),
    async getProducts() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + this.token },
      };
      try{
        this.loading = true
        const res = await fetch(process.env.VUE_APP_URL + '/products', requestOptions)
        this.products = await res.json()
        this.productDatas = this.products
        this.filteredProducts = this.products
        this.processedData = this.preprocessData(this.products);
        this.initializeDataTable();
      }catch(err){
        console.log(err)
      }finally{
        this.loading = false
      }
    },
    isSubCategory(element) {
      return element.id == this.categoryId;
    },
    checkNull(category) {
      if (category) {
        return category;
      } else {
        return " - "
      }
    },

    filterTable(arg) {
      switch (arg) {
        case 'name':
          this.dataTable.search(this.name).draw()
          break
        case 'code':
          this.dataTable.search(this.code).draw()
          break
        case 'category':
          this.dataTable.search(this.categoryId).draw()
          break
        default:
          this.dataTable.search('').draw()
      }
    },

    reinitializeTable(){
      this.dataTable.clear()
      this.dataTable.rows.add(this.preprocessData(this.productDatas))
      this.dataTable.draw()
    },

    clear(){
      this.name = ""
      this.code = ""
      this.category = ""
      this.categoryId = 0
      this.subCategoryId = 0
      this.dataTable.search('').draw()
      this.reinitializeTable()
    },

    preprocessData(rawData) {
      return rawData.map(item => {
        return {
          indexNo: "1",
          createdAt: this.formatDate(item.createdAt),
          productName: item.name,
          productCode: item.code,
          productAlt: this.checkNull(item.alternativeName),
          category: this.checkNull(item.Category?.category),
          unit: this.checkNull(item.unit?.unit),
          value: this.formatMoney(item.pricePurchase),
          stockMin: this.formatQty(item.stockMin),
          stockMax: this.formatQty(item.stockMax),
          stockOrder: this.formatQty(item.stockOrder),
          action: `<a href="/inventory/products/${item.id}" class="btn btn-sm btn-default" style="border: 1px solid black; margin-right:5px">View</a>`
        };
      });
    },

 




    initializeDataTable() {
      try {
        this.dataTable = $(this.$el).find('#productsTable').DataTable({
          responsive: true,
          language: {
            paginate: {
              previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
              next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
            },
          },
          dom: 'Bfrtip',
          buttons: [
            'colvis',
            'pageLength',
            {
              extend: 'csv',
              text: 'Export CSV',
              className: '',
              exportOptions: {
                columns: ':visible',
              },
            },
            {
              extend: 'pdfHtml5',
              text: 'Export PDF',
              orientation: 'landscape',
              exportOptions: {
                columns: ':visible',
              },
              customize: this.customizePDF
            },
          ],
          data: this.processedData,
          columns: [
            { data: null },
            { data: 'productName' },
            { data: 'productAlt' },
            { data: 'productCode' },
            { data: 'category' },
            { data: 'unit' },
            { data: 'value' },
            { data: 'stockMin' },
            { data: 'stockMax' },
            { data: 'stockOrder' },
            { data: 'action' }
          ],
          columnDefs: [
            {
              targets: 0,
              searchable: false,
              orderable: false,
              render: (data, type, row, meta) => {
                return meta.row + 1;
              },
            },
          ],
        });
      } catch (error) {
        console.log({ error })
      }
    },


    convertToBase64(imageUrl) {
      fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64Image = reader.result;
          };
          reader.readAsDataURL(blob);
        });
    },


    customizePDF(doc) {
      const profile = JSON.parse(localStorage.getItem('profile'))
      //const logoBase64 = this.convertToBase64(logo)
      //console.log(logoBase64)
      doc.pageMargins = [40, 40, 40, 40]; // Set margins for the entire page
      doc.defaultStyle.columnGap = 0
      doc.content[1].fillColor = '#f2f2f2'

      doc.header = () => {
        return {
          columns: [
            {
              width: '*',
              columns: [
                /* {
                     image: logoBase64,
                     width: 150, // Adjust the width as needed
                 },*/
                {
                  text: profile?.businessName,
                  alignment: 'left',
                  margin: [40, 15, 0, 40], // Adjust margins as needed
                  color: '#000000', // Orange color
                  bold: true,
                  fontSize: 14,

                }
              ]
            },
            {
              width: '*',
              stack: [
                {
                  text: 'Products List',
                  alignment: 'right',
                  margin: [0, 15, 50, 0],
                  fontSize: 12,
                  color: '#4448B3'
                },
                {  
                  alignment: 'right',
                  margin: [300, 0, 0, 0],
                  width: '*',
                  columns: [
                    {
                      width: 'auto',
                      text: 'Date:',
                      alignment: 'right',
                      margin: [0, 0, 10, 0],
                      bold: true,
                    },
                    {
                      width: 'auto',
                      text: `${this.formatDate(new Date(), "true")}`,
                      alignment: 'right',
                      margin: [0, 0, 50, 0],
                      fontSize: 9
                    }
                  ]
                }
              ]
            }
          ],
          fillColor: '#000000'
        };
      },

        doc.footer = (currentPage, pageCount) => docFooter(currentPage, pageCount)

      doc.styles.title = {
        color: '#4448B3',
        fontSize: 13,
        margin: [80, 0, 0, 40],
      };
    }
  }

  /*
  watch: {
      categoryId() {
      this.subcategories = []
      const category = this.categories.filter(this.isSubCategory);
      this.subcategories = category[0].subcategories;
      }
  }*/
}
</script>

<style scoped>
.suppliers-list {
  padding: 20px;
  margin-top: 30px;
  box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
}
</style>