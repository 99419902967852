<template>
  <div class="inventory">

    <Header :mainHeader="true"/>

    <section id="suppliers-add">
      <div class="container">
        <h4 class="text-primary fw-bold ">Add Stock/Batch Processing
          <button v-if="batchId" class="btn-default btn btn-sm pull-right" style="margin-top: 0px;float:right; border: 1px solid #000;" @click="splitBatchModal()">Split Receipt</button>
          <button v-if="purchase" class="btn-default btn btn-sm pull-right" style="margin-top: 0px;float:right; border: 1px solid #000;margin-right: 15px;"  @click="this.$router.push('/purchases/pdf/'+purchase.id)">View PO</button>
        </h4>
        <div class="row mt-4">
          <strong class="col-md-2 text-md" >1. Delivery Details</strong>
          <strong class="col-md-10 text-md" v-if="!isInternal">2. Courier & Supplier Details</strong>
        </div>
        <div>
          <div class="form">
            <div class="row">
              <div class="col-md-2">
                  <div class="check-button">
                  <div class="form-group">
                      <label>Internal Delivery?</label>
                      <div class="components-button">
                  <span class="switcher switcher-1">
                      <input type="checkbox" id="switcher-1" v-model="isInternal">
                      <label for="switcher-1"></label>
                  </span>
                      </div>
                  </div>
                  </div>
              </div>
              <div class="col-md-2" v-if="isInternal">
                <div class="form-group">
                  <label for="driver">Select Driver</label>
                  <select v-model="driverId" class="full" id="driver"  v-if="users.length > 0">
                      <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                          {{ user.name }}
                      </option>
                  </select>
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Users Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchUsers">Fetch Users</button>
                  </div>
                </div>
              </div>
              <div class="col-md-2" v-if="!isInternal">
                <div class="form-group">
                  <label for="suppliers">Select Supplier</label>
                  <select v-model="supplierId" class="full" id="suppliers"  v-if="suppliers.length > 0">
                      <option v-for="supplier in suppliers" v-bind:Key="supplier.id" :value="supplier.id">
                          {{ supplier.name }}
                      </option>
                  </select>
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Supplier Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchSuppliers">Fetch Suppliers</button>
                  </div>
                </div>
              </div>
              <div class="col-md-2" v-if="!isInternal">
                <div class="form-group">
                  <label for="address">Driver's Name</label>
                  <input type="text" v-model="driver" id="address" class="form-control">
                </div>
              </div>
              <div class="col-md-2" v-if="!isInternal">
                <div class="form-group">
                  <label for="item-code">Contacts</label>
                  <input type="text" class="form-control" id="item-code" v-model="contacts">
                </div>
              </div>
              <div class="col-md-2" v-if="!isInternal">
                <div class="form-group">
                  <label for="item-code">Vehicle Reg No.</label>
                  <input type="text" class="form-control" id="item-code" v-model="vehicle">
                </div>
              </div>
            </div>

            <div class="row">
              <strong class="col-md-5 text-md">2. Stock Description</strong>
              <strong class="col-md-7 text-md">3. Consignment Details & Documents</strong>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Stock Category</label>
                  <select v-model="nogId" class="full" id="stockCategories"  v-if="nogs.length > 0">
                      <option v-for="nog in nogs" v-bind:Key="nog.id" :value="nog.id">
                          {{ nog.nog }}
                      </option>
                  </select>
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Stock Category Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchNogs">Fetch Nogs</button>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="item-code">BPN Tags</label>
                  <select v-model="tagId" class="full" id="suppliers"  v-if="tags.length > 0">
                      <option v-for="tag in tags" v-bind:Key="tag.id" :value="tag.id">
                          {{ tag.tag }}
                      </option>
                  </select>
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Tag Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchTags">Fetch Tags</button>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Project / Site</label>
                  <!--<input type="text" class="form-control" id="item-code" v-model="project">-->
                  <select v-model="project" class="full" id="projects"  v-if="projects?.length > 0">
                    <option v-for="proj in projects" v-bind:Key="proj.id" :value="proj.name">
                        {{ proj.name }}
                    </option>
                  </select>
                  <div v-else>
                    <h5 for="" style="color: red !important;">No Projects Found.</h5> 
                    <button class="btn btn-primary btn-sm" @click="this.fetchProjects">Fetch Projects</button>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="item-code">PO No.</label>
                  <select v-model="purchaseId" class="full" id="stockCategories">
                    <option value="null">None</option>
                    <option v-for="purchaseno in purchasenos" v-bind:Key="purchaseno.id" :value="purchaseno.id"> {{ purchaseno.purchaseNo }} </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Select Document</label>
                  <select v-model="document" id="" class="full">
                      <option value="Delivery Note">Delivery Note</option>
                      <option value="Invoice">Invoice</option>
                      <option value="Goods Received Receipt">Goods Received Receipt</option>
                      <option value="Written Note">Written Note</option>
                      <option value="Good Issue">Good Issue</option>
                      <option value="PO Document">PO Document</option>
                  </select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="item-code">Reference 1</label>
                  <input type="text" class="form-control" id="item-code" v-model="reference1">
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="item-code">Reference 2</label>
                  <input type="text" class="form-control" id="item-code" v-model="reference2">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="item-code">Upload Document</label>
                  <input type="file" class="form-control" id="item-code" ref="fileDocument" @change="handleFileChange">
                </div>
              </div>

              <div class="col-md-2">
                <button v-if="batchId == ''" class="btn-style-2" style="margin-top: 12px" @click="postBatch">Add Batch</button>
              </div>
            
            </div>

          </div>
        </div>
        <div v-if="purchase"  style="margin-top:30px;">
          <strong>3. Purchase Order Items</strong>
            <table class="table table-hover">
              <thead>
                  <th>No.</th>
                  <th>Product Description</th>
                  <th>Unit</th>
                  <th>Qty</th>
                  <th>Qty Received</th>
                  <th>Price</th>
                  <th>Condition</th>
                  <th>Comments</th>
                  <th>Action</th>
              </thead>
              <tbody v-if="purchase.PurchaseProduct?.length > 0">
                  <tr v-for="(item, index) in purchase?.PurchaseProduct" v-bind:key="item.id">
                      <td>{{ (index+1) }}</td>
                      <td>{{ item.Product?.name }}</td>
                      <td>{{ item.Product?.unit?.unit }}</td>
                      <td>{{ item?.quantity }}</td>
                      <td><input class="form-control" type="number" min="0" :id="`quantity${item.id}`" /></td>
                      <td><input class="form-control" type="text" :id="`price${item.id}`"/></td>
                      <td>
                          <select :id="`condition${item.id}`" class="full">
                              <option value="New">New</option>
                              <option value="Used">Used</option>
                          </select>
                      </td>
                      <td><input class="form-control" type="text" :id="`comment${item.id}`"/></td>
                      <td>
                          <button class="btn btn-primary btn-sm mt-5" style="border:1px solid black;" :id="`button${item.id}`" @click="completeItem(item.id, item.Product?.id)">Complete</button>
                      </td>
                  </tr>
              </tbody>
              <!--
              <div v-else>
                <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No items found</label>
              </div>
              -->
              
          </table>
          
          <div>
            <button class="btn-style small" style="border: 1px solid black; float: right;" @click="isVisible =true">Process</button>
            <!-- <button class="btn btn-success" style="float: right; margin-right: 10px;" @click="this.$router.push('/transaction')">Save</button>-->
            <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="cancelBatchAlert">Cancel</button>
          </div>
        </div>

        <div v-if="!purchase" :style="{ 'display': displayItems }" style="margin-top:30px;">
          <strong>3. Add Items</strong>
          <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Product Description</label>
                  <Select2 class="select2" v-if="products.length > 0" style="width: 100% !important;" v-model="productId" :options="productOptions" :settings="{ width: 'resolve', selectionCssClass: ':all:' }" />
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Product Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchProducts">Fetch Products</button>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Unit</label>
                  <input class="form-control"  type="text" v-model="itemUnit" />
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Quantity</label>
                  <input class="form-control" type="number" min="0" v-model="quantity" />
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Price</label>
                  <input class="form-control" type="text" v-model="price"/>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Condition</label>
                  <select v-model="condition"  class="full" >
                    <option value="New">New</option>
                    <option value="Used">Used</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Comments</label>
                  <input class="form-control" type="text" v-model="comment"/>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Warehouse</label>
                  <select v-model="warehouseId" class="full" id="warehouses"  v-if="warehouses.length > 0">
                      <option v-for="warehouse in warehouses" v-bind:Key="warehouse.id" :value="warehouse.id">
                          {{ warehouse.name }} <span v-if="warehouse.isVirtual == true"> - {{ warehouse.description }}</span>
                      </option>
                  </select>
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Warehouse Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchWarehouses">Fetch Warehouses</button>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Section</label>                  
                  <select v-model="sectionId" class="full" id="sections">
                    <option v-for="section in sections" v-bind:Key="section.id" :value="section.id">
                        {{ section.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <button class="btn-style small" style="margin-top: 25px" @click="postItem">Add</button>
                </div>
              </div>

          </div>
            <table class="table table-hover">
              <thead>
                  <th>No.</th>
                  <th>Product Description</th>
                  <th>Unit</th>
                  <th>Qty</th>
                  <th>Price (Kshs)</th>
                  <th>Condition</th>
                  <th>Location</th>
                  <th>Comments</th>
                  <th>Action</th>
              </thead>
              <tbody v-if="items.length > 0">
                  <tr v-for="(item, index) in items" v-bind:key="item.id">
                      <td>{{ (index+1) }}</td>
                      <td>{{ item.Product.name }}</td>
                      <td>{{ item.Product?.unit?.unit }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ formatMoney(item.price) }}</td>
                      <td>{{ item.condition }}</td>
                      <td>{{ item.Warehouse?.name }}</td>
                      <td>{{ item.comment }}</td>
                      <td>
                          <button class="btn btn-default btn-sm" style="border:1px solid black;" @click="removeItem(item.id)">Remove</button>
                      </td>
                  </tr>
              </tbody>
              <div v-else>
                <label for="" class="text-danger mt-2" style="margin: 0px; width: fit-content; display: block;">No items found</label>
              </div>
          </table>

          <div>
            <button class="btn-style small" style="margin-bottom: 20px; border: 1px solid black; float: right;" @click="isVisible =true">Process</button>
            <!--<button class="btn btn-success" style="margin-bottom: 20px; float: right; margin-right: 10px;" @click="this.$router.push('/transaction')">Save</button>-->
            <button class="btn btn-default" style="margin-bottom: 20px; border: 1px solid black; float: right; margin-right: 10px;" @click="cancelBatchAlert">Cancel</button>
          </div>
        </div>
      </div>
    </section>

<Modal v-if="isVisible" @close="closeModal" >
  <template v-slot:header>
    <h3>Confirm Stock Entry</h3>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="col-md-12">
          <div class="check-button">
          <div class="form-group">
              <label>Entered stock has been physically verified by you?</label>
              <div class="components-button">
          <span class="switcher switcher-1">
              <input type="checkbox" id="switcher-1" v-model="isVerified">
              <label for="switcher-1"></label>
          </span>
              </div>
          </div>
          </div>
      </div>
      <div class="row">
        <label for="item-code">Assigned stock to guardian?</label>
        <div class="col-sm-4">
            <div class="check-button">
            <div class="form-group">
                <div class="components-button">
            <span class="switcher switcher-1">
                <input type="checkbox" id="switcher-1" v-model="isGuardian">
                <label for="switcher-1"></label>
            </span>
                </div>
            </div>
            </div>
        </div>
        <div class="col-sm-8 mt-1" :style="{ 'display': displayGuardian }">
          <select v-model="guardianId" class="col-7 full" id="guardians"  v-if="users.length > 0">
              <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                  {{ user.name }}
              </option>
          </select>
          <div v-else>
              <h5 for="" style="color: red !important;">No Guardian Found.</h5> 
              <button class="btn btn-primary btn-sm" @click="this.fetchUsers">Fetch Users</button>
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="item-code">BPN Note</label>
        <input type="text" class="form-control" placeholder="Enter note regarding the stock batch" v-model="note">
      </div>
    </div>
  </template>

  <template v-slot:footer>
  <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Cancel</button>
  <button class="btn-style small" style="border: 1px solid black; float: right;" @click="processBatch">Process</button>
  </template>
</Modal>

<Modal v-if="isVisiblePO" @close="closeModalPO" >
  <template v-slot:header>
    <h3>Select warehouse and section</h3> 
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="col-md-12">
          <div class="form-group">
            <label for="supplierContact">Warehouse</label>     
            <select class="full" v-model="poWarehouseId"  v-if="warehouses.length > 0">
                <option v-for="warehouse in warehouses" v-bind:Key="warehouse.id" :value="warehouse.id">{{ warehouse.name }} <span v-if="warehouse.isVirtual == true"> - {{ warehouse.description }}</span></option>
            </select>
            <div v-else>
                <h5 for="" style="color: red !important;">No Warehouse Found.</h5> 
                <button class="btn btn-primary btn-sm" @click="this.fetchWarehouses">Fetch Warehouses</button>
            </div>
          </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="supplierContact">Section</label>                  
          <select v-model="poSectionId" class="full" id="sections">
            <option v-for="section in sections" v-bind:Key="section.id" :value="section.id">
                {{ section.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </template>

  <template v-slot:footer>
  <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisiblePO =false">Cancel</button>
  <button class="btn-style small" style="border: 1px solid black; float: right;" @click="postCompleteItem">Complete Item</button>
  </template>
</Modal>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import Header from '../../../components/Header';
import Modal from '../../../components/Modal.vue';
import axios from 'axios'
import { ref } from 'vue';
import Select2 from 'vue3-select2-component';

export default {
  name: 'AddBatchPage',
  components: {
    Header,
    Modal,
    Select2,
  },
  data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      items: [],
      sections: [],
      products: [],
      productOptions:[],
      displayItems: "none",
      displayGuardian: "block",
      DriverDisplay: "block",
      SupplierDisplay: "block",
      purchase:"",
      batchId: "",
      isVisible:false,
      isVisiblePO: false,
      dynamicValue : ref(0),
      isInternal: true,
      stockCategory: "",
      driverId: "",
      driver: "",
      contacts: "",
      vehicle: "",
      project: "",
      document: "",
      reference1: "",
      reference2: "",
      referenceDocument: "",
      nogId: "",
      tagId: 0,
      warehouseId: "",
      sectionId: "",
      supplierId: null,
      purchaseId: null,
      productId: "",
      quantity: "",
      price: "",
      condition: "",
      comment: "",
      batchNo: "",
      itemUnit: "",
      itemSubUnit: "",
      itemNog: "",
      isVerified: true,
      isGuardian: true,
      guardianId: "",
      note: "",
      poSectionId:"",
      poWarehouseId:"",
      poQuantity:"",
      poPrice:"",
      poCondition:"",
      poComment:"",
      poProductId:"",
      poItemId:"",
  }),
  computed:{
      ... mapGetters (["getSuppliers", "getUsers", "getNogs", "getPurchases", "getPurchasenos", "getWarehouses", "getTags", "getProjects"]),
      suppliers () { return this.getSuppliers },
      users () { return this.getUsers },
      nogs () { return this.getNogs },
      purchases () { return this.getPurchases },
      purchasenos () { return this.getPurchasenos },
      warehouses () { return this.getWarehouses },
      tags () { return this.getTags },
      projects () { return this.getProjects}
  },
  mounted(){
      this.fetchSuppliers(),
      this.fetchUsers(),
      this.fetchPurchases(),
      this.fetchPurchasenos(),
      this.fetchWarehouses(),
      this.fetchTags(),
      this.fetchNogs(),
      this.setupProducts()
      this.fetchProjects()
  },
  methods:{
      ... mapActions(['fetchSuppliers','fetchUsers', 'fetchNogs', 'fetchPurchases','fetchPurchasenos','fetchWarehouses','fetchTags', 'fetchProjects']),
      async setupProducts(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/products', requestOptions)
        this.products = await res.json()
        this.productOptions = [];
        this.productOptions.push({ id: 0, text: "All"})
        this.products.forEach(product => {
          this.productOptions.push({ id: product.id, text: product.name})
        });
      },
      closeModal() {
        this.isVisible = false;
      },
      closeModalPO() {
        this.isVisiblePO = false;
      },
      async processBatch () {
          if(this.batchId == "") {
            Swal.fire({
                icon: 'error',
                title: 'Batch not Found',
                text: "Try again or create a new batch"
            })
            return false;
          }

          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                batchId: this.batchId,
                isVerified: this.isVerified,
                isGuardian: this.isGuardian,
                guardianId: this.guardianId,
                note: this.note,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/batches/process', requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Batch Processed',
                showConfirmButton: false,
                timer: 1500
              })  
              this.$router.push('/transaction')         
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Batch not Processed',
                  text: data.message
              })
          }
      },
      cancelBatchAlert(){
          Swal.fire({
            title: 'Confirm Batch Cancellation',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!'
          }).then((result) => {
            if (result.isConfirmed) {
              this.cancelBatch();
            }
          })
      },
      async cancelBatch () {
          const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/batches/cancel/'+this.batchId, requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Batch Cancelled',
                showConfirmButton: false,
                timer: 1500
              })
              this.$router.push('/transaction')          
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Batch Cancellation Failed',
                  text: data.message
              })
          }
      },
      splitBatchModal(){
        Swal.fire({
          title: 'Confirm',
          text: "Kindly confirm that you want to split receipt.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#4448B3',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm'
        }).then((result) => {
          if (result.isConfirmed) {
            this.splitBatch()
          }
        })
      },
      async splitBatch(){
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/batches/split/'+this.batchId, requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'Batch Splitted',
              showConfirmButton: false,
              timer: 1500
            })       
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Batch Split Failed',
                text: data.message
            })
        }
      },
      async removeItem (itemId) {
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  itemId: itemId,
                  batchId: this.batchId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/batches/items/delete', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.items = data.items
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Batch Item not found',
                  text: data.message
              })
          }
      },
      async postBatch () {
        let formData = new FormData()
        formData.append("name", this.name)
        formData.append("isInternal", this.isInternal)
        formData.append("driverId", this.driverId)
        formData.append("driver", this.driver)
        formData.append("contacts", this.contacts)
        formData.append("vehicle", this.vehicle)
        formData.append("project", this.project)
        formData.append("document", this.document)
        formData.append("reference1", this.reference1)
        formData.append("reference2", this.reference2)
        formData.append("file", this.referenceDocument)
        formData.append("nogId", this.nogId)
        formData.append("tagId", this.tagId)
        formData.append("supplierId", this.supplierId)
        formData.append("purchaseId", this.purchaseId)
        axios.post(process.env.VUE_APP_URL+'/batches', formData, {
          headers:{ 
            "Content-Type": "multipart/form-data", 
            'Authorization': 'Bearer '+this.token 
          }}).then(function(data) {
            console.log(data)
          if(data.data.status == true){
            this.batchId = data.data.batch.id
            this.purchase = data.data.purchase
            this.displayItems = "block"
            this.setupPurchase()
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Batch not created',
                  text: data.data.message
              })
          }
        }.bind(this)).catch((error) => {
          console.log({error});
          Swal.fire({
              icon: 'error',
              title: 'Stock Category is required',
              text: 'Select the stock category'
          })
        });
      },
      async postItem () {
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  productId: this.productId,
                  batchId: this.batchId,
                  quantity: this.quantity,
                  price: this.price,
                  condition: this.condition,
                  comment: this.comment,
                  batchNo: this.batchNo,
                  warehouseId: this.warehouseId,
                  sectionId: this.sectionId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/batches/items', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.items = data.items
              this.displayItems = "block"
              this.quantity = ""
              this.price = ""
              this.condition = "new"
              this.comment = ""
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Batch Item not created',
                  text: data.message
              })
          }
      },

      async setupPurchase(){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };

            try{
              const res = await fetch(process.env.VUE_APP_URL+'/purchases/'+this.purchaseId, requestOptions)
              console.log("Purchase Response", res)
              const data = await res.json()
              if(data.status == true){
                  this.purchase = data.purchase;
                  console.log("Purchase", this.purchase)
                  console.log("Purchase Product",this.purchase.PurchaseProduct)
              }else{
                console.log("error fetching purchases")
              }
            }catch(err){
              console.log(err)
            }
            
      },

      async completeItem (itemId, productId) {
        this.poQuantity=document.getElementById('quantity'+itemId).value;
        this.poPrice=document.getElementById('price'+itemId).value;
        this.poCondition=document.getElementById('condition'+itemId).value;
        this.poComment=document.getElementById('comment'+itemId).value;
        this.poProductId=productId;
        this.poItemId = itemId;
        this.isVisiblePO = true;
      },
      async postCompleteItem () {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                productId: this.poProductId,
                batchId: this.batchId,
                purchaseId: this.poItemId,
                quantity: this.poQuantity,
                price: this.poPrice,
                condition: this.poCondition,
                comment: this.poComment,
                warehouseId: this.poWarehouseId,
                sectionId: this.poSectionId,
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/batches/items', requestOptions)
        const data = await res.json()
        if(data.status == true){
          let btn = document.getElementById('button'+this.poItemId)
          btn.setAttribute('disabled','disabled')
          btn.disabled = true
          btn.value = 'Done'
          Swal.fire({
            icon: 'success',
            title: 'Batch Item created',
            text: "Item completed"
          })
          this.isVisiblePO = false;
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Batch Item not created',
                text: data.message
            })
        }
      },
      handleFileChange() {
        this.referenceDocument = this.$refs.fileDocument.files[0];
      }
      
  },
  watch:{
      productId(newValue){
        const parsedValue = parseInt(newValue)
        let productObj = this.products.find(i => i.id === parsedValue);
        this.itemUnit = productObj?.unit?.unit
        this.itemNog = productObj?.Category?.category
        this.itemSubUnit = "None"
      },
      warehouseId(newValue){
          let obj = this.warehouses.find(i => i.id === newValue);
          this.sections = obj.sections
          this.sectionId = ""
      },
      poWarehouseId(newValue){
          let obj = this.warehouses.find(i => i.id === newValue);
          this.sections = obj.sections
          this.poSectionId = ""
      },
      isGuardian(newValue){
        if(newValue)
          this.displayGuardian = "block"
        else
          this.displayGuardian = "none"
        
      },
      isInternal(newValue) {
        if(newValue){
          this.DriverDisplay = "block"
          this.SupplierDisplay = "none"
        } else{
          this.DriverDisplay = "none"
          this.SupplierDisplay = "block"
        }
      }
  }
}
</script>

<style lang="scss" scoped>
  $text-color: #002060;
  $primary: #d2d2d2;
  $green: #4448B3;
  $white: #ffffff;
  select {
    /* inline SVG */
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"), #fff;
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius: 3px;
    height: 45px;
    border: 1px solid #b4c7e7 !important;
    padding: 5px 30px 5px 10px;
    // disable default appearance
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    &::-ms-expand { display: none };
    &.full{
      width: 100%;
    }
    &:focus, &:hover{
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"), #fff;
      border: 1px solid $text-color;
      background-position: right 10px center;
      background-repeat: no-repeat;
      background-size: auto 50%;
    }
  }

  .form-control{
  border: 1px solid black; 
  border-radius: 5px;
  }

  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                    height: 30px !important;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    height: 30px !important;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }

</style>