export const filterRecords = (array, searchQuery) => {
    const query = searchQuery.trim().toLowerCase()
    if(query){
        return array.filter(obj => {
            return Object.values(obj).some(value => {
                if (typeof value === 'string') {
                    const lowercasedValue = value.toLowerCase();
                    return lowercasedValue.includes(query);
                }
                return false;
            });
        });
    }else {
        return array;
    }
}

export const  addLists = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        throw new Error("Arrays must have the same length");
    }

    let arr3 = [];

    for (let i = 0; i < arr1.length; i++) {
        let sumElement = arr1[i] + arr2[i];
        arr3.push(sumElement);
    }
    return arr3;
}

export const getThisMonthStats = (array) => {
    const today = new Date();
    const thisMonth = today.getMonth();
    const thisYear = today.getFullYear();
    const thisMonthValues = array.filter(obj => {
        const createdAtDate = new Date(obj.createdAt);
        const createdAtMonth = createdAtDate.getMonth();
        const createdAtYear = createdAtDate.getFullYear();
        return createdAtMonth === thisMonth && createdAtYear === thisYear;
    });
    return thisMonthValues.length; 
}

export const countThisMonthValues = (array) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // January is 0, December is 11

    const thisMonthValues = array.filter(obj => {
        const createdAtDate = new Date(obj.createdAt);
        return createdAtDate.getFullYear() === currentYear && createdAtDate.getMonth() === currentMonth;
    });

    return thisMonthValues.length;
}

export const  countLastMonthValues = (array) => {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1); // Set last month's date
    
    const lastMonthStartDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1); // First day of last month
    const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of last month
    
    const valuesLastMonth = array.filter(obj => {
        const createdAtDate = new Date(obj.createdAt);
        return createdAtDate >= lastMonthStartDate && createdAtDate <= lastMonthEndDate;
    });
    return valuesLastMonth.length;
}

export const  addArrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        throw new Error("Arrays must have the same length");
    }

    let arr3 = [];

    for (let i = 0; i < arr1.length; i++) {
        let sumElement = arr1[i] + arr2[i];
        arr3.push(sumElement);
    }

    return arr3;
}

const formatDate = (rawDate, timeOption) => {
    if(rawDate){
      const dateNo = new Date(rawDate)
      if(timeOption == "true"){
        var minutes = 0;
        if(dateNo.getMinutes() < 10){
          minutes = "0"+dateNo.getMinutes()
        } else {
          minutes = dateNo.getMinutes()
        }
        var hours = 0;
        if(dateNo.getHours() < 10){
          hours = "0"+dateNo.getHours()
        } else {
          hours = dateNo.getHours()
        }
        return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear()+" - "+hours+":"+minutes
      } else {
        return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear();
      }
    } else {
      return ""
    }
}


export const docFooter = (currentPage, pageCount) => {
    const profile = JSON.parse(localStorage.getItem('profile'))
    //on ${formatDate(new Date(), "true")}
    console.log(formatDate(new Date(), "true"))
    return {
        stack: [
          {
            table: {
              widths: ['*'],
              body: [
                [
                    {
                      columns: [
                        { width: '*', text: profile?.businessWebsite || '', alignment: 'left', color: 'black', border: [false, false, false, false] },
                        { width: '*', text: profile?.businessEmail || '', alignment: 'center', color: 'black', border: [false, false, false, false] },
                        { width: '*', text: `Page ${currentPage} of ${pageCount}`, alignment: 'center', color: 'black', border: [false, false, false, false] },
                        { width: '*', text: `Generated by - ${profile.systemName}`, alignment: 'right', color: 'black', border: [false, false, false, false] }
                      ],
                      margin: [40, 10, 40, 10], // Margin for the whole footer
                      fillColor: '#ffffff',
                      border: [false, false, false, false] // No border
                    }
                ],
                [
                  {
                    text: `Generated by ${profile?.systemName}`,
                    alignment: 'center',
                    margin: [0, 0, 0, 10], // Margin for the centered text
                    color: 'black', // Assuming you want the text in black
                    fillColor: '#ffffff',
                    border: [false, false, false, false] // No border
                  }
                ]
               
              ],
              layout: 'noBorders' // No borders for the table
            },
            fillColor: '#f2f2f2' // Background color for the whole footer
          }
        ]
      };
}