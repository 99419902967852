<template>
    <div class="home">
        <Header :mainHeader="true"/>
        <section id="settings-categories">
            <div class="container ">
                <h4 class="mb-5 fw-bold">Company Details</h4>
            
            <div class="tab-pane px-20" id="settings">
                <form class="form-horizontal" @submit.prevent="postProfile">
                  <div class="form-group row mb-3">
                    <label  for="inputName" class="col-sm-2 col-form-label fw-bold"></label>
                    <div class="col-sm-10 d-flex justify-content-center align-items-center ">
                      <label v-if="!imageUrl" @click="openUploadWidget" class="form-group row d-flex align-items-center justify-content-center well" >
                          <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 24 24" style="fill: grey;transform: ;msFilter:;"><path d="M13 19v-4h3l-4-5-4 5h3v4z"></path><path d="M7 19h2v-2H7c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.756 2.673-3.015l.581-.102.192-.558C8.149 8.274 9.895 7 12 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-3v2h3c2.206 0 4-1.794 4-4a4.01 4.01 0 0 0-3.056-3.888C18.507 7.67 15.56 5 12 5 9.244 5 6.85 6.611 5.757 9.15 3.609 9.792 2 11.82 2 14c0 2.757 2.243 5 5 5z">
                          </path></svg>
                          <!--<input type="file" hidden 
                            ref="fileInput"
                            @change="handleFileUpload"
                            >-->
                      </label>

                      <!--<button @click="openUploadWidget">Select Image</button>-->
                      <div v-else class="position-relative">
                        <img  :src="imageUrl" alt="Preview" class="mb-4" style="max-width: 200px; max-height: 200px; margin-top: 10px;">
                        <div @click="openUploadWidget" class="img-icon position-absolute  bottom-0 p-4 d-flex justify-content-center align-items-center right-0">
                          <div class="icon">
                            <i class="fa fa-camera" aria-hidden="true"></i>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                  
                  <div class="form-group row mb-3">
                    <label for="inputName" class="col-sm-2 col-form-label fw-bold">Company</label>
                    <div class="col-sm-10">
                      <input type="text"  class="form-control" placeholder="Business Name" v-model="businessName">
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label for="inputEmail" class="col-sm-2 col-form-label fw-bold">Email</label>
                    <div class="col-sm-10">
                      <input type="email" class="form-control" placeholder="business email" v-model="businessEmail">
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="inputName2" class="col-sm-2 col-form-label fw-bold">Tel</label>
                    <div class="col-sm-10">
                      <input type="tell" class="form-control" placeholder="0712345678" v-model="businessTel">
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="inputName2" class="col-sm-2 col-form-label fw-bold">Address</label>
                    <div class="col-sm-10">
                      <input type="text" class="form-control" placeholder="PO Box" v-model="businessAddress">
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="inputSkills" class="col-sm-2 col-form-label fw-bold">Domain</label>
                    <div class="col-sm-10">
                      <input type="" class="form-control"  placeholder="www.business.com" v-model="businessWebsite"/>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="inputSkills" class="col-sm-2 col-form-label fw-bold">System Name</label>
                    <div class="col-sm-10">
                      <input type="" class="form-control"  placeholder=" System Name" v-model="systemName"/>
                    </div>
                  </div>
                  <div class="form-group row mt-3">
                    <div class="col-sm-6">
                    </div>
      
                    <div class="col-sm-4">
                      
                    </div>
                    <div class="col-sm-2 d-flex justify-content-end gap-2  align-items-center">
                      <p class="mr-5 btn-style-2" @click="clear" style="border: none !important; background-color: #e6e6e6 !important; box-shadow: 2px 2px 2px 2px #e6e6e6; height: 34px !important; margin-top: 10px; margin-right: 10px; ">Clear</p>
                      <button type="submit" class="btn-style-2 btn-primary  px-2" style="height: 37px !important">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </section> 
    </div>
</template>

<script>
import Header from '../../components/Header';
import Swal from 'sweetalert2'
import axios from 'axios'


export default {
    name: 'SettingsCategoryPage',
    components:{
        Header
    },

    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        profile: {},
        businessName: "",
        businessEmail: "",
        businessTel:  "",
        businessWebsite: "", 
        businessLogo: "",
        businessAddress: "",
        systemName: "",
        imageUrl: "",
        widget: null
    }),

    mounted(){
      this.profile = JSON.parse(localStorage.getItem('profile'))
      this.businessName = this.profile.businessName
      this.businessEmail = this.profile.businessEmail
      this.businessTel = this.profile.businessTel
      this.businessWebsite = this.profile.businessWebsite
      this.businessLogo = this.profile.businessLogo
      this.businessAddress = this.profile.businessAddress
      this.imageUrl = this.profile.businessLogo
      this.systemName = this.profile.systemName
    },

    methods:{
        clear(){
          this.businessName = ""
          this.businessEmail = ""
          this.businessTel = ""
          this.businessWebsite = ""
          this.businessLogo = ""
          this.businessAddress = ""
          this.imageUrl = ""
          this.systemName = ""
        },

        handleFileChange() {
          this.systemName = this.$refs.filePicture.files[0];
        },

        openUploadWidget(){
          const widget = window.cloudinary.createUploadWidget(
            {cloud_name: "djzduga98", upload_preset: "jxeytapf"},
            (error, result) => {
              //console.log(error);
              //console.log("Result", result);
              if(!error && result.event === "success"){
              // console.log(result.info)
                this.imageUrl = result.info.secure_url
                this.businessLogo = this.imageUrl
              }
            }
          );
          if(widget)
            widget.open()
        },
        async postProfile () {
          //let formaData = new FormData()
          let formaData = {
            businessName: this.businessName,
            businessEmail: this.businessEmail,
            businessTel: this.businessTel, 
            businessAddress: this.businessAddress,
            businessWebsite: this.businessWebsite, 
            businessLogo: this.businessLogo,
            systemName: this.systemName
          }
          try {
            const response = await axios.post(`${process.env.VUE_APP_URL}/profile`, formaData, {
              headers: {
                'Authorization': 'Bearer ' + this.token
              }
            });

            console.log("Profile Response", response)
            if (response.status === 200) {
              const data = response.data;
              //console.log("Data",data)
              if (data.status === true) {
                Swal.fire({
                  icon: 'success',
                  title: 'Profile created',
                  text: data.message
                });
                //console.log(data)
                localStorage.setItem('profile', JSON.stringify(data.profile))
                window.location.reload(); 
                const { profile } = data
                this.businessName = profile.businessName
                this.businessEmail = profile.businessEmail
                this.businessTel = profile.businessTel
                this.businessWebsite = profile.businessWebsite
                this.businessLogo = profile.businessLogo
                this.systemName = profile.systemName

              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Profile not created',
                  text: data.message
                });
              }
            } else {
              console.log(response)
              console.error('Unexpected status code:', response.status);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to create profile. Please Try again later'
              });
            }
          }catch (error) {
            console.error('Error creating profile:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to create profile. Please try again later.'
            });
        }
      },
    },

}

</script>