<template>
    <div class="transaction">
  
    <Header :mainHeader="true"/>

    <section id="transaction-products">
        <div class="container">
            <h4 class="mb-3 fw-bold title">System Settings & Configurations – Roles & Permissions</h4>
            
            <h5 for="" class="text-primary" style="margin-left: 10px !important">Add Role</h5>
            <div class="panel">
                <div class="row">
                    <div class="col-md-3">
                        <label for="">Role </label>
                        <input type="text"  class="form-control" v-model="name" >
                    </div>
                    <div class="col-md-3">
                        <label for="">Description</label>
                        <input type="text"  class="form-control" v-model="description" >
                    </div>
                    <div class="col-md-2">
                        <label for="">Department</label>
                        <select  v-model="departmentId" class="form-control"  v-if="departments.length > 0">
                            <option v-for="department in departments" v-bind:key="department.id" :value="department.id">
                                {{  department.name }}
                            </option>
                        </select>
                        <div v-else>
                            <label for="" style="color: red !important;">No Deparment Found.</label> 
                            <button  class="btn btn-primary btn-sm" @click="this.fetchDepartments">Fetch Departments</button>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="">Level of Hierarchy</label>
                        <select  v-model="departmentRank" class="form-control"  v-if="departments.length > 0">
                            <option v-for="index in ranks" v-bind:key="index" :value="index">
                                {{ index }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary btn-small" @click="postRole" style="margin-top: 27px;">Create</button>
                    </div>
                </div>
            </div>
            <div class="purchases-list">
                <div class="row mt-3" style="padding: 0px 15px;">
                    <h5 style="padding-left: 0px;" class="text-primary">Roles List</h5>
                    <table class="table table-hover" >
                        <thead>
                            <th>No.</th>
                            <th>Role </th>
                            <th>Description </th>
                            <th>Deparment </th>
                            <th>Rank in Department</th> 
                            <th>No. of Users </th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            <tr v-for="(role,index) in roles" v-bind:key="role.id">
                                <td>{{ index+1 }}</td>
                                <td>{{ role.name}}</td>
                                <td>{{ role.description}}</td>
                                <td>{{ role?.deparment?.name }}</td>
                                <td style="text-align: center;">{{ role?.deparmentRank }}</td>
                                <td v-if="role.users" style="text-align: center;">{{ role.users.length}}</td>
                                <td v-else style="text-align: center;">0</td>
                                <td>
                                    <button class="btn-sm btn btn-primary" @click="$router.push({name: 'SettingsRole', params:{ id: role.id}})">Edit</button>
                                    <button class="btn-sm btn btn-danger" @click="deleteRole(role.id)" style="margin-left:5px;">Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </section>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import Header from '../../components/Header';

export default {
    name: 'SettingsRoles',
    components:{
        Header
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        name: "",
        description: "",
        departmentId:"",
        departmentRank: "",
        ranks: 0
    }),
    computed:{
        ... mapGetters (["getRoles", 'getDepartments']),
        roles () { return this.getRoles },
        departments () { return this.getDepartments },

    },
    methods:{
        ... mapActions(['fetchRoles', "fetchDepartments"]),
        async postRole () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    description: this.description,
                    name: this.name,
                    departmentId: this.departmentId, 
                    departmentRank: this.departmentRank
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/permissions', requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Role created',
                    text: 'Role created successfully'
                })
                this.fetchRoles();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Role not created',
                    text: data.message
                })
            }
        },
         
        isDepartment(element){
            return element.id == this.departmentId
        },
        
        async deleteRole(id) {
            const requestOptions = {
                method: "GET",
                headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/permissions/delete/'+id, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Role deleted successfully',
                    text: 'Role deleted'
                })
                this.fetchRoles();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Role not deleted',
                    text: data.message
                })
            }
        },
    },



    mounted(){
        this.fetchRoles()
        this.fetchDepartments()
    },

    watch: {
        departmentId () {
        const department = this.departments.filter(this.isDepartment)
        this.ranks = department[0].ranks
        console.log("Department", department)
        }
    },



}
</script>


<style lang="scss">
  $text-color: #002060;
  $primary: #d2d2d2;
  $green: #4448B3;
  $white: #ffffff;
  select {
    /* inline SVG */
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"), #fff;
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius: 3px;
    height: 45px;
    border: 1px solid #b4c7e7 !important;
    padding: 5px 30px 5px 10px;
    // disable default appearance
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    &::-ms-expand { display: none };
    &.full{
      width: 100%;
    }
    &:focus, &:hover{
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"), #fff;
      border: 1px solid $text-color;
      background-position: right 10px center;
      background-repeat: no-repeat;
      background-size: auto 50%;
    }
  }

  .form-control{
  border: 1px solid black; 
  border-radius: 5px;
  }

  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                    height: 30px !important;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    height: 30px !important;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }

</style>