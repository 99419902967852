<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h4 class="fw-bold text-primary mb-4">{{ warehouse?.name }} - {{ warehouse?.code }}
            <button style="float: right;" class="btn btn-danger" @click="deleteWarehouse(warehouseId,'warehouse')">Delete Warehouse</button>
            <button style="float: right;border: 1px solid #000; margin-right: 15px;" class="btn btn-default" @click="isVisible =true">Edit Warehouse</button>
          </h4>
          <div>
            <strong>Section List</strong>
            <table class="table table-hover" v-if="sections.length > 0">
              <thead>
                <th>No.</th>
                <th>Section</th>
                <th>Description</th>
                <th>No. of Bins</th>
                <th>No. of Products</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(section,index) in sections" v-bind:key="section.id">
                  <td>{{ index+1 }}</td>
                  <td>{{ section.name }}</td>
                  <td>{{ section.description }}</td>
                  <td>{{ section.binCount }}</td>
                  <td>{{ getQuantity(section?.id, 'section') }}</td>
                  <td>
                    <button class="btn btn-default btn-sm" style="border: 1px solid #000; margin-right: 10px;" 
                    @click="$router.push('/warehouses/product/'+section?.id+'/section/'+warehouse?.name+'-'+warehouse?.code+' - '+section?.name)">View Products</button>
                    <button style="border: 1px solid #000; margin-right: 10px;" class="btn btn-sm btn-default" @click="editSection(section)">Edit</button>
                    <button class="btn btn-danger btn-sm"  @click="deleteWarehouse(section?.id,'section')">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>



            <div v-else>
              <label for=""  style="margin: 0px auto; color:red; font-size:11px !important;  width: fit-content; display: block;">No section  found</label>
          </div>


          </div>
          
          <div class="mt-4">
            <strong>Virtual Warehouses</strong>
            <table class="table table-hover" v-if="virtuals?.length > 0">
              <thead>
                <th>No.</th>
                <th>Virtual W/H</th>
                <th>Description</th>
                <th>No. of Products</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(virtual,index) in virtuals" v-bind:key="virtual.id">
                  <td>{{ index+1 }}</td>
                  <td>{{ virtual.name }}</td>
                  <td>{{ virtual.description }}</td>
                  <td>{{  getQuantity(virtual?.id, 'virtual') }}</td>
                  <td>
                    <button class="btn btn-default btn-sm" style="border: 1px solid #000; margin-right: 10px;" 
                    @click="$router.push('/warehouses/product/'+virtual?.id+'/virtual/'+warehouse?.name+'-'+warehouse?.code+' - '+virtual?.name)">View Products</button>
                    <button style="border: 1px solid #000; margin-right: 10px;" class="btn btn-sm btn-default" @click="editVirtual(virtual)">Edit</button>
                    <button class="btn btn-danger btn-sm" @click="deleteWarehouse(section?.id,'virtual')">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-else>
                <label for=""  style="margin: 0px; color:red; font-size:11px !important;  width: fit-content; display: block;">No virtual warehouse found</label>
            </div>
          </div>
  
        </div>
      </section>
  
      <div style="margin-bottom: 50px;">
        <button class="btn btn-default" style="float: right; margin-right: 30px;border:1px solid black;" @click="this.$router.push('/warehouses')">Close</button>
      </div>

<ModalWarehouse v-if="isVisible" @close="closeModal" >
  <template v-slot:header>
    <h3>Update Warehouse</h3>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="form-group col-12">
        <label for="item-code">Warehouse Name</label>
        <input type="text" class="form-control" v-model="name">
      </div>
      <div class="form-group col-12">
        <label for="item-code">Physical Location</label>
        <input type="text" class="form-control" v-model="location">
      </div>
      <div class="form-group col-12">
        <label for="item-code">Description</label>
        <input type="text" class="form-control" v-model="description">
      </div>

      <div class="check-button mt-3">
        <div class="form-group">
            <label>Has Sections</label>
            <div class="components-button">
              <span class="switcher switcher-1">
                  <input type="checkbox" id="switcher-1" v-model="hasSections" :disabled="warehouseSections">
                  <label for="switcher-1"></label>
              </span>
            </div>
        </div>
      </div>

      <div class="check-button">
        <div class="form-group">
            <label>Has Bins</label>
            <div class="components-button">
        <span class="switcher switcher-1">
            <input type="checkbox" id="switcher-1" v-model="hasBins" :disabled="warehouseBins">
            <label for="switcher-1"></label>
        </span>
            </div>
        </div>
      </div>


      <div class="" style="margin-top: 2px !important ">
        <div class="d-flex justify-content-between"><strong>Add Sections</strong><span class="text-primary cursor-pointer" @click="toggleSections" > {{ showSections? 'Hide Sections' : 'View Sections' }}</span></div>
        <table class="table table-hover">
          <thead>
            <th>No.</th>
            <th>Section Name</th>
            <th>Section Description</th>
            <th>No. of Bins</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr>
                <td>#</td>
                <td><input type="text" placeholder="Enter name" class="form-control" v-model="sectionName"></td>
                <td><input type="text" placeholder="Enter Description" class="form-control" v-model="sectionDescription"></td>
                <td><input type="number" min="0" placeholder="No of bins" class="form-control" v-model="binCount"></td>
                <td><button class="btn-style small pull-right" style="border:1px solid black;" @click="postSection">Add</button></td>
            </tr>
          </tbody>
          <tbody v-if="showSections">
              <tr v-for="(section, index) in sections" v-bind:key="section.id"  >
                <td>{{ index+1 }}</td>
                <td>{{ section.name }}</td>
                <td>{{ section.description }}</td>
                <td>{{ section.binCount }}</td>
                <td>
                    <button class="btn btn-default btn-sm" style="border:1px solid black;" @click="removeSection(section.id)">Remove</button>
                </td>
              </tr> 
          </tbody>
        </table>
      </div>

    </div>
  </template>

  <template v-slot:footer>
    <button class="btn-style small" style="border: 1px solid black; float: right;" @click="updateWarehouse">Update</button>
    <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Cancel</button>
  </template>
</ModalWarehouse>


<Modal v-if="isVisibleSection" @close="closeModalSection" >
  <template v-slot:header>
    <h3>Update Warehouse Section</h3>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="form-group col-12">
        <label for="item-code">Section Name</label>
        <input type="text" class="form-control" v-model="sectionName">
      </div>
      <div class="form-group col-12">
        <label for="item-code">Section Description</label>
        <input type="text" class="form-control" v-model="sectionDescription">
      </div>
      <div class="form-group col-12">
        <label for="item-code">No. of Bins</label>
        <input type="number" min="0" class="form-control" v-model="sectionBins">
      </div>
    </div>
  </template>

  <template v-slot:footer>
    <button class="btn-style small" style="border: 1px solid black; float: right;" @click="updateSection">Update</button>
    <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisibleSection =false">Cancel</button>
  </template>
</Modal>

<Modal v-if="isVisibleVirtual" @close="closeModalVirtual" >
  <template v-slot:header>
    <h3>Update Virtual Warehouse</h3>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="form-group col-12">
        <label for="item-code">Name</label>
        <input type="text" class="form-control" v-model="virtualName">
      </div>
      <div class="form-group col-12">
        <label for="item-code">Description</label>
        <input type="text" class="form-control" v-model="virtualDescription">
      </div>
    </div>
  </template>

  <template v-slot:footer>
  <button class="btn-style small" style="border: 1px solid black; float: right;" @click="updateVirtual">Update</button>
  <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisibleVirtual =false">Cancel</button>
  </template>
</Modal>
    </div>
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import Modal from '../../components/Modal.vue'
  import ModalWarehouse from '../../components/ModalWarehouse.vue'
  
  export default {
    name: 'ViewWarehouse',
    components: {
      Header, 
      Modal,
      ModalWarehouse
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        warehouse: "",
        name:"",
        location:"",
        description:"",
        warehouseId: "",
        virtuals:[],
        sections:[],
        hasSections: false,
        hasBins: true, 
        warehouseSections: false,
        warehouseBins: false,
        displaySections: "none",
        sectionId:"",
        sectionName:"",
        sectionDescription:"",
        sectionBins:0,
        virtualId:"",
        virtualName:"",
        virtualDescription:"",
        isVisible: false,
        isVisibleSection: false,
        isVisibleVirtual: false,
        showSections: false
    }),
    mounted(){
        this.warehouseId = this.$route.params.id
        this.setupWarehouse(this.warehouseId)
        this.setupInventory()
    },
    methods:{
        closeModal() {
          this.isVisible = false;
        },
        closeModalSection() {
          this.isVisibleSection = false;
        },
        closeModalVirtual() {
          this.isVisibleVirtual = false;
        },

        toggleSections(){
          this.showSections = !this.showSections
        },
        async setupWarehouse(itemId){
          const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/warehouses/'+itemId, requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.warehouse = data.warehouse;
              this.warehouseId= data.warehouse.id;
              this.sections= data.warehouse.sections;
              this.virtuals = data.virtuals;
              this.name = data.warehouse.name;
              this.location = data.warehouse.location;
              this.description = data.warehouse.description;
              this.hasSections = data.warehouse.hasSections
              this.hasBins = data.warehouse.hasBins
              this.warehouseSections = data.warehouse.hasSections,
              this.warehouseBins = data.warehouse.hasBins

          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Warehouse not found. Try again',
                  text: data.message
              })
          }
        },
        
        async removeSection (sectionId) {
            if(this.sections.length == 1){
                Swal.fire({
                    icon: 'error',
                    title: 'Cannot remove section',
                    text: 'A warehouse must have at least one section'
                })
                return 
            }

            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/warehouses/delete/'+sectionId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.sections = data.sections
                this.warehouseSections = this.sections.length == 0 ? false :true
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Section not deleted',
                    text: data.message
                })
            }
        },

        async postSection () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    name: this.sectionName,
                    description: this.sectionDescription,
                    location: "",
                    sectionId: this.warehouseId,
                    binCount: this.binCount,
                    hasSections: false,
                    hasBins: false
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/warehouses', requestOptions)
            const data = await res.json()
            if(data.status == true){
              this.sectionDescription = "";
              this.sectionName = "";
              this.binCount = 0;
              this.sections= data.warehouse.sections
              this.warehouseSections = this.sections.length == 0 ? false :true
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Warehouse Section not created',
                    text: data.message
                })
            }
        },

        async deleteWarehouse (itemId, type) {
          Swal.fire({
            title: 'Confirm Warehouse Deletion',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              this.delete(itemId, type)
            }
          })
            
        },


        async delete(itemId, type){
          const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
              };
              const res = await fetch(process.env.VUE_APP_URL+'/warehouses/delete/'+itemId, requestOptions)
              const data = await res.json()
              if(data.status == true){
                  Swal.fire({
                      icon: 'success',
                      title: 'Warehouse deleted successfully',
                      text: 'Warehouse deleted'
                  })
                  if(type == 'warehouse'){
                    this.$router.push("/warehouses")
                  }
              }else{
                  Swal.fire({
                      icon: 'error',
                      title: 'Warehouse not deleted',
                      text: data.message
                  })
          }
        },


        async updateWarehouse () {
         

            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    name: this.name,
                    location: this.location,
                    description: this.description,
                    hasBins: this.hasBins
            
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/warehouses/'+this.warehouseId, requestOptions)
            console.log(res)
            const data = await res.json()
            console.log(data)
            if(data.status == true){ 
              this.isVisible = false;
              Swal.fire({
                    icon: 'success',
                    title: 'Warehouse updated',
                    text: data.message
                })
             
              this.setupWarehouse(this.warehouseId)
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Warehouse not updated',
                    text: data.message
                })
            }
        },
        async setupInventory(){
          const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/inventory/warehouse', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.inventory = data.inventory;
          }
        },
        getQuantity(id,type){
          if(type == 'section'){
            var product = this.inventory?.find(obj => obj.sectionId == id)
            if(product) return product?._count;
            return 0;
          } else{
            var productel = this.inventory.find(obj => obj.warehouseId == id)
            if(productel) return productel?._count;
            return 0;
          }
        },
        editSection(section){
          this.isVisibleSection = true
          this.sectionBins = section.binCount
          this.sectionName = section.name
          this.sectionDescription = section.description
          this.sectionId = section.id
        },
        editVirtual(virtual){
          this.isVisibleVirtual = true
          this.virtualDescription = virtual.description
          this.virtualName = virtual.name
          this.virtualId = virtual.id
        },
        async updateSection(){
          const requestOptions = {
              method: "PUT",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  name: this.sectionName,
                  description: this.sectionDescription,
                  binCount: this.sectionBins,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/warehouses/'+this.sectionId, requestOptions)
          const data = await res.json()
          if(data.status == true){
            this.isVisibleSection = false;
            this.setupWarehouse(this.warehouseId)
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Warehouse section not updated',
                  text: data.message
              })
          }
        },
        async updateVirtual(){
          const requestOptions = {
              method: "PUT",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  name: this.virtualName,
                  description: this.virtualDescription,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/warehouses/'+this.virtualId, requestOptions)
          const data = await res.json()
          if(data.status == true){
            this.isVisibleVirtual = false;
            this.setupWarehouse(this.warehouseId)
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Virtual Warehouse not updated',
                  text: data.message
              })
          }
        }
        
    }
  }
  </script>



<style lang="scss" scoped>


$primary: #d2d2d2;
$green: #4448B3;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    height: 30px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    height: 30px !important;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
  </style>