<script>
  export default {
    name: 'ModalVue',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop-2">
      <div class="modal-2"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-headerr"
          id="modalTitle"
        >
          <slot name="header">
            This is the default tile!
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
          </button>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            This is the default body!
          </slot>
        </section>

        <footer 
            class="modal-footer"
        >
          <slot name="footer">
            This is the default footer!
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.modal-backdrop-2 {
  position: relative;
  min-height: 100vh;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  width: 100vw !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 1000; 
  display: flex !important;
  justify-content: center !important;


}

.modal-2 {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px rgba(0, 0, 0, 0.5);
  display: flex !important;
  flex-direction: column;
  width: 80% !important;
  max-width: 1000px;
  height: auto !important;
  padding: 20px !important;
  z-index: 1001; 
  margin-top: 30px;
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
    gap: 10px;
}

  .modal-headerr {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #002060;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #002060;
    border: 1px solid #002060;
    border-radius: 2px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }


  /* Media queries for small screens */
@media (max-width: 715px) {
  .modal-header{
    display: none; /* Hide the header on small screens */
  }

  
  .modal-2 {
    background: #FFFFFF;
    display: flex !important;
    flex-direction: column;
    width: 100% !important;
    height: auto !important;
    padding: 20px !important;
    z-index: 1001; 
    margin-top: 0px;
  }

  .modal-body {
    position: relative;
    padding: 0px;
  }

  .modal-footer {
    display: none;
  }



}
</style>