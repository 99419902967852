<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="inventory-products">
        <div class="container">
          <h3>Edit Product</h3>
          <div class="row">
            <div class="col-md-6 align-self-center">
              <strong>1. Product Specifications</strong>
            </div>
            <div class="col-md-6 align-self-center">
              <div class="upload-products">
                <a v-if="product?.safety" target="_blank" :href="`${urlServer}/files/${product?.safety}`" class="btn-style">View Material Safety Data</a>
                <a v-if="product?.picture" target="_blank" :href="`${urlServer}/files/${product?.picture}`" class="btn-style">View Product Picture</a>
              </div>
            </div>
          </div>
          <div class="product-specification">
            <div class="form" style="margin-top: 20px;">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="product-name">Product Name/Description <span style="color: red !important;">*</span></label>
                    <input type="text" class="form-control" id="product-name" placeholder="Enter name" v-model="name">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Item Code</label>
                    <input type="text" class="form-control" id="item-code" placeholder="Enter code" v-model="code">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="product-category">Product Category <span style="color: red !important;">*</span></label>
                    <select v-model="categoryId" class="form-control" id="product-category"  v-if="categories.length > 0">
                        <option v-for="category in categories" v-bind:Key="category.id" :value="category.id">
                            {{ category.category }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Product Category.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchCategories">Fetch Product Categories</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="product-sub-category">Product Sub-cat <span style="color: red !important;">*</span></label>
                    <select v-model="subCategoryId" class="form-control" id="product-sub-category">
                        <option value="0" v-if="subcategories?.length == 0">None</option>
                        <option v-for="subcategory in subcategories" v-bind:Key="subcategory.id" :value="subcategory.id">
                            {{ subcategory.category }}
                        </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="purchase-price">Purchase Price (Cost of Product)</label>
                    <input type="number" class="form-control" id="purchase-price" placeholder="Enter Kshs" v-model="pricePurchase">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="retail-price">Retail Selling Price</label>
                    <input type="number" class="form-control" id="retail-price" placeholder="Enter Kshs" v-model="priceRetail">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="wholesale-price">Wholesale Selling Price</label>
                    <input type="number" class="form-control" id="wholesale-price" placeholder="Enter Kshs" v-model="priceWholesale">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="product-safety">Material Safety Data</label>
                    <div class="input-file">
                      <input type="file" id="product-safety" ref="fileSafety" @change="handleFileChange">
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="product-picture">Product Picture</label>
                    <div class="input-file">
                      <input type="file" id="product-picture" ref="filePicture" @change="handleFileChange">
                    </div>
                  </div>
                </div>
  
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="product-alternative-name">Product Alternative Name</label>
                    <input type="text" class="form-control" id="product-alternative-name" placeholder="Enter name" v-model="alternativeName">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="product-unit">Unit <span style="color: red !important;">*</span></label>
                    <select v-model="unitId" class="full" id="product-unit"  v-if="units.length > 0">
                        <option v-for="unit in units" v-bind:Key="unit.id" :value="unit.id">
                            {{ unit.unit }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Unit Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchUnits">Fetch Units</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="sub-unit">Sub-Unit <span style="color: red !important;">*</span></label>
                    <select v-model="subUnitId" class="full" id="sub-unit" >
                        <option value="0" v-if="subUnits?.length == 0">None</option>
                        <option v-for="subunit in subUnits" v-bind:Key="subunit.id" :value="subunit.id">
                            {{ subunit.unit }}
                        </option>
                    </select>
                  </div>
                </div>
  
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-weight">Item Weight</label>
                    <input type="number" min="0" class="form-control" id="item-weight" placeholder="Enter Kgs" v-model="itemWeight">
                  </div>
                </div>
  
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="storage-dimension">Storage Dimension</label>
                    <input type="text" class="form-control" id="storage-dimension" placeholder="Enter Cubic Mtrs" v-model="storageDimension">
                  </div>
                </div>
  
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="hs-code">HS Code</label>
                    <input type="text" class="form-control" id="hs-code" placeholder="Enter Code" v-model="hsCode">
                  </div>
                </div>
                
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="shelfLife">Shelf Life</label>
                    <input type="number" min="0" class="form-control" id="shelfLife" placeholder="Enter No. of Days" v-model="shelfLife">
                  </div>
                </div>
  
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="max-disc">Tax Rate (%)</label>
                    <input type="number" min="0" max="100" class="form-control" id="max-disc" placeholder="Enter Discount" v-model="maxDisc">
                  </div>
                </div>
  
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="barcode-serial">Barcode/Serial No</label>
                    <input type="text" class="form-control" id="barcode-serial" placeholder="Enter barcode"  v-model="barcode">
                  </div>
                </div>
  
                
                <div class="col-md-3">
                  <div class="check-button">
                    <div class="form-group">
                      <label>Incl. components</label>
                      <div class="components-button">
                        <span class="switcher switcher-1">
                          <input type="checkbox" id="switcher-1" v-model="inclComponent">
                          <label for="switcher-1"></label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
  
              </div>
  
            </div>
          </div>
          <div class="product-stock-levels">
            <strong>2. Product Stock Levels</strong>
            <div class="form">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="min-stock-level">Min Stock Level</label>
                    <input type="number" min=0 class="form-control" id="min-stock-level" placeholder="Enter quantity" v-model="stockMin">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="max-stock-level">Max Stock Level</label>
                    <input type="number" min="0" class="form-control" id="max-stock-level" placeholder="Enter quantity" v-model="stockMax">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="order-stock-level">Order Stock Level</label>
                    <input type="number" min="0" class="form-control" id="order-stock-level" placeholder="Enter quantity" v-model="stockOrder">
                  </div>
                </div>
                <div class="col-md-2">
                  <button class="btn-style-2" style="margin-top:31px" @click="updateProduct"> Update</button>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </section>
  
      <div>
        <button class="btn btn-default" style="float: right;margin-bottom: 30px; margin-right: 15px; border: 1px solid #000;" @click="this.$router.back()">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  
  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../../components/Header';
  import axios from 'axios'
  
  export default {
    name: 'EditProductPage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        productId:"",
        product:"",
        name: "",
        code: "",
        pricePurchase: 0,
        categoryId: 0,
        subCategoryId: 0,
        subcategories: [],
        priceRetail: 0,
        priceWholesale: 0,
        alternativeName:"",
        unitId:"",
        subUnits: [],
        shelfLife:0,
        subUnitId:"",
        itemWeight:0,
        storageDimension:0,
        hscode:"",
        maxDisc:0,
        barcode:"",
        inclComponent:false,
        companyAsset:false,
        stockMin:0,
        stockMax:0,
        stockOrder:0,
        assetNo:"",
        picture:"",
        safety:"",
    }),
    computed:{
      ... mapGetters (["getUnits", "getAllUnits", "getCategories"]),
      units () { return this.getUnits },
      allUnits () { return this.getAllUnits },
      categories () { return this.getCategories }
    },
    methods:{
        ... mapActions(['fetchUnits','fetchAllUnits', 'fetchCategories']),
        async updateProduct () {
            let formaData = new FormData()
            formaData.append("productId", this.productId)
            formaData.append("name", this.name)
            formaData.append("code", this.code)
            formaData.append("pricePurchase",this.pricePurchase)
            formaData.append("categoryId",this.categoryId)
            formaData.append("subCategoryId",this.subCategoryId)
            formaData.append("priceRetail",this.priceRetail)
            formaData.append("priceWholesale",this.priceWholesale)
            formaData.append("alternativeName", this.alternativeName)
            formaData.append("unitId",this.unitId)
            formaData.append("subUnitId",this.subUnitId)
            formaData.append("weightMeasurement",this.itemWeight)
            formaData.append("dimensions",this.storageDimension)
            formaData.append("hscode", this.hscode)
            formaData.append("shelfLife", this.shelfLife)
            formaData.append("maxDiscount", this.maxDisc)
            formaData.append("components",this.inclComponent)
            formaData.append("companyAsset",this.companyAsset)
            formaData.append("barcode", this.barcode)
            formaData.append("stockMin",this.stockMin)
            formaData.append("stockMax",this.stockMax)
            formaData.append("stockOrder",this.stockOrder)
            formaData.append("assetNo", this.assetNo)
            formaData.append("picture", this.picture)
            formaData.append("safety", this.safety)
            axios.put(process.env.VUE_APP_URL+'/products', 
            formaData, 
            {
              headers:{ 
                "Content-Type": "multipart/form-data", 
                'Authorization': 'Bearer '+this.token 
              }}).then(function(data) {
              if(data.data.status == true){
                  Swal.fire({
                      icon: 'success',
                      title: 'Product updated',
                      text: data.data.message
                  })
                  window.location.reload()
              }else{
                  Swal.fire({
                      icon: 'error',
                      title: 'Product not updated',
                      text: data.data.message
                  })
              }
            }.bind(this))
        },
        isSubUnit(element) {
          return element.unitId == this.unitId;
        },
        isSubCategory(element) {
          return element.id == this.categoryId;
        },
        handleFileChange() {
          this.picture = this.$refs.filePicture.files[0];
          this.safety = this.$refs.fileSafety.files[0];
        },
        async setupProduct(itemId){
          const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/products/'+itemId, requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.product = data.product;
              this.productId= data.product.id;
              
            this.name = data.product.name;
            this.code = data.product.code;
            this.pricePurchase = data.product.pricePurchase;
            this.categoryId = data.product.categoryId;
            this.subCategoryId = data.product.subCategoryId;
            this.priceRetail = data.product.priceRetail;
            this.priceWholesale = data.product.priceWholesale;
            this.alternativeName =data.product.alternativeName;
            this.unitId =data.product.unitId;
            this.shelfLife =data.product.shelfLife,
            this.subUnitId =data.product.subUnitId;
            this.itemWeight =data.product.weightMeasurement;
            this.storageDimension =data.product.dimensions;
            this.hscode =data.product.hscode;
            this.tax =data.product.tax;
            this.maxDisc =data.product.maxDiscount,
            this.barcode =data.product.barcode;
            this.inclComponent = this.formatBoolean(data.product.components);
            this.companyAsset = this.formatBoolean(data.product.companyAsset);
            this.stockMin =data.product.stockMin;
            this.stockMax =data.product.stockMax;
            this.stockOrder =data.product.stockOrder;
            this.assetNo =data.product.assetNo;
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Product not found. Try again',
                  text: data.message
              })
          }
        },
        formatBoolean(value){
          if(value){
            return true
          } else{
            return false
          }
        },
        
    },
    mounted(){
        this.fetchUnits()
        this.fetchAllUnits()
        this.fetchCategories()
        
        this.productId = this.$route.params.id
        this.setupProduct(this.productId)
    },
    watch: {
      unitId() {
        this.subUnits = []
        this.subUnits = this.allUnits.filter(this.isSubUnit);
      },
      categoryId() {
        this.subcategories = []
        const category = this.categories.filter(this.isSubCategory);
        this.subcategories = category[0].subcategories;
      },
    }
  }
</script>


<style lang="scss" scoped>
input[readonly]{
  background-color: #cfcece !important;
}

$primary: #d2d2d2 !important;
$green: #4448B3 !important;
$white: #ffffff !important;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                    height: 30px !important;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                    height: 30px !important;
                  }
                }
              }
            }
          }
        }
      }
  </style>