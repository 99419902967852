<template>
<div>
    <PdfModal @close="closeModal">
        <template v-slot:header>
            <h5 class="fw-bold">PDF Preview</h5>
        </template>

        <template v-slot:body>
            <div class="pdf" id="pdfStart" style="margin: 5px;">
                <div class="row">
                    <div class="col-md-7">
                        <h4>Incomplete Delivery Note</h4>
                    </div>
                    <div class="col-md-5">
                        <p style="float:right; border: 1px solid #000;">
                            <span style="background-color:#C1D4EB; color: #000;font-size: 17px;padding:2px ; font-weight: bold;">IDN Status</span>
                            <span style="color: #000; margin-left: 5px;"> {{ note.status }}</span></p>
                    </div>
        
                    <div class="col-md-12">
                        <h4 class="text-15">{{ note.idnNo }} - {{ batch?.batchNo }}</h4>
                    </div>
                    
                    <div class="col-md-7">
                        <div style="border: 1px solid #000; width: 400px;">
                            <p style="background-color:#C1D4EB;padding: 10px; margin:0px; font-weight: bold !important;">Delivery/Courier Details From</p>
                            <p v-if="batch?.isInternal == false"  style="margin: 1px 10px; font-weight: bold;">{{ batch.Supplier?.name }}</p>
                            <p v-if="batch?.isInternal == false"  style="margin: 1px 10px;">{{ batch.supplierDriver }}</p>
                            <p v-if="batch?.isInternal == false"  style="margin: 1px 10px;">{{ batch.supplierContact }}</p>
                            <p v-if="batch?.isInternal == false"  style="margin: 1px 10px;">{{ batch.supplierVehicle }}</p>
                            <p v-if="batch?.isInternal == true">Internal Delivery</p>
                        </div>
        
                    </div>
                    <div class="col-md-2">
                    </div>
        
                    <div class="col-md-3 qr">
                        <canvas ref="qrcodeCanvas"></canvas>
                    </div>
        
                    <div class="col-md-5"></div>
                    <div class="col-md-7" id="companyDetails">
                        <h4 class="mt-2">{{ profile.businessName }}</h4>
                        <h4 style="font-size:smaller !important;">{{ profile.businessAddress }}</h4>
                    </div>
                </div>
        
                <div class="row" style="border: 1px solid #000;">
                    <div class="row" style="background-color:#C1D4EB;">
                        <div class="col-md-6" style="padding: 10px; border-right: 1px solid #f2f2f2;">Stock Description</div>
                        <div class="col-md-6" style="padding: 10px;">Consignment Details</div>
                    </div>
                    <div class="row" style="margin-top: 0px !important; padding-right: 0px !important;">
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;border-left: 1px solid #808080;padding: 5px;">Stock Category</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">BPN Tags</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Project</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Purchase Order No.</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Reference 1 No.</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Reference 2 No.</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch?.Nog?.nog }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch?.Tag?.tag }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch?.project }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-if="batch?.Purchase">{{  batch.Purchase?.purchaseNo }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-else>N/A</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch?.reference1 }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch?.reference2 }}</div>
                    </div>
                </div>
        
                <div class="row" style="border: 1px solid #000;">
                    <p style="background-color:#C1D4EB;padding: 10px; margin:0px; font-weight: bold !important;">Stock Data Input Details</p>
                    <div class="row" style="margin-top: 0px !important; padding-right: 0px !important;">
                        <div class="col-md-4" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;border-left: 1px solid #808080;padding: 5px;">Data Entry by:</div>
                        <div class="col-md-4" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Date & Time.</div>
                        <div class="col-md-4" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Stock Verified By:</div>
        
                        <div class="col-md-4" style="border-right: 1px solid #808080;padding: 5px;">{{  batch?.User?.name }}</div>
                        <div class="col-md-4" style="border-right: 1px solid #808080;padding: 5px;">{{  formatDate(batch?.createdAt,"true") }}</div>
                        <div class="col-md-4" style="border-right: 1px solid #808080;padding: 5px;" v-if="verifiedBy">{{ verifiedBy?.name }}</div>
                        <div class="col-md-4" style="border-right: 1px solid #808080;padding: 5px;" v-else>-</div>
                    </div>
                </div>
        
                <div class="row" style="border: 1px solid #000;">
                    <p style="background-color:#C1D4EB;padding: 10px; margin:0px; font-weight: bold !important;">Missing Items / Quantities Description</p>
                    <table class="table table-bordered">
                        <tbody v-if="note?.items?.length > 0">
                            <tr>
                                <th>No.</th>
                                <th>Product</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>Comments</th>
                            </tr>
                            <tr v-for="(item, index) in note?.items" v-bind:key="item.id">
                                <td>{{ (index+1) }}</td>
                                <td>{{ item.Product.name }}</td>
                                <td>{{ item.Product.unit?.unit }}</td>
                                <td>{{ item.quantity }}</td>
                                <td>{{ item.comment }}</td>
                            </tr>
                        </tbody>
                        <div v-else>
                            <label for="" class="alert alert-danger" style="margin: 10px auto;  display: block;">No items found</label>
                        </div>
                    </table>
                </div>
                <div class="row" style="border: 1px solid #000;">
                    <p style="background-color:#C1D4EB;padding: 10px; margin:0px; font-weight: bold !important;">IDN Comments</p>
                    <p style="margin-left: 0px;">{{ note.notes }}</p>
                </div>
                <div class="row" style="border: 1px solid #000;">
                    <p style="background-color:#C1D4EB;padding: 10px; margin:0px; font-weight: bold !important;">IDN Settlement Comments</p>
                    <p style="margin-left: 0px;">{{ note.settleComment }}</p>
                </div>
                <div class="row" id="footer">
                    <div class="col-md-5">
                        <p><span style="font-weight: bold;">Note:</span> Printed document is uncontrolled</p>
                    </div>
                    <div class="col-md-4">
                        <p><span style="font-weight: bold;">Printed On:</span> {{ formatDate(new Date(),"true") }}</p>
                    </div>
                    <div class="col-md-3">
                        <p><span style="font-weight: bold;">By:</span> {{ user.name }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <img v-if="note.User?.signature" :src="`${urlServer}/files/${note.User?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                        <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">IDN Generated By;</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ note.User?.name }}</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ note.User?.id }}</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(note.createdAt,"true") }}</p>
                    </div>
                    <div class="col-md-4" v-if="verifiedBy">
                        <img v-if="verifiedBy?.signature" :src="`${urlServer}/files/${verifiedBy?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                        <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">Stock Verified By;</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ verifiedBy?.name }}</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ verifiedBy?.id }}</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(note.updatedAt,"true") }}</p>
                    </div>
                    <div class="col-md-4" v-if="settledBy">
                        <img v-if="settledBy?.signature" :src="`${urlServer}/files/${settledBy?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                        <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">IDN Settled By;</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ settledBy?.name }}</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ settledBy?.id }}</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(note.updatedAt,"true") }}</p>
                    </div>
                </div>
                <div style="margin-top: 90px !important;">
                    <img  v-if="profile.businessLogo" :src="profile.businessLogo" style="height: 60px; width: 250px;"/>
                    <img v-else src="../../../assets/images/enterpriseOne.png" style="height: 60px; width: 250px;"/>
                </div>
            </div>
        </template>

        <template v-slot:footer>
            <button class="btn-style small" style="border: 1px solid black; float: right;" @click="downloadPDF">Download Pdf</button>
            <button class="btn-style small" style="border: 1px solid black; float: right;" @click="copyToClipboard">Copy Pdf Link</button>
            <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 20px;" @click="$router.back()">Cancel</button>
        </template>
    </PdfModal>

    
</div>
  </template>
  
<script>
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import QRCode from 'qrcode-generator';
import PdfModal from "@/components/PdfModal.vue";
import axios from "axios";

export default {
    name: 'NotePdf',
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        noteId: "",
        note:"",
        user:"",
        batch:"",
        verifiedBy:"",
        settledBy:"",
        guardian:"",
        driver:"",
        updateCount:1,
        spinnerColor:'#ff1d5e', 
        profile: {},
        pdfLink: ""
    }),

    components: {
        PdfModal
    },

    mounted(){
        this.noteId = this.$route.params.id
        this.setupNote(this.noteId)
        this.setupUser()
       // this.generateQRCode()
       // setTimeout(()=> { this.exportToPDF() }, 3000)
        this.profile = JSON.parse(localStorage.getItem('profile'))
    },
    methods: {
        async exportToPDF() {
            this.generatePdf()
            this.$router.back()
        },

        downloadPDF() {
            html2pdf(document.getElementById("pdfStart"), { 
                margin: 0.2, 
                filename: new Date().getTime()+"_incomplete_delivery_note.pdf", 
                image:{ type: 'jpeg', quality: 0.98 },
                html2canvas:{ scale: 2, useCORS: true },
                jsPDF:{ unit: 'in',format: 'a4', orientation: 'portrait' }
            });
        },

        async copyToClipboard(){
            try {
                await navigator.clipboard.writeText(this.pdfLink);
                alert('Pdf link copied')
            } catch (err) {
                console.error('Failed to copy text to clipboard', err);
            }

        },

        async generatePdf(){
            try{
                Swal.fire({
                    html: "Generating pdf",
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                    }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log("I was closed by the timer");
                    }
                    });
                await axios.get(`${process.env.VUE_APP_URL}/pdf/idn/${this.noteId}`)
            }catch(err){
                console.log(err)
            }finally{
                console.log("finally")
            } 
        },


        closeModal(){
            this.$router.back()
        },

        formatBoolean(value){
            if(value){
            return "YES"
            } else{
            return "NO"
            }
        },

        generateQRCode(link) {
            const qr = QRCode(0, 'M');
            qr.addData(link);
            qr.make();
            const canvas = this.$refs.qrcodeCanvas;
            const ctx = canvas.getContext('2d');
            const cellSize = 6;

            canvas.width = qr.getModuleCount() * cellSize;
            canvas.height = qr.getModuleCount() * cellSize;

            for (let row = 0; row < qr.getModuleCount(); row++) {
                for (let col = 0; col < qr.getModuleCount(); col++) {
                    ctx.fillStyle = qr.isDark(row, col) ? "#000000" : "#ffffff";
                    ctx.fillRect(col * cellSize, row * cellSize, cellSize, cellSize);
                }
            }
        },

        async setupNote(itemId){
            try {
                const requestOptions = {
                    method: "GET",
                    headers: { 
                        "Content-Type": "application/json", 
                        'Authorization': 'Bearer ' + this.token 
                    },
                };
                
                const res = await fetch(`${process.env.VUE_APP_URL}/incompletenotes/${itemId}`, requestOptions);
                const data = await res.json();
                
                if (data.status === true) {
                    this.noteId = data.note.id;
                    this.note = data.note;
                    this.batch = data.note.Batch[0];
                    this.verifiedBy = data.verifiedBy;
                    this.guardian = data.guardian;
                    this.driver = data.driver;
                    this.settledBy = data.settledBy;
                    this.pdfLink = `${process.env.VUE_APP_URL}/pdf/idn/${this.noteId}`
                    this.generateQRCode(this.pdfLink)
                    //this.pdfLink = this.shortenUrl(`${process.env.VUE_FRONT_URL}/transaction/incompletenotes/pdf/${this.purchaseId}`)
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Incomplete Delivery Note not found. Try again',
                        text: 'Failed to fetch data. Please try again later.'
                    });
            }
            } catch (error) {
                // Handle fetch or JSON parsing errors
                console.error('Error fetching data:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to fetch data. Please try again later.'
                });
            }
        },
        async setupUser(){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/profile', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.user = data.user;
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Incomplete Delivery Note not found. Try again',
                    text: data.message
                })
            }
        },

    }
}
</script>

<style scoped>
p, span, h3, h4{
    color: #000 !important;
}
h3{
    font-size: 33px !important;
}
h4{
    font-weight: bold !important;
}
#companyDetails h4{
    text-align: right;
    font-size: 19px;
}
#footer p{
    font-size: 12px !important;
}
.row{
    margin-top: 20px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #002060;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
