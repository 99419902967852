<script>
  export default {
    name: 'ModalVue',
    methods: {
      close() {
        this.$emit('close');
      },
      updateHeight() {
        this.componentHeight = this.$refs.component.offsetHeight;
      }
    }, 
    
    data() {
      return {
        componentHeight: 0
      };
    },
    computed: {
      calculatedMargin() {
        return `calc(50vh - ${this.componentHeight / 2}px)`;
      }
    },
    mounted() {
      this.componentHeight = this.$refs.component.offsetHeight;
      window.addEventListener("resize", this.updateHeight);
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.updateHeight);
    },

  };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        ref="component"  
      >
        <header
          class="modal-headerr"
          id="modalTitle"
        >
          <slot name="header">
            This is the default tile!
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
          </button>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            This is the default body!
          </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer">
            This is the default footer!
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.modal-backdrop {
  position: absolute;
  height: 100vh;
  height: 8000px !important;
  width: 100vw !important;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 1000; 
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow-y: auto;
}
.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px rgba(0, 0, 0, 0.5);
  display: flex !important;
  flex-direction: column;
  width: 80% !important;
  max-width: 600px;
  height: auto !important;
  padding: 20px !important;
  z-index: 1001; 
  margin-left: calc(50vw - 300px) !important;
  margin-top: 10% !important;
}


  .modal-header {
    padding: 15px;
    display: flex;
    gap: 10px;
  }

  .modal-headerr {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #002060;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #002060;
    border: 1px solid #002060;
    border-radius: 2px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>