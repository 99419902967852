<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  meta:{
    title: 'Default Title',
    titleTemplate: '%s | My Awesome Webapp'
  }
  
}
</script>

<style>

</style>
