<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">  
  
          <h3>Pending Approval Requests</h3>
          <div>
            <table class="table table-hover" v-if="approvals?.length > 0">
                <thead>
                    <th>No.</th>
                    <th>Date</th>
                    <th>Priority</th>
                    <th>Request</th>
                    <th>Action</th>
                </thead>
                <tbody>
                    <tr v-for="(approval, index) in approvals" v-bind:key="approval.id">
                        <td>{{ index+1 }}</td>
                        <td>{{  formatDate(approval.createdAt,"true") }}</td>
                        <td>
                            <button v-if="approval.priority == 'urgent'" class="btn btn-sm btn-danger">Urgent</button>
                            <button v-else-if="approval.priority == 'high'" class="btn btn-sm btn-primary">High</button>
                            <button v-else class="btn btn-sm btn-info">Normal</button>
                        </td>
                        <td v-if="approval.module == 'purchase'">Purchase Order</td>
                        <td v-else-if="approval.module == 'pickup'">Pickup Note</td>
                        <td v-else-if="approval.module == 'dispatch'">Dispatch Note</td>
                        <td v-else>{{ approval.module }}</td>
                        <td>
                          <button class="btn btn-default btn-sm" style="border: 1px solid #000" @click="showModal(approval)">View</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-else>
                <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No pending approval request</label>
            </div>
          </div>

        </div>
      </section>

<Modal v-if="isModalVisible" @close="closeModal" >
  <template v-slot:header>
    <h3>Request Details</h3>
    <h4>{{ modalTitle }}</h4> 
  </template>

  <template v-slot:body><div ref="modalBody" v-html="getModalBody(modalApproval)">
    
  </div></template>

  <template v-slot:footer>
    <button class="btn btn-default" style="border: 1px solid #000" @click="closeModal"> Close</button>
  </template>
</Modal>

    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex'
  import Header from '../../components/Header';
  import Modal from '../../components/Modal.vue'
  import { isProxy, toRaw } from 'vue';
  
  export default {
    name: 'ApprovalHome',
    components: {
      Header,
      Modal,
    },
    data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      isModalVisible: false,
      modalApproval: "",
      modalTitle:"pending ...",
    }),
    computed:{
        ... mapGetters (["getApprovalsPending"]),
        approvals () { return this.getApprovalsPending },
    },
    methods:{
      ... mapActions(['fetchApprovalsPending']),
      showModal(obj) {
        this.modalApproval = obj;
        this.isModalVisible = true;
        this.modalTitle = this.getModaltitle(this.modalApproval.module)
        //this.$refs.modalBody.innerHTML = this.getModalBody(this.modalApproval);
      },
      getModaltitle(value) {
        switch (value){
          case "purchase": return "Purchase Order"
          case "StockReleased" : return "Release Reserved Stock"
          case "StockAdjustment" : return "Stock Adjustment"
          case "StockTransfer" : return "Stock Transfer"
          default: return ""
        }
      },
      getModalBody(data) {
        let approval=""
        if (isProxy(data)){
          approval = toRaw(data)
        } else { approval = data }
        switch (approval.module){
          case "purchase": return "<table class='table table-bordered'><tr><th>Status</th><td>"+approval.type+"</td></tr><tr><th>Purchase Order Number</th><td>"+approval.purchase?.purchaseNo
            +"</td></tr><tr><th>Department</th><td>"+approval.purchase?.department+"</td></tr><tr><th>Project</th><td>"+approval.purchase?.project
            +"</td></tr><tr><th>Supplier</th><td>"+approval.purchase?.Supplier?.name+" - "+approval.purchase?.Supplier?.phone
            +"</td></tr><tr><th>Purchase Order Created By</th><td>"+approval.purchase?.User?.name+"</td></tr></table><a class='btn btn-sm btn-primary' href=\"/purchases/pdf/"+
            approval.purchase.id+"\">View Purchase Order</a>"

          case "StockReleased" : return "<table class='table table-bordered'><tr><th>Priority</th><td>"+approval.priority+"</td></tr><tr><th>Status</th><td>"+
            approval.type+"</td></tr><tr><th>Product</th><td>"+approval.stockReserved?.Product?.name+" - "+approval.stockReserved?.Product?.code
              +"</td></tr><tr><th>Unit</th><td>"+approval.stockReserved?.Product?.unit.unit
            +"</td></tr><tr><th>Quantity Requested</th><td>"+approval.stockReserved?.quantityReleased
            +"</td></tr><tr><th>Comment</th><td>"+approval.stockReserved?.comment
            +"</td></tr><tr><th>Quantity Reserved</th><td>"+approval.stockReserved?.balance
            +"</td></tr><tr><th>Warehouse</th><td>"+approval.stockReserved?.Inventory?.Warehouse?.name +" - "+approval.stockReserved?.Inventory?.Warehouse?.description
            +"</td></tr><tr><th>Quantity Available</th><td>"+approval.stockReserved?.Inventory.quantity
              +"</td></tr><tr><th>Requested By</th><td>"+approval.stockReserved?.User?.name+"</td></tr></table>"

          case "StockAdjustment" : return "<table class='table table-bordered'><tr><th>Priority</th><td>"+approval.priority+"</td></tr><tr><th>Status</th><td>"+
            approval.type+"</td></tr><tr><th>Product</th><td>"+approval.stockAdjustment?.Product?.name+" - "+approval.stockAdjustment?.Product?.code
              +"</td></tr><tr><th>Unit</th><td>"+approval.stockAdjustment?.Product?.unit.unit
            +"</td></tr><tr><th>Type of Adjustment</th><td>"+approval.stockAdjustment?.type
            +"</td></tr><tr><th>Comment</th><td>"+approval.stockAdjustment?.comment
            +"</td></tr><tr><th>Current Quantity</th><td>"+approval.stockAdjustment?.oldQuantity
            +"</td></tr><tr><th>Quantity Change</th><td>"+approval.stockAdjustment?.quantity
            +"</td></tr><tr><th>New Quantity</th><td>"+approval.stockAdjustment?.newQuantity
            +"</td></tr><tr><th>Warehouse</th><td>"+approval.stockAdjustment?.Inventory?.Warehouse?.name +" - "+approval.stockAdjustment?.Inventory?.Warehouse?.description
            +"</td></tr><tr><th>Requested By</th><td>"+approval.stockAdjustment?.User?.name+"</td></tr></table>"

          case "StockTransfer" : return "<table class='table table-bordered'><tr><th>Priority</th><td>"+approval.priority+"</td></tr><tr><th>Status</th><td>"+
            approval.type+"</td></tr><tr><th>Product</th><td>"+approval.stockTransfer?.Product?.name+" - "+approval.stockTransfer?.Product?.code
              +"</td></tr><tr><th>Unit</th><td>"+approval.stockTransfer?.Product?.unit.unit
            +"</td></tr><tr><th>Quantity</th><td>"+approval.stockTransfer?.quantity
            +"</td></tr><tr><th>Comment</th><td>"+approval.stockTransfer?.comment
            +"</td></tr><tr><th>Old Warehouse</th><td>"+approval.stockTransfer?.Inventory?.Warehouse?.name +" - "+approval.stockTransfer?.Inventory?.Warehouse?.description
            +"</td></tr><tr><th>Quantity Available</th><td>"+approval.stockTransfer?.Inventory.quantity+"</td></tr><tr><th>New Warehouse</th><td>"+
              approval.stockTransfer?.Warehouse?.name+" - "+approval.stockTransfer?.Warehouse?.description+"</td></tr><tr><th>Requested By</th><td>"+
                approval.stockTransfer?.User?.name+"</td></tr></table>"

          default: return "Details not found. Refresh the page and try again"
        }
      },
      closeModal() {
        this.isModalVisible = false;
      },
    },
    mounted(){
      this.fetchApprovalsPending()
    }
  }
  </script>

  <style scoped>
  .purchases-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }
  </style>