<script>
  export default {
    name: 'CreditModalVue',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-headerr"
          id="modalTitle"
        >
          <slot name="header">
            This is the default tile!
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
          </button>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            This is the default body!
          </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer">
            This is the default footer!
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style>
.modal-backdrop {
  position: absolute;
  height: 100vh;
  width: 100vw !important;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 1000; 
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;


}
.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px rgba(0, 0, 0, 0.5);
  display: flex !important;
  flex-direction: column;
  width: 80% !important;
  max-width: 1000px;
  height: auto !important;
  padding: 20px !important;
  z-index: 1001; 
  margin-left: calc(50vw - 500px) !important;
  margin-top: calc(50vh - 200px) !important;
}


  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-headerr {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #002060;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #002060;
    border: 1px solid #002060;
    border-radius: 2px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }




  .inner-addon .fas, .inner-addon .far {
    position: absolute;
    padding: 10px;
    pointer-events: none;
    color: #bcbdbd !important;
  }
  .right-addon .fas, .right-addon .far { right: 0px; top: 40px;}
  .right-addon input { padding-right: 30px; }
  
  .credit-card .card-details label{
    font-family: 'Abhaya Libre', serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
    color: #79818a;
    text-transform: uppercase;
  }
  
  .credit-card .card-details input[type="text"] {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 10px 10px 5px;
    -webkit-appearance: none;
    display: block;
    background: #fafafa;
    color: #636363;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #757575;	
  }
  .credit-card .card-details input[type="text"]:focus { outline: none; }
  
  .credit-card .card-details button{
    margin-top: 0.6em;
    padding:12px 0;
    font-weight: 600;
  }
  
  .credit-card .date-separator{
     margin-left: 10px;
      margin-right: 10px;
      margin-top: 5px;
  }
  
  @media (max-width: 768px) {
    .credit-card{
      height: 250vh;
      width: 100%;
    }
    .credit-card .title {
      font-size: 1.2em; 
    }
  
    .credit-card .row .col-lg-6 {
      margin-bottom: 40px; 
      }
      .credit-card .card-details {
        padding: 40px 40px 30px; 
      }
  
      .credit-card .card-details button {
        margin-top: 2em; 
      } 
  }
</style>