<template>
    <div>
    <!--
    <div style="position: absolute; width: 100%; height: 2000px; z-index:1;background-color: #f2f2f2;">
        <h3 style="margin-top: 100px; text-align: center;padding: 40px;">Wait as the PDF is being generated</h3>
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>-->


    <PdfModal @close="closeModal">
        <template v-slot:header>
        <h5 class="fw-bold">PDF Preview</h5>
        </template>
    
        <template v-slot:body>
            <div class="pdf" id="pdfStart"  ref="pdfStart" style="margin: 5px;">
            <div class="row">
                <div class="col-md-7">
                    <h4>Pickup Note</h4>
                </div>
                <div class="col-md-5">
                    <p style="float:right; border: 1px solid #000;">
                        <span style="background-color:#C1D4EB; color: #000;font-size: 17px;padding:2px ;">PN Status</span>
                        <span style="color: #000; margin-left: 5px;"> {{ pickup.status }}</span></p>
                </div>

                <div class="col-md-12">
                    <h4 style="font-size: 15px !important">{{ pickup.pickupNo }}</h4>
                </div>
                
                <div class="col-md-7">
                    <div style="border: 1px solid #000; width: 400px;">
                        <p style="background-color:#C1D4EB;padding: 10px; margin:0px;">For (Assignee)</p>
                        <p style="margin: 1px 10px;">{{ pickup.assigneeName }}</p>
                        <p style="margin: 1px 10px;">{{ pickup.assigneeCompany }}</p>
                        <p style="margin: 1px 10px;">{{ pickup.assigneePhone }}</p>
                        <p style="margin: 1px 10px;">{{ pickup.assigneeEmail }}</p>
                    </div>
                </div>
                <div class="col-md-2">
                    
                </div>
                <div class="col-md-3 qr">
                    <canvas ref="qrcodeCanvas"></canvas>
                </div>
                
                <div class="col-md-5">
                    <p style="font-size: 13px !important;">Note: This document must be presented upon request at the gate or any relevant person in the warehouse or HQ compound.</p>
                </div>
                <div class="col-md-7" id="companyDetails">
                    <h4 class="mt-2">{{ profile?.businessName }}</h4>
                    <h4 style="font-size:smaller !important;">{{ profile?.businessAddress }}</h4>
                </div>
            </div>

            <div class="row" style="border: 1px solid #000;">
                <table class="table table-bordered" style="margin:0px;">
                    <thead style="">
                        <th style="padding: 10px; border-right: 1px solid #f2f2f2; font-weight: normal">Requested by:</th>
                        <th style="padding: 10px; font-weight: normal">Dispatched by:</th>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Name</th>
                        </tr>
                        <tr>
                            <td>{{  pickup.User?.name }}</td>
                            <td v-if="dispatchedBy">{{  dispatchedBy?.name }}</td>
                            <td v-else>-</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row">
                <div class="col-md-7">
                    <p style="border: 1px solid #000;">
                        <span style="background-color:#C1D4EB; color: #000;font-size: 17px;padding:2px 10px 2px 2px;">Warehouse</span>
                        <span style="color: #000; margin-left: 10px; font-weight: bold;">{{ pickup.Warehouse?.name }}</span></p>
                </div>
                <div class="col-md-5">
                    <p style="border: 1px solid #000;">
                        <span style="background-color:#C1D4EB; color: #000;font-size: 17px;padding:2px 10px 2px 2px;">Scheduled Pickup</span>
                        <span style="color: #000; margin-left: 10px; font-weight: bold;">{{ formatBoolean(pickup.scheduled) }}</span></p>
                    </div>
                <div class="col-md-7">
                    <p style="border: 1px solid #000;">
                        <span style="background-color:#C1D4EB; color: #000;font-size: 17px;padding:2px 10px 2px 2px;">Pickup Date & Time</span>
                        <span style="color: #000; margin-left: 10px; font-weight: bold;">{{ formatDate(pickup.createdAt,"true") }}</span></p>
                </div>
                <div class="col-md-5">
                    <p style="border: 1px solid #000;">
                        <span style="background-color:#C1D4EB; color: #000;font-size: 17px;padding:2px 10px 2px 2px;">Vehicle Reg No.</span>
                        <span style="color: #000; margin-left: 10px; font-weight: bold;" v-if="pickup.vehicle != ''">{{ pickup.vehicle }}</span>
                        <span style="color: #000; margin-left: 10px;" v-else>Pending</span>
                    </p>
                    </div>
            </div>

            <div class="row">
                <div class="col-md-6" style="border: 1px solid #000; padding:0px;">
                    <p style="background-color:#C1D4EB;padding: 10px; margin:0px;">Purpose</p>
                    <p style="margin-left: 10px;">{{ pickup.Purpose?.purpose }}</p>
                </div>
                <div class="col-md-6" style="border: 1px solid #000;padding: 0px;">
                    <p style="background-color:#C1D4EB;padding: 10px; margin:0px;">Project/Site.</p>
                    <p style="margin-left: 10px;">{{ pickup.project }}</p>
                </div>
            </div>
            <div class="row" style="border: 1px solid #000;">
                <p style="background-color:#C1D4EB; padding: 10px; margin:0px;">Instructions</p>
                <p style="margin-left: 0px;">{{ pickup.instructions }}</p>
            </div>

            <div class="row" style="border: 1px solid #000;">
                <p style="background-color:#C1D4EB; padding: 10px; margin:0px;">Items to collect</p>
                <table class="table table-bordered">
                    <tbody v-if="pickup.items?.length > 0">
                        <tr>
                            <th>No.</th>
                            <th>Material Description</th>
                            <th>Unit</th>
                            <th>Assigned Qty</th>
                            <th>Collected Qty</th>
                            <th>Comments</th>
                        </tr>
                        <tr v-for="(item, index) in pickup.items" v-bind:key="item.id">
                            <td>{{ (index+1) }}</td>
                            <td>{{ item.Product.name }}</td>
                            <td>{{ item.Product.unit?.unit }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.quantityAssigned }}</td>
                            <td>{{ item.collectComment }}</td>
                        </tr>
                    </tbody>
                    <div v-else>
                        <label for="" class="alert alert-danger" style="margin: 10px auto;  display: block;">No items found</label>
                    </div>
                </table>
            </div>
            
            <div class="row" style="border: 1px solid #000;">
                <p style="background-color:#C1D4EB; padding: 10px; margin:0px; font-weight: bold !important;">Pickup Comments</p>
                <p style="margin-left: 0px;">{{ pickup.comment }}</p>
            </div>
            <div class="row" id="footer">
                <div class="col-md-5">
                    <p><span style="font-weight: bold;">Note:</span> Printed document is uncontrolled</p>
                </div>
                <div class="col-md-4">
                    <p><span style="font-weight: bold;">Printed On:</span> {{ formatDate(new Date(),"true") }}</p>
                </div>
                <div class="col-md-3">
                    <p><span style="font-weight: bold;">By:</span> {{ user.name }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <img v-if="pickup.User?.signature" :src="`${urlServer}/files/${pickup.User?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                    <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">Pickup Note By;</p>
                    <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ pickup.User?.name }}</p>
                    <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ pickup.User?.id }}</p>
                    <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(pickup.createdAt,"true") }}</p>
                </div>

                <div class="col-md-4" v-if="pickup.status == 'approved' || dispatchedBy">
                    <img v-if="pickup?.Approval?.approvedBy?.signature" :src="`${urlServer}/files/${pickup?.Approval?.approvedBy?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                    <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">Note Approved By;</p>
                    <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ pickup?.Approval?.approvedBy?.name }}</p>
                    <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ pickup?.Approval?.approvedBy?.id }}</p>
                    <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(pickup?.updatedAt,"true") }}</p>
                </div>


                <div class="col-md-4" v-if="dispatchedBy">
                    <img v-if="dispatchedBy?.signature" :src="`${urlServer}/files/${dispatchedBy?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                    <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">Pickup Note Processed By;</p>
                    <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ dispatchedBy?.name }}</p>
                    <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ dispatchedBy?.id }}</p>
                    <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(pickup.updatedAt,"true") }}</p>
                </div>
            </div>
            <div style="margin-top: 90px !important;">
                <img v-if="profile?.businessLogo" :src="profile?.businessLogo" style="height: 60px; width: 150px;"/>
                <img v-else src="../../../assets/images/enterpriseOne.png" style="height: 60px; width: 250px;"/>
            </div>
            </div>
            </template>
            <template v-slot:footer>
                <button class="btn-style small" style="border: 1px solid black; float: right;" @click="downloadPDF">Download Pdf</button>
                <button class="btn-style small" style="border: 1px solid black; float: right;" @click="copyToClipboard">Copy Pdf Link</button>
                <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 20px;" @click="$router.back()">Cancel</button>
            </template>
    </PdfModal>
    </div>
</template>
  
<script>
import Swal from "sweetalert2";
//import html2pdf from "html2pdf.js";
import QRCode from 'qrcode-generator';
import PdfModal from "@/components/PdfModal.vue";
import axios from 'axios'
import html2pdf from "html2pdf.js";


export default {
    name: 'PickupPdf',
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        pickupNoteId: "",
        pickup:"",
        dispatchedBy:"",
        user:"",
        updateCount:1,
        spinnerColor:'#ff1d5e',
        profile: {},
        pdfLink: ""
    }),

    components: {
        PdfModal
    },

    mounted(){
        this.setupUser()
        this.pickupNoteId = this.$route.params.id
        this.setupPickup(this.pickupNoteId)
        const profileData = localStorage.getItem('profile');
        try {
            this.profile = profileData ? JSON.parse(profileData) : null;
        } catch (error) {
            console.error("Failed to parse profile data:", error);
            this.profile = null; 
        }
      //this.generateQRCode()
    },

    methods: {
        async exportToPDF() {
            this.generatePdf()
            //this.$router.back()
        },

        downloadPDF() {
            html2pdf(document.getElementById("pdfStart"), { 
                margin: 0.2, 
                filename: new Date().getTime()+"_batch_note.pdf", 
                image:{ type: 'jpeg', quality: 0.98 },
                html2canvas:{ scale: 2, useCORS: true },
                jsPDF:{ unit: 'in',format: 'a4', orientation: 'portrait' }
            });
        },


        async generatePdf(){
            try{
                Swal.fire({
                    html: "Generating pdf",
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                    }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log("I was closed by the timer");
                    }
                    });
                const response = await axios.get(`${process.env.VUE_APP_URL}/pdf/pickup/${this.pickupNoteId}`)
                console.log(response)
            }catch(err){
                console.log(err)
            }finally{
                console.log("finally")
            }   
        },
        
        closeModal(){
            this.$router.back()
        },
   
        generateQRCode(link) {
            const qr = QRCode(0, 'M');
            qr.addData(link);
            qr.make();

            const canvas = this.$refs.qrcodeCanvas;
            const ctx = canvas.getContext('2d');
            const cellSize = 6;

            canvas.width = qr.getModuleCount() * cellSize;
            canvas.height = qr.getModuleCount() * cellSize;

            for (let row = 0; row < qr.getModuleCount(); row++) {
                for (let col = 0; col < qr.getModuleCount(); col++) {
                ctx.fillStyle = qr.isDark(row, col) ? "#000000" : "#ffffff";
                ctx.fillRect(col * cellSize, row * cellSize, cellSize, cellSize);
                }
            }
        },

       
        formatBoolean(value){
            if(value){
            return "YES"
            } else{
            return "NO"
            }
        },

        async setupPickup(itemId){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/pickups/'+itemId, requestOptions)
            const data = await res.json()

            console.log("Data", data)
            if(data.status == true){
                this.dispatchedBy = data.dispatchedBy;
                this.pickup = data.pickup;
                this.pdfLink = `${process.env.VUE_APP_URL}/pdf/pickup/${this.pickupNoteId}`
                this.generateQRCode(this.pdfLink)
                //this.pdfLink = this.shortenUrl(`${process.env.VUE_FRONT_URL}/orders/pickup/pdf/${this.pickupNoteId}`)
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Pickup Note not found. Try again',
                    text: data.message
                })
            }
        },

        async copyToClipboard(){
            try {
                await navigator.clipboard.writeText(this.pdfLink);
                alert('Pdf link copied')
            } catch (err) {
                console.error('Failed to copy text to clipboard', err);
            }
        },

        async setupUser(){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/profile', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.user = data.user;
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Pickup Note not found. Try again',
                    text: data.message
                })
            }
        },

    }
}
</script>

<style scoped>
p, span, h3, h4, tr, th, td{
    color: #000 !important;
}
h3{
    font-size: 33px !important;
}
h4{
    font-weight: bold !important;
}
#companyDetails h4{
    text-align: right;
    font-size: 19px;
}
#footer p{
    font-size: 12px !important;
}
.row{
    margin-top: 20px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #002060;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
