<template>
		<section class="credit-card">
      <div class="container">
       
       <div class="card-holder">
         <div class="card-box bg-news">
            <div class="row">
         <div class="col-lg-6">
          <div class="img-box">
          </div>
         </div>
         <div class="col-lg-6">
          <form>
            <div class="card-details">
            <div class="row">
              <div class="form-group col-sm-7">
                <div class="inner-addon right-addon">
              <label for="card-holder">Card Holder</label>
              <input id="card-holder" type="text" class="form-control" v-model="cardHolderName" placeholder="Card Holder" aria-label="Card Holder" aria-describedby="basic-addon1">
                </div>	
              </div>
              <div class="form-group col-sm-5">
              <label for="">Expiration Date</label>
              <div class="input-group expiration-date">
                <input type="text" class="form-control" placeholder="MM" aria-label="MM"  v-model="mm"  aria-describedby="basic-addon1">
                <input type="text" class="form-control" placeholder="YY" aria-label="YY" v-model="yy" aria-describedby="basic-addon1">
              </div>
              </div>
              <div class="form-group col-sm-8">
                <div class="inner-addon right-addon">
              <label for="card-number">Card Number</label>
              <input id="card-number" type="text" class="form-control" placeholder="Card Number"  v-model="cardNumber" aria-label="Card Holder" aria-describedby="basic-addon1">
                </div>	
              </div>
              <div class="form-group col-sm-4">
              <label for="cvc">CVC</label>
              <input id="cvc" type="text" class="form-control" placeholder="CVC" aria-label="Card Holder" v-model="cvc" aria-describedby="basic-addon1">
              </div>
              <div class="form-group col-sm-12">
              <button type="button" class="btn btn-primary btn-block" style="width:100%" @click="postCreditCard">
                <img  src="../assets/images/loader.gif" v-if="loading"/> <span v-if="!loading" style="color: white">Add</span></button>
              </div>
            </div>
            </div>
          </form>				
         </div> 
            </div>
         </div>
       </div>
      </div>
     </section>
</template>


<script>
import Swal from 'sweetalert2'
import axios from 'axios'


export default {
  name: 'CreditCard',
  data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      urlServer:process.env.VUE_APP_URL,
      loading: false,
      cardHolderName: '',
      expirationDate: '',
      cvc: '',
      cardNumber: '',
      mm: '',
      yy: ''
  }),
  
  methods:{
      async postCreditCard() {
          this.loading = true
         // let formaData = new FormData()
          const formData = {
            cardHolderName: this.cardHolderName,
            expirationDate: `${this.mm}/${this.yy}`,
            cvc: this.cvc,
            cardNumber: this.cardNumber
          }

          /*
          formaData.append("cardHolderName", this.cardHolderName)
          formaData.append("expirationDate", `${this.mm}/${this.yy}`)
          formaData.append("cvc",this.cvc)
          formaData.append("cardNumber",this.categoryId)*/
          
           axios.post(process.env.VUE_APP_URL+'/creditcards', 
          formData, 
          {
            headers:{ 
              'Authorization': 'Bearer '+this.token 
            }}).then(function(data) {
            console.log(data)
            if(data.data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Payment Details Added',
                    text: data.data.message
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Card not added',
                    text: data.data.message
                })
            }
            
          })
          this.loading = false
      },      
  }
}
</script>

<style scoped>
.card-holder {
  margin: 0;
}

.img-box {
 padding-top: 20px;    
 display: flex;
 justify-content: center;
}
.card-box {
  font-weight: 800;
  border-radius: 0.25em;
  
}
.bg-news {
  background: -webkit-linear-gradient(70deg, #5b44ad 40%, #ffffff 40%);
  background: -o-linear-gradient(70deg, #5b44ad 40%, #ffffff 40%);
  background: -moz-linear-gradient(70deg,  #5b44ad 40%, #ffffff 40%);
  background: linear-gradient(70deg, #5b44ad 40%, #ffffff 40%);  
}

.credit-card form{
	background-color: #ffffff;
	padding: 0;
	max-width: 100%px;
	margin: auto;
}

.credit-card .title{
	font-family: 'Abhaya Libre', serif;
	font-size: 1em;
	color: #2C3E50;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	margin-bottom: 0.8em;
	font-weight: 600;
	padding-bottom: 8px;
}


.inner-addon {
  position: relative;
}
</style>