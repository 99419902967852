<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h4 class="text-primary fw-bold">Warehouses</h4>
          <!--
            <button class="btn-style small pull-right" style="margin-top: -50px;float:right;" @click="$router.push('/warehouses/locate')">Locate Product</button>          -->
          
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>No. of Warehouses</p>
              <strong class="blue">{{ warehouses.length }}</strong>
            </div>
  
          </div>
  
          <div class="filter-search" style="margin-top: -40px;padding-left: 0px;" >
            <div class="filter-search-header">
              <strong>Search</strong>
              <p class="clear" @click="clear">Clear</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Warehouse</label>
                      <input type="text" class="form-control" v-model="searchQuery" @change="searchWarehouse">
                    </div>
                  </div>
                  <!--
                  <div class="col-md-10 align-self-end">
                    <div class="form-group">
                      <a href="#" class="btn-style small pull-right">Filter</a>
                    </div>
                  </div>
                  -->
                  
                </div>
              </div>
            </div>
          </div>
  
          <div class="products-list transaction-products-list" style="margin-top:-10px">
                <strong style="margin-top: 0px;">Warehouses List</strong>
                <table v-if="warehouses.length > 0" class="table table-hover">
                    <thead>
                        <th>No.</th>
                        <th>Warehouse</th>
                        <th>Description</th>
                        <th>No. of Virtual W/H</th>
                        <th>No. of Sections</th>
                        <th>No. of Bins</th>
                        <th>No. of Products</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        <tr v-for="(warehouse, index) in filteredWarehouses" v-bind:key="warehouse.id">
                            <td>{{ index+1 }}</td>
                            <td>{{  warehouse.name }} - {{ warehouse.code }} <span v-if="warehouse.isVirtual == true" class="btn btn-info btn-sm" style="float: right;">Virtual</span></td>
                            <td>{{  warehouse.description }}</td>
                            <td>{{  getNumberVirtuals(warehouse.id) }}</td>
                            <td>{{  warehouse.sections.length }}</td>
                            <td>{{  warehouse.binCount }}</td>
                            <td>{{  getQuantity(warehouse.id) }}</td>
                            <td><button class="btn btn-sm btn-default" style="border: 1px solid black;" @click="$router.push({name: 'ViewWarehouse', params:{ id: warehouse.id}})">View</button></td>
                        </tr>
                    </tbody>
                </table>

                <LoaderItem v-if="loading"/>
                <!--
                  <div v-else>
                    <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block; border: none !important">No warehouse found</label>
                  </div>
                -->
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
<script>
  
import { filterRecords } from '@/utils/common';
import Header from '../../components/Header';
import LoaderItem from '../../components/LoaderItem'

  export default {
    name: 'WarehousesPage',
    components: {
      Header,
      LoaderItem
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        virtuals:[],
        inventory:[],
        warehouses:[],
        filteredWarehouses: [],
        searchQuery: '',
        loading: false
    }),
    methods:{
      async setupVirtuals(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/warehouses/virtual/group', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.virtuals = data.virtuals;
        }
      },

      clear(){
        this.searchQuery = ''
        this.filteredWarehouses = this.warehouses
      },

      async setupInventory(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/inventory/warehouse', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.inventory = data.inventory;
        }
      },


      searchWarehouse(){
        this.filteredWarehouses = filterRecords(this.warehouses, this.searchQuery)
      },
      
      async setupWarehouses(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        try{
          this.loading = true
          const res = await fetch(process.env.VUE_APP_URL+'/warehouses/all', requestOptions)
          const data = await res.json()
          if(data.status == true)
            this.warehouses = data.warehouses;
            this.filteredWarehouses = this.warehouses
        }catch(err){
            console.log(err)
        }finally{
            this.loading = false
        }
      },
      getNumberVirtuals(id){
        var virtual = this.virtuals.find(obj => obj.hostId == id)
        if(virtual) return virtual?._count;
        return 0;
      },
      getQuantity(id){
        var product = this.inventory.find(obj => obj.warehouseId == id)
        if(product) return product?._count;
        return 0;
      },
    },

    watch: {
      searchQuery(newValue, oldValue) {
        if (newValue.trim() !== '' && newValue.trim() !== oldValue.trim()) {
          this.filteredWarehouses = filterRecords(this.warehouses,newValue);
        } else {
          this.filteredWarehouses = this.warehouses;
        }
      }
    },

    mounted(){
        this.setupWarehouses()
        this.setupVirtuals()
        this.setupInventory()
    }
  }
  </script>

  <style scoped>
  .warehouses-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }
  </style>