<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
      
      <section id="suppliers-add">
        <div class="container">
          <h3>Stock Transfer</h3>
          <div>
            <div class="form">
              <div class="row">
                <strong>1. Product to be transferred</strong>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Product Description</label>
                    <input type="text" id="address" class="form-control" readonly :value="inventory.Product?.name">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Product Code</label>
                    <input type="text" id="address" class="form-control" readonly :value="inventory.Product?.code">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Unit</label>
                    <input type="text" id="address" class="form-control" readonly :value="inventory.Product?.unit?.unit">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Qty Avail</label>
                    <input type="text" id="address" class="form-control" readonly :value="inventory.quantity">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Current Warehouse</label>
                    <input type="text" id="address" class="form-control" readonly :value="inventory.Warehouse?.name">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="reason">Warehouse to Move</label>
                    <select v-model="warehouseId" class="full" id="warehouses"  v-if="warehouses.length > 0">
                        <option v-for="warehouse in warehouses" v-bind:Key="warehouse.id" :value="warehouse.id">
                            {{ warehouse.name }} 
                            <span v-if="warehouse.isVirtual"> - Virtual</span>
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Warehouse Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchWarehouses">Fetch Warehouses</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Qty to Move</label>
                    <input type="number" min="0" id="address" class="form-control" v-model="quantity">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Reason</label>
                    <input type="text" id="address" class="form-control" v-model="reason">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Comment</label>
                    <input type="text" id="address" class="form-control" v-model="comment">
                  </div>
                </div>

                <div class="col-md-2">
                    <button class="btn-style small" style="margin-top: 35px" @click="postProcess">Process</button>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
        </section>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  
  export default {
    name: 'StockTransfer',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        inventoryId: "",
        inventory:"",
        reason: "",
        comment: "",
        quantity: "",
        warehouseId:"",
    }),
    computed:{
        ... mapGetters (["getWarehouses"]),
        warehouses () { return this.getWarehouses },
    },
    mounted(){
        this.fetchWarehouses(),
        this.inventoryId = this.$route.params.id
        this.setupInventory(this.inventoryId)
    },
    methods:{
        ... mapActions(['fetchWarehouses',]),
        async setupInventory(inventoryId){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/inventory/'+inventoryId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.inventory = data.inventory;
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Stock Details not found. Try again',
                    text: data.message
                })
            }
        },

        async postProcess() {
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                reason: this.reason,
                quantity: this.quantity,
                comment: this.comment,
                productId: this.inventory.Product?.id,
                inventoryId: this.inventoryId,
                warehouseId: this.warehouseId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/stocktransfers', requestOptions)
          const data = await res.json()
          if(data.status == true){
            Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: 'Stock Transfer Processed',
                showConfirmButton: false,
                timer: 1500
              })
              this.$router.push('/inventory/stocks')        
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Stock Transfer not created',
                text: data.message
            })
          }
        }
        
    }
  }
</script>

<style scoped>
input[readonly]{
  background-color: #cfcece !important;
}
</style>