<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="">
        <div class="container">
          <div class="d-flex justify-content-between">
            <h3>Stock Activity - {{ productName }} <button class="btn btn-success btn-sm" style="margin-top: -6px !important;  margin-left: 15px !important;">{{ whName }} <span v-if="whVirtual== 1" style="color: #fff !important;">- {{ whDesc }}</span></button></h3>
            <div class="btn-group" role="group" style="float:right; margin-bottom: 10px">
              <button v-if="!noactivity" type="button" class="btn btn-default btn-sm dropdown-toggle mb-2  mr-4" style="border: 1px solid black; margin-right: 20px!important; border-radius: none" data-bs-toggle="dropdown" aria-expanded="false">Export</button>
              <ul class="dropdown-menu">
                <li style="width: 100%;"><a class="dropdown-item" href="#" @click="exportData">CSV</a></li>
                <li style="width: 100%;"><a class="dropdown-item" href="#" @click="exportToPDF">PDF</a></li>
              </ul>
            </div>
          </div>
          
          <div class="pdf" id="pdfStart" style="margin-top: 0px">
            <div class="d-flex justify-content-between" v-if="pdf">
              <div>
                <p style="color: #FFA500; font-size: 14px" class="fw-bold">{{ profile?.businessName}}</p>
                <p style="color: #4448B3; font-size: 13px"> {{ profile?.businessName }} | Stock Activity</p>
              </div>
  
              <div style="display: flex; flex-direction: column; align-items: end">
                <p style="color: #4448B3; font-size: 12px; margin-bottom: 2px !important;" class="fw-bold">Stock Activity</p>
                <p style="font-size: 10px !important">{{ this.formatDate(new Date(), "true")}}</p>
              </div>
            </div>
            <table class="table table-hover">
              <thead>
                <th>No.</th>
                <th>Date</th>
                <th>Activity</th>
                <th>Shipped From</th>
                <th>Qty-In</th>
                <th>Qty Adj</th>
                <th>Qty Trans</th>
                <th>Qty Res</th>
                <th>Qty-Out</th>
                <th>BPN</th>
                <th>PN/DN</th>
                <th>Requested By</th>
                <th>Shipped To</th>
                <th>Project/Site</th>
                <th>Balance</th>
              </thead>
              <tbody>
                    <tr v-for="(activity, index) in activities" v-bind:key="activity.id">
                        <td>{{ index+1 }}</td>
                        <td>{{  formatDate(activity.createdAt,"true") }}</td>

                        <td style="text-transform: capitalize;" v-if="activity.activity == 'remove'">Stock Issue</td>
                        <td style="text-transform: capitalize;" v-else>{{ activity.activity }}</td>

                        <td v-if="activity.Batch">
                         <span v-if="activity.Batch.isInternal == true && activity.Batch.purchaseId == null">Internal Delivery</span>
                         <span style="display: block !important;" v-if="activity.Batch.Supplier">{{ activity.Batch.Supplier.name }}</span>
                         <span v-if="activity.Batch.Purchase"><a target="_blank" title="View PDF" :href="'/purchases/pdf/'+activity.Batch.Purchase.id">{{ activity.Batch.Purchase.purchaseNo }}</a></span>
                        </td>
                        <td v-else>-</td>

                        <td v-if="activity.activity == 'addition'">{{ activity.changedQuantity }}</td>
                        <td v-else>-</td>

                        <td v-if="activity.StockAdjustment?.length > 0">
                          <span v-if="activity.StockAdjustment?.[0].type == 'Addition'">{{ activity.StockAdjustment?.[0].quantity }} </span>
                          <span v-else>({{ activity.StockAdjustment?.[0].quantity }})</span>
                        </td>
                        <td v-else>-</td>
                        <td v-if="activity.activity == 'StockTransfer'">{{ activity.changedQuantity }}</td>
                        <td v-else>-</td>
                        <td v-if="activity.activity == 'StockReserved'">({{ activity.changedQuantity }})</td>
                        <td v-else-if="activity.activity == 'StockRelease'">{{ activity.changedQuantity }}</td>
                        <td v-else>-</td>
                        <td v-if="activity.activity == 'remove'">({{ activity.changedQuantity }})</td>
                        <td v-else>-</td>
                        <td v-if="activity.Batch"><a target="_blank" title="View PDF" :href="'/transaction/pdfbatch/'+activity.Batch.id">{{ activity.Batch.batchNo }}</a></td>
                        <td v-else>-</td>
                        <td v-if="activity.PickupNote"><a target="_blank" title="View PDF" :href="'/orders/pickup/pdf/'+activity.PickupNote.id">{{ activity.PickupNote.pickupNo }}</a></td>
                        <td v-else-if="activity.DispatchNote"><a target="_blank" title="View PDF" :href="'/orders/dispatch/pdf/'+activity.DispatchNote.id">{{ activity.DispatchNote.dispatchNo }}</a></td>
                        <td v-else>-</td>
                        <td v-if="activity.PickupNote"><a target="_blank" title="View PDF" :href="'/orders/pickup/pdf/'+activity.PickupNote.id">{{ activity.PickupNote.User.name }}</a></td>
                        <td v-else-if="activity.DispatchNote"><a target="_blank" title="View PDF" :href="'/orders/dispatch/pdf/'+activity.DispatchNote.id">{{ activity.DispatchNote.User.name }}</a></td>
                        <td v-else>-</td>
                        <td v-if="activity.PickupNote">{{ activity.PickupNote.assigneeName }}{{ activity.PickupNote.assigneeCompany }}</td>
                        <td v-else>-</td>
                        <td v-if="activity.PickupNote"><a target="_blank" title="View PDF" :href="'/orders/pickup/pdf/'+activity.PickupNote.id">{{ activity.PickupNote.project }}</a></td>
                        <td v-else-if="activity.DispatchNote"><a target="_blank" title="View PDF" :href="'/orders/dispatch/pdf/'+activity.DispatchNote.id">{{ activity.DispatchNote.project }}</a></td>
                        <td v-else>-</td>
                        <td v-if="activity.StockTransfer?.length > 0">{{ activity.quantity }}</td>
                        <td v-else>{{ activity.quantity }}</td>
                    </tr>
              </tbody>
            </table>

            <LoaderItem v-if="loading"/>

              <p v-if="noactivity" class="text-danger mt-4 mx-auto">No activity found</p>

              <div class="d-flex  justify-content-between" style="margin-top: 123vh; color: black" >
                <p>{{ profile.businessWebsite  }}</p>
                <p>{{ profile.businessEmail }}</p>
                <p> page 1 of 1 </p>
                <p> {{ this.formatDate(new Date(), "true") }}  </p>
              </div>  

          </div>
        </div>
      </section>
  
    
        <div v-if="pdf" id="pdfLoader" style="position: absolute; width: 100%; height: 2000px; top:0; z-index:1;background-color: #f2f2f2;">
          <h3 style="margin-top: 100px; text-align: center;padding: 40px;">Wait as the PDF is being generated</h3>
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    
   
    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import html2pdf from "html2pdf.js";
  import exportFromJSON from 'export-from-json';
import LoaderItem from '@/components/LoaderItem.vue';
  
  export default {
    name: 'ProductActivityPage',
    components: {
      Header,
      LoaderItem
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        activities: [],
        productName:"",
        whName:"",
        whVirtual:"",
        whDesc:"",
        noactivity: false, 
        profile: {},
        pdf: false, 
        loading: false
       
    }),
    mounted(){
        this.getActivities(this.$route.params.id)
        this.profile = JSON.parse(localStorage.getItem('profile'))
    },
    methods:{
        exportToPDF() {
          this.pdf = true
          const collection = document.getElementsByTagName("td");

          for (let i = 0; i < collection.length; i++) {
            collection[i].style.fontSize= "12px";
          }

          const element = document.getElementById("pdfStart");

          html2pdf()
          .from(element)
          .set({
            margin: 0.2,
            filename: new Date().getTime() + "_stock_activity.pdf",
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
          })
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            this.addHeadersAndFooters(pdf);
            return pdf;
          })
          .save()
          .then(() => {
            for (let i = 0; i < collection.length; i++) {
              collection[i].style.fontSize = "16px";
            }
            this.pdf=false
          });

        },

      

        addHeadersAndFooters(pdf) {
          const totalPages = pdf.internal.getNumberOfPages();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);

            // Header
            pdf.setFontSize(10);
            pdf.text("Header text", pdf.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

            // Footer
            pdf.setFontSize(10);
            pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 10, { align: 'center' });
          }
        },


        
      exportData() {
      const data = this.activities.map((activity, index) => ({
        'No.': index + 1,
        'Date': this.formatDate(activity.createdAt, 'false'),
        'Activity': activity.activity === 'remove' ? 'Stock Issue' : activity.activity,
        'Shipped From': activity.Batch
          ? (activity.Batch.isInternal && activity.Batch.purchaseId === null
            ? 'Internal Delivery'
            : activity.Batch.Supplier
              ? activity.Batch.Supplier.name
              : activity.Batch.Purchase
                ? `PO: ${activity.Batch.Purchase.purchaseNo}`
                : 'N/A')
          : '-',
        'Qty-In': activity.activity === 'addition' ? activity.changedQuantity : '-',
        'Qty Adj': activity.StockAdjustment?.length > 0
          ? (activity.StockAdjustment[0].type === 'Addition'
            ? activity.StockAdjustment[0].quantity
            : `(${activity.StockAdjustment[0].quantity})`)
          : '-',
        'Qty Trans': activity.activity === 'StockTransfer' ? activity.changedQuantity : '-',
        'Qty Res': activity.activity === 'StockReserved' ? `(${activity.changedQuantity})` : (activity.activity === 'StockRelease' ? activity.changedQuantity : '-'),
        'Qty-Out': activity.activity === 'remove' ? `(${activity.changedQuantity})` : '-',
        'BPN': activity.Batch ? activity.Batch.batchNo : 'N/A',
        'PN/DN': activity.PickupNote
          ? activity.PickupNote.pickupNo
          : activity.DispatchNote
            ? activity.DispatchNote.dispatchNo
            : 'N/A',
        'Requested By': activity.PickupNote
          ? activity.PickupNote.User.name
          : activity.DispatchNote
            ? activity.DispatchNote.User.name
            : 'N/A',
        'Shipped To': activity.PickupNote
          ? `${activity.PickupNote.assigneeName} ${activity.PickupNote.assigneeCompany}`
          : 'N/A',
        'Project/Site': activity.PickupNote
          ? activity.PickupNote.project
          : activity.DispatchNote
            ? activity.DispatchNote.project
            : 'N/A',
        'Balance': activity.quantity,
      }));
          const fileName = new Date().getTime()+'_stock_activity';
          const exportType = 'csv';
          
          const fields = ['No.', 'Date', 'Activity', 'Shipped From', 'Qty-In', 'Qty Adj', 'Qty Trans', 'Qty Res', 'Qty-Out', 'BPN', 'PN/DN','Requested By', 'Shipped To', 'Project/Site', 'Balance'];
          console.log({data})
          console.log({fields})
          //exportFromJSON({ data, fileName, exportType, fields });
          exportFromJSON({ data, fileName, exportType });
        },
        

        
        async getActivities (inventoryId) {
          try {
            this.loading = true
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + this.token },
                body: JSON.stringify({ 
                    inventoryId
                })
            };
            
            const res = await fetch(process.env.VUE_APP_URL + '/inventory/activity', requestOptions);
            
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            
            const data = await res.json();
              
              if (data.status === true) {
                  this.activities = data.activities;
                  this.productName = data.activities[0].Inventory.Product?.name;
                  this.whName = data.activities[0].Inventory.Warehouse?.name;
                  this.whDesc = data.activities[0].Inventory.Warehouse?.description;
                  this.whVirtual = data.activities[0].Inventory.Warehouse?.isVirtual;
              } else {
                  Swal.fire({
                      icon: 'error',
                      title: 'Product Stock Activity not found',
                      text: data.message
                  });
              }
          } catch (error) {
              this.loading = false
              this.noactivity= true
          }finally{
            this.loading = false
          }

        },
        
    }
  }
</script>

<style>

.lds-roller {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #002060;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>