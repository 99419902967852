<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h4 class="text-primary fw-bold">Cancelled Orders</h4>
          <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>Todays Cancelled Orders</p>
              <strong class="blue">{{ getTodayStats() }}</strong>
            </div>
  
          </div>
  
          <div class="filter-search" style="margin-top: -30px;padding-left: 0px;">
            <div class="filter-search-header">
              <strong>Filter</strong>
              <p class="clear">Clear</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo" >
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Cancelled By.</label>
                      <input type="text" class="form-control" v-model="cancelledBy">
                    </div>
                  </div>
                  
                  
                   <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <a href="#" class="btn-style small" @click.prevent="search">Search</a>
                    </div>
                  </div>
      
                </div>
              </div>
            </div>
          </div>
  
          <div>
                <strong>Orders Table</strong>
                 <!--<div class="btn-group" role="group" style="float:right">
                  <button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black;" data-bs-toggle="dropdown" aria-expanded="false">Export</button>
                  <ul class="dropdown-menu">
                    <li style="width: 100%;"><a class="dropdown-item" href="#">CSV</a></li>
                    <li style="width: 100%;"><a class="dropdown-item" href="#">PDF</a></li>
                    <li style="width: 100%;"><a class="dropdown-item" href="#">EXCEL</a></li>
                  </ul>
                </div>-->
                <table class="table table-hover" id="ordersTable">
                    <thead>
                        <th>No.</th>
                        <th>Created Date</th>
                        <th>Cancelled Date</th>
                        <th>Order No.</th>
                        <th>Cancelled By</th>
                        <th>Reason for Cancellation</th>
                        <th>Action</th>
                    </thead>
                    <!--
                    <tbody>
                        <tr v-for="(dispatch, index) in dispatches" v-bind:key="dispatch.id">
                            <td>{{ index+1 }}</td>
                            <td>{{  formatDate(dispatch.createdAt) }}</td>
                            <td>{{  formatDate(dispatch.deletedAt) }}</td>
                            <td>{{  dispatch.dispatchNo }}</td>
                            <td>{{  dispatch.name }}</td>
                            <td>{{  dispatch.cancelReason }}</td>
                            <td><button class="btn btn-sm btn-default" style="border: 1px solid black;" @click="$router.push({name: 'PdfDispatch', params:{ id: dispatch.id}})">View PDF</button></td>
                        </tr>
                        <tr v-for="(pickup, index) in pickups" v-bind:key="pickup.id">
                            <td>{{ index+1 }}</td>
                            <td>{{  formatDate(pickup.createdAt) }}</td>
                            <td>{{  formatDate(pickup.deletedAt) }}</td>
                            <td>{{  pickup.pickupNo }}</td>
                            <td>{{  pickup.name }}</td>
                            <td>{{  pickup.cancelReason }}</td>
                            <td><button class="btn btn-sm btn-default" style="border: 1px solid black;" @click="$router.push({name: 'PickupPdf', params:{ id: pickup.id}})">View PDF</button></td>
                        </tr>
                    </tbody>
                    -->
                    
                </table>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import Header from '../../components/Header';
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import Swal from 'sweetalert2'
  
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';
import { docFooter } from '@/utils/common';


  export default {
    name: 'CancelledOrders',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        dispatches:[],
        pickups: [],
        processedData:[],
        dateTo: '',
        dateFrom: '',
        cancelledBy:''
    }),
    mounted(){
      this.setupDispatches()
      this.setupPickups()
    },


  

    methods:{
      async setupPickups(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/pickups/cancelled', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.pickups = data.pickups;
            this.processedData = this.preprocessDataPickup(this.pickups);
        }
      },
      async setupDispatches(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/dispatches/cancelled', requestOptions)
        const data = await res.json()
        if(data.status == true){
          this.dispatches = data.dispatches;
          this.processedData = this.processedData.concat(this.preprocessDataDispatch(this.dispatches));
          this.initializeDataTable();
        }
      },



      getTodayStats(){
        return this.countTodayValues(this.dispatches) + this.countTodayValues(this.pickups)
      },

      countTodayValues(array) {
        const today = new Date();
        const todayString = today.toISOString().split('T')[0]; 
        const todayValues = array.filter(obj => {
            const deletedAtDate = new Date(obj.deletedAt);
            const deletedAtString = deletedAtDate.toISOString().split('T')[0];
            return deletedAtString === todayString;
        });
        return todayValues.length;
      },
      

      preprocessDataDispatch(rawData) {
        return rawData?.map(item => {
          return {
            indexNo:"1",
            createdDate: this.formatDate(item.createdAt),
            cancelledDate: this.formatDate(item.deletedAt),
            orderNo: item.dispatchNo,
            cancelledBy: item.name,
            cancelReason:item.cancelReason,
            action: '',
            type: 'dispatch'
          };
        });
      },

      preprocessDataPickup(rawData) {
        return rawData?.map(item => {
          return {
            indexNo:"1",
            createdDate: this.formatDate(item.createdAt),
            cancelledDate: this.formatDate(item.deletedAt),
            orderNo: item.pickupNo,
            cancelledBy: item.name,
            cancelReason: item.cancelReason,
            action: '',
            type: 'pickup'
          };
        });
      },


      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#ordersTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF,
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'createdDate' },
              { data: 'cancelledDate' }, 
              { data: 'orderNo' }, 
              { data: 'cancelledBy'},
              { data: 'cancelReason' }, 
              { data: 'action', render: this.renderActionButton }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },
  

      renderActionButton(data, type, row){
        if(type == 'display'){
          let action = ''
          if (row.type == 'dispatch') {
            action = `<button class="btn btn-sm btn-default" style="border: 1px solid black;" {@click="$router.push({name: 'PdfDispatch', params:{ id: dispatch.id}})">View PDF</button>`
          }
          if (row.type == 'pickup') {
            action = `<button class="btn btn-sm btn-default" style="border: 1px solid black;" @click="$router.push({name: 'PickupPdf', params:{ id: pickup.id}})">View PDF</button>`
          }

          return action
        }
        return data
      },


      
      customizePDF(doc) {
      //const logoBase64 = this.convertToBase64(logo)
      //console.log(logoBase64)
      const profile = JSON.parse(localStorage.getItem('profile'))
      doc.pageMargins = [40, 40, 40, 40]; // Set margins for the entire page
      doc.defaultStyle.columnGap = 0
      doc.content[1].fillColor = '#f2f2f2'

      doc.header = () => {
        return {
          columns: [
            {
              width: '*',
              columns: [
                /* {
                     image: logoBase64,
                     width: 150, // Adjust the width as needed
                 },*/
                {
                  text: profile?.businessName,
                  alignment: 'left',
                  margin: [40, 15, 0, 40], // Adjust margins as needed
                  color: '#FFA500', // Orange color
                  bold: true,
                  fontSize: 14,

                }
              ]
            },
            {
              width: '*',
              stack: [
                {
                  text: 'Cancelled Orders',
                  alignment: 'right',
                  margin: [0, 15, 50, 0],
                  fontSize: 12,
                  color: '#4448B3'
                },
                {  
                  alignment: 'right',
                  margin: [300, 0, 0, 0],
                  width: '*',
                  columns: [
                    {
                      width: 'auto',
                      text: 'Date:',
                      alignment: 'right',
                      margin: [0, 0, 10, 0],
                      bold: true,
                    },
                    {
                      width: 'auto',
                      text: `${this.formatDate(new Date(), "true")}`,
                      alignment: 'right',
                      margin: [0, 0, 50, 0],
                      fontSize: 9
                    }
                  ]
                }
              ]
            }
          ],
          fillColor: '#000000'
        };
      },

      doc.footer = (currentPage, pageCount) => docFooter(currentPage, pageCount)

      doc.styles.title = {
        color: '#4448B3',
        fontSize: 13,
        margin: [80, 0, 0, 40],
      };
    },





      async search(){
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                cancelledBy: this.cancelledBy,
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/pickups/search', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.pickups = data.pickups
            this.dataTable.clear();
            this.dataTable.rows.add(this.preprocessDataPickup(this.pickups));
            this.dataTable.draw();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Search failed. Try again',
                text: data.message
            })
        }
        
        const requestOptionsD = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                cancelledBy: this.cancelledBy,
            })
        };
        const resD = await fetch(process.env.VUE_APP_URL+'/dispatches/search', requestOptionsD)
        const dataD = await resD.json()
        if(dataD.status == true){
            this.dispatches = dataD.dispatches
            this.dataTable.clear();
            this.dataTable.rows.add(this.preprocessDataDispatch(this.dispatches));
            this.dataTable.draw();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Search failed. Try again',
                text: dataD.message
            })
        }
      },

      isSubCategory(element) {
        return element.id == this.categoryId;
      },
    },
      
       

    }
  
  </script>

  <style scoped>
  .purchases-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }
  </style>